import { getStore } from 'owa-teams-policies-lazy/lib/store/store';
import { skipPolicyCheckIfNotLoaded } from '../utils/skipPolicyCheckIfNotLoaded';

export function isTeamsMeetNowEnabled(): boolean {
    const policies = getStore().policies;
    return (
        skipPolicyCheckIfNotLoaded(policies) ||
        !!(policies?.allowPrivateMeetNow !== false && policies?.teamsFeatureEnabled)
    );
}

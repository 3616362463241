import { isFeatureEnabled } from 'owa-feature-flags';
import { isBootFeatureEnabled } from 'owa-metatags';

/**
 * Returns whether the shared coprincipal account functionality is enabled. Which requires both
 * the feature flag (acct-sharedcpa) and the boot flight for persisnce ids to be enabled.
 */
export function isSharedCoprincipalAccountEnabled(): boolean {
    return (
        isFeatureEnabled('acct-sharedcpa', undefined, /*dontThrowErrorIfNotInitialized*/ true) &&
        isBootFeatureEnabled('acctPersistentIdIndexerV2')
    );
}

import ContextMenuDataItem from './ContextMenuDataItem';
import onOpenMessageExtensionComposeApp from 'owa-app-host-actions/lib/actions/onOpenMessageExtensionComposeApp';
import type { AddinCommandSurfaceItem, ContextMenuDataItemClick } from 'owa-addins-types';

interface messageExtensionCommandSurfaceItemProps {
    id: string;
    name: string;
    description: string;
    iconUrl: string;
}

export default function createMessageExtensionCommandSurfaceItem(
    props: messageExtensionCommandSurfaceItemProps
): AddinCommandSurfaceItem {
    const menuData: ContextMenuDataItem = new ContextMenuDataItem(props.id, props.name);
    menuData.getClickHandler = getMessageExtensionClickHandler(props.id);
    menuData.description = truncateName(props.name);
    menuData.accessibilityText = getTooltip(props.name, props.description);
    menuData.iconUrl = props.iconUrl;
    return menuData.generateData();
}

function getMessageExtensionClickHandler(appId: string): ContextMenuDataItemClick {
    return () => {
        return (
            event?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>
        ) => {
            if (event?.stopPropagation) {
                event.stopPropagation();
            }
            onOpenMessageExtensionComposeApp({ appId });
        };
    };
}

function truncateName(name: string): string {
    const maxLength = 24;
    return name.length > maxLength ? name.substring(0, maxLength) + '\u2026' : name;
}

function getTooltip(name: string, description: string): string {
    if (!description) {
        return name;
    }
    return name + '\n\n' + description;
}

import { fetch } from 'owa-fetch';
import { registerIcons } from '@fluentui/style-utilities'; // @fluentui/style-utilities is an optional dependency, so we have to account for the package not being present.

export type IconMap = {
    [iconName: string]: string;
};

export interface IconFontRegistration {
    fontFileName: string;
    icons: IconMap;
}

interface RegisteredFontsMap {
    [fontFileName: string]: true;
}
const registeredFonts: RegisteredFontsMap = {};

export default function registerFontSubsets(
    packageBaseUrl: string,
    registrations: IconFontRegistration[] | undefined
): void {
    if (!packageBaseUrl || !registrations) {
        return;
    }

    registrations.forEach(fontRegistration => {
        const fontFileName = fontRegistration.fontFileName;
        if (!registeredFonts[fontFileName]) {
            registeredFonts[fontFileName] = true;
            if (process.env.NODE_ENV === 'dev' && !!process.env.CODESPACE_NAME) {
                // When running in codespaces, if you are running off of the codespaces fronting server,
                // you need to send authenticated cross-origin requests to the github backend
                // in order to get through github's auth server and into the game.
                //
                // At time of writing, there is not a clear way to make assets included from CSS perform
                // cross-origin authentication.
                //
                // To sidestep this problem, we inline the woff into the source when
                // doing a dev build out of a codespace.
                const woffUrl = `${packageBaseUrl}fonts/${fontFileName}.woff`;
                // the fetch hijack should automatically add the cors headers
                // see owa-start/src/codespacesAuthHijack.ts
                fetch(woffUrl)
                    .then(response => response.blob())
                    .then(blobData => {
                        const blobUrl = URL.createObjectURL(blobData);
                        // Duplicate this registerIcons call in its entirety because it will
                        // have less of a bundlesize impact in prod than refactoring common
                        // elements out (this branch is entirely eliminated in prod)
                        registerIcons({
                            style: {
                                MozOsxFontSmoothing: 'grayscale',
                                WebkitFontSmoothing: 'antialiased',
                                fontStyle: 'normal',
                                fontWeight: 'normal',
                                speak: 'none',
                            },
                            fontFace: {
                                fontFamily: `"${fontFileName}"`,
                                src: `url('${blobUrl.toString()}') format('woff')`,
                            },
                            icons: fontRegistration.icons,
                        });
                    });
            } else {
                registerIcons({
                    style: {
                        MozOsxFontSmoothing: 'grayscale',
                        WebkitFontSmoothing: 'antialiased',
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        speak: 'none',
                    },
                    fontFace: {
                        fontFamily: `"${fontFileName}"`,
                        src: `url('${packageBaseUrl}fonts/${fontFileName}.woff') format('woff')`,
                    },
                    icons: fontRegistration.icons,
                });
            }
        }
    });
}

export const isLocalLieError = (result: any, sanity: number = 1024): boolean => {
    if (sanity <= 0) {
        return false;
    }

    if (result === null || result === undefined || typeof result !== 'object') {
        return false;
    } else if (Array.isArray(result)) {
        return result.some(item => isLocalLieError(item, sanity - 1));
    } else {
        if (
            result.extensions?.remotePending === true ||
            Object.keys(result).some(key => isLocalLieError(result[key], sanity - 1))
        ) {
            return true;
        }
    }
    return false;
};

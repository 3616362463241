import { Network, Observable, RecordSource, Environment, Store } from 'relay-runtime';
import type {
    GraphQLResponse,
    RequestParameters,
    Variables,
    CacheConfig,
    UploadableMap,
} from 'relay-runtime';
import type {
    LegacyObserver,
    SubscribeFunction,
    FetchFunction,
} from 'relay-runtime/lib/network/RelayNetworkTypes';
import { gql } from 'graphql-tag';
import { ApolloLink } from '@apollo/client';
import type { GraphQLRequest } from '@apollo/client';
import { debugErrorThatWillShowErrorPopupOnly, errorThatWillCauseAlertAndThrow } from 'owa-trace';
import type { ResolverContext } from 'owa-graph-schema';

let globalEnvironment: Environment;

/**
 * Initialize the global Relay environment. This creates a Relay enviroment
 * with the provided links and context. This Relay Environment will be stored
 * in module scope and can be accessed using `getEnvironment()` from
 * owa-relay.
 */
export function initializeRelayEnvironment({
    links,
    context,
}: {
    links: Array<ApolloLink>;
    context: Partial<ResolverContext>;
}) {
    const store = new Store(new RecordSource());

    globalEnvironment = createRelayEnvironment({ apolloRequestContext: context, links, store });

    return globalEnvironment;
}

/**
 * Get the global Relay environment. This will throw an error if the
 * environment has not been initialized using `initializeRelayEnvironment`.
 */
export function getEnvironment() {
    if (!globalEnvironment) {
        return errorThatWillCauseAlertAndThrow('Relay envrionment is not initialized');
    }

    return globalEnvironment;
}

/**
 * Create a new Relay environment with the provided Apollo links and
 * Apollo context for those links.
 *
 * This can be used to create a new Relay environment for a specific piece
 * of functionality. This environment will not be stored in module scope,
 * you will need to store it yourself if you want to access it later. The
 * recommended way to store it is to use the RelayEnvironmentProvider from
 * `react-relay`.
 */
export function createRelayEnvironment({
    apolloRequestContext: context,
    links,
    store,
}: {
    apolloRequestContext: Partial<ResolverContext>;
    links: Array<ApolloLink>;
    store: Store;
}): Environment {
    const link = ApolloLink.from(links);

    return new Environment({
        network: Network.create(
            fetcherWithLinkAndContext(link, context),
            subscriptionWithLinkAndContext(link, context)
        ),
        store,
    });
}

function subscriptionWithLinkAndContext(
    link: ApolloLink,
    apolloRequestContext: Partial<ResolverContext>
) {
    const subscription: SubscribeFunction = (
        request: RequestParameters,
        variables: Variables,
        cacheConfig: CacheConfig,
        observer?: LegacyObserver<GraphQLResponse>
    ): Observable<GraphQLResponse> => {
        return Observable.create<GraphQLResponse>(outer => {
            const innerObserver = {
                next: (data: any) => {
                    outer.next(data);
                    observer?.onNext?.(data);
                },
                complete: () => {
                    outer.complete();
                    observer?.onCompleted?.();
                },
                error: (e: any) => {
                    debugErrorThatWillShowErrorPopupOnly('Relay subscription error', e);
                    outer.error(e);
                    observer?.onError?.(e);
                },
            };

            const apolloRequest = createApolloRequest(
                request,
                variables,
                cacheConfig,
                apolloRequestContext
            );
            ApolloLink.execute(link, apolloRequest).subscribe(innerObserver);
        });
    };

    return subscription;
}

function fetcherWithLinkAndContext(
    link: ApolloLink,
    apolloRequestContext: Partial<ResolverContext>
): FetchFunction {
    const fetcher: FetchFunction = (
        request: RequestParameters,
        variables: Variables,
        cacheConfig: CacheConfig,
        _uploadables?: UploadableMap | null
    ) => {
        return Observable.create<GraphQLResponse>(outer => {
            const innerObserver = {
                next: (data: any) => {
                    outer.next(data);
                },
                complete: () => {
                    outer.complete();
                },
                error: (e: any) => {
                    debugErrorThatWillShowErrorPopupOnly('Relay fetcher error', e);
                    outer.error(e);
                },
            };

            const apolloRequest = createApolloRequest(
                request,
                variables,
                cacheConfig,
                apolloRequestContext
            );

            ApolloLink.execute(link, apolloRequest).subscribe(innerObserver);
        });
    };

    return fetcher;
}

function createApolloRequest(
    request: RequestParameters,
    variables: Variables,
    cacheConfig: CacheConfig,
    context?: Partial<ResolverContext>
): GraphQLRequest {
    const operation = {
        query: gql(request.text || ''),
        variables,
        operationName: request.name,
        extensions: {},
        context: Object.assign({}, context, cacheConfig?.metadata?.context || {}),
    };

    return operation;
}

export const OWA_ROOT_PREFIX: string = '/owa/';
export const ATTACHMENT_DOWNLOAD_RELATIVE_URL_TEMPLATE: string =
    'service.svc/s/GetFileAttachment?id={0}';
export const ATTACHMENT_PREVIEW_RELATIVE_URL_TEMPLATE: string =
    'service.svc/s/GetAttachmentThumbnail?id={0}&thumbnailType=2';
export const ATTACHMENT_THUMBNAIL_RELATIVE_URL_TEMPLATE: string =
    'service.svc/s/GetAttachmentThumbnail?id={0}';
export const ATTACHMENT_PRINT_RELATIVE_URL_TEMPLATE: string = 'pdfprint.aspx?id={0}';
export const ATTACHMENT_PRINT_HTML_PATHNAME: string = '/mail/pdfprint.html';
export const MESSAGE_DOWNLOAD_RELATIVE_URL_TEMPLATE: string =
    'service.svc/s/DownloadMessage?id={0}&outputFormat={1}&token={2}';

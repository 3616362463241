import {
    hotkey_keyword_alt,
    hotkey_keyword_backspace,
    hotkey_keyword_capslock,
    hotkey_keyword_command,
    hotkey_keyword_ctrl,
    hotkey_keyword_del,
    hotkey_keyword_down,
    hotkey_keyword_end,
    hotkey_keyword_enter,
    hotkey_keyword_esc,
    hotkey_keyword_escape,
    hotkey_keyword_home,
    hotkey_keyword_ins,
    hotkey_keyword_left,
    hotkey_keyword_meta,
    hotkey_keyword_pagedown,
    hotkey_keyword_pageup,
    hotkey_keyword_plus,
    hotkey_keyword_return,
    hotkey_keyword_right,
    hotkey_keyword_shift,
    hotkey_keyword_space,
    hotkey_keyword_tab,
    hotkey_keyword_up,
    keyboardShortcutThen,
} from './convertHotkeyStringToReadableText.locstring.json';
import loc from 'owa-localize';
import { isMac } from 'owa-user-agent/lib/userAgent';

import type { ResourceId } from 'owa-localize';

interface HotkeyTranslationOptions {
    useMacSymbols?: boolean;
}

const hotkeyResourceId: Record<string, ResourceId> = {
    shift: hotkey_keyword_shift,
    ctrl: hotkey_keyword_ctrl,
    alt: hotkey_keyword_alt,
    meta: hotkey_keyword_meta,
    backspace: hotkey_keyword_backspace,
    tab: hotkey_keyword_tab,
    enter: hotkey_keyword_enter,
    return: hotkey_keyword_return,
    capslock: hotkey_keyword_capslock,
    esc: hotkey_keyword_esc,
    escape: hotkey_keyword_escape,
    space: hotkey_keyword_space,
    pageup: hotkey_keyword_pageup,
    pagedown: hotkey_keyword_pagedown,
    end: hotkey_keyword_end,
    home: hotkey_keyword_home,
    left: hotkey_keyword_left,
    up: hotkey_keyword_up,
    right: hotkey_keyword_right,
    down: hotkey_keyword_down,
    ins: hotkey_keyword_ins,
    del: hotkey_keyword_del,
    command: hotkey_keyword_command,
    plus: hotkey_keyword_plus,
};

/**
 * Converts the given hotkey (i.e. "ctrl+n") to the Readable form to
 * be shown to the user.
 * @param hotkey The hotkey or array of hotkeys for a command
 * @param options Overrides for the translation options, which are otherwise
 *    inferred from the environment
 * @return A Readable string of the given hotkey(s)
 */
export function convertHotkeyStringToReadableText(
    hotkey: string | string[] | undefined,
    options?: HotkeyTranslationOptions
): string {
    /**
     * If there's only 1 hotkey, return the Readable version. If there
     * are multiple hotkeys, iterate over them and return a single Readable
     * string.
     */
    if (typeof hotkey === 'string') {
        return convertSingleHotkeyStringToReadableText(hotkey, options);
    } else if (hotkey instanceof Array) {
        return hotkey
            .map((k: string) => convertSingleHotkeyStringToReadableText(k, options))
            .join(' / '); // Add space so that the hotkey string breaks on new line at logical place
    } else {
        return '';
    }
}

/**
 * Helper function for convertToReadableHotkey that actually creates the
 * readable version of the hotkey string for a single hotkey.
 * @param hotkey The hotkey for a command
 * @return A readable string of the given hotkey
 */
function convertSingleHotkeyStringToReadableText(
    hotkey: string,
    options?: HotkeyTranslationOptions
): string {
    // Attempt to split on "+" which signifies a combination of keys.
    const individualKeys = hotkey.split('+');

    /**
     * If there's no "+" present (i.e. individualKeys === 1), continue
     * processing the string.
     *
     * If is a "+" present, the hotkey is a combination, so iterate over
     * individual keys, stylize them, join them back together with a "+",
     * and return (i.e. Ctrl+N).
     */
    if (individualKeys.length === 1) {
        // Attempt to split on " " which signifies a 2 stroke hotkey.
        const individualKeysOnSpace = hotkey.split(' ');

        /**
         * If there's no " " present (i.e. individualKeys === 1), stylize
         * the string and return.
         *
         * If there is a " " present, the hotkey is 2 strokes, so stylize
         * the individual keys and join them with "then" (i.e. G then I).
         */
        if (individualKeysOnSpace.length === 1) {
            return localizeKey(individualKeysOnSpace[0], options);
        } else {
            return loc(
                keyboardShortcutThen,
                localizeKey(individualKeysOnSpace[0], options),
                localizeKey(individualKeysOnSpace[1], options)
            );
        }
    } else {
        const ReadableKeys: string[] = [];

        /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
         * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
         *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
        individualKeys.forEach(key => {
            ReadableKeys.push(localizeKey(key, options));
        });

        return ReadableKeys.join('+');
    }
}

function localizeKey(key: string, { useMacSymbols = isMac() }: HotkeyTranslationOptions = {}) {
    if (useMacSymbols) {
        switch (key.toLowerCase()) {
            case 'ctrl':
                return '\u2318';
            case 'alt':
                return '\u2325';
            case 'shift':
                return '\u21E7';
            default:
        }
    }
    return key in hotkeyResourceId ? loc(hotkeyResourceId[key]) : capitalizeFirstLetter(key);
}

/**
 * Helper function to capitalize a string.
 * https://stackoverflow.com/a/1026087/4581361
 * @param string The string to capitalize
 * @return A string whose first letter is capitalized
 */
function capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

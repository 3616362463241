import type { MailboxInfo } from 'owa-client-types';
import getThirdPartyImProvider from '../selector/selectors';
import { defaultOnlineMeetingProviderName } from './constants';

export function getSelectedThirdPartyImDisplayName(mailboxInfo: MailboxInfo): string {
    const selectedMeetingProvider = getThirdPartyImProvider(mailboxInfo);
    if (!selectedMeetingProvider) {
        return defaultOnlineMeetingProviderName;
    }

    return selectedMeetingProvider.name || defaultOnlineMeetingProviderName;
}

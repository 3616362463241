import { LazyModule, createLazyComponent } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "SignInStateChangedInfoBar" */ './lazyIndex'),
    { name: 'SignInStateChangedInfoBar' }
);

export const SignInStateChangedInfoBar = createLazyComponent(
    lazyModule,
    m => m.SignInStateChangedInfoBar
);

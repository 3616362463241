import { LINKEDCALENDARV2_ENTRY_TYPENAME } from './constants';

/**
 * Returns whether provided calendar is a old model shared calendar or not
 * @param calendarEntry, the calendar entry
 * @returns true if calendar is an old model shared calendar
 */
export function isLinkedCalendarEntryV2(calendarEntry?: {
    __typename?: string;
    typename?: string;
    IsGroupMailboxCalendar?: boolean | null;
}): boolean {
    return (
        (calendarEntry?.__typename == LINKEDCALENDARV2_ENTRY_TYPENAME ||
            calendarEntry?.typename == LINKEDCALENDARV2_ENTRY_TYPENAME) &&
        !calendarEntry?.IsGroupMailboxCalendar
    );
}

import React from 'react';
import loc from 'owa-localize';
import { observer } from 'owa-mobx-react';
import { KeytipManager, type IKeytipLayerProps } from '@fluentui/react';
import { KeytipLayer } from '@fluentui/react/lib/KeytipLayer';
import { isHostAppFeatureEnabled } from 'owa-hostapp-feature-flags';
import { keytipAriaDescription } from './RibbonKeytipLayer.locstring.json';
import { useValueInitializedOnce } from 'owa-react-hooks/lib/useValueInitializedOnce';
import { useCustomKeytipEvents } from './useCustomKeytipEvents';
import { addDocumentEventListener } from 'owa-event-listener';

const getContent = (): IKeytipLayerProps['content'] => {
    return isHostAppFeatureEnabled('altForKeytips')
        ? loc(keytipAriaDescription, 'Alt', 'F10')
        : 'Alt';
};

/** Element that had focus prior to entering keytip mode */
let lastActiveElement: typeof document.activeElement;

let removeKeydownListener: ReturnType<typeof addDocumentEventListener>;

/** Restore focus to element that had focus prior to entering keytip mode */
const restoreFocus = () => {
    removeKeydownListener?.();
    lastActiveElement instanceof HTMLElement && lastActiveElement.focus();
};

const onKeyDown = (event: KeyboardEvent) => {
    const { key, target } = event;
    const { inKeytipMode } = KeytipManager.getInstance();

    if (
        // Ensuring target is an `HTMLElement` here to avoid type casting
        !(target instanceof HTMLElement) ||
        inKeytipMode
    ) {
        return;
    }

    const ribbonRoot = document.getElementById('RibbonRoot');
    const isRibbonControl = ribbonRoot?.contains(target);

    if (key === 'Escape' && isRibbonControl) {
        restoreFocus();
    }

    if (!isRibbonControl) {
        removeKeydownListener?.();
    }
};

const onEnterKeytipMode = (selectedTabId: string) => () => {
    // Add keydown listener
    removeKeydownListener = addDocumentEventListener('RibbonKeytipLayer', 'keydown', onKeyDown);

    // Save currently focused element
    lastActiveElement = document.activeElement;

    // Set focus to selected Ribbon tab
    document.getElementById(selectedTabId)?.focus();
};

const onExitKeytipMode: IKeytipLayerProps['onExitKeytipMode'] = event => {
    // When `event` is `undefined`, it indicates that `keytipManager.exitKeytipMode()` has been called directly.
    // In this case, we want to restore focus.
    if (!event) {
        restoreFocus();
        return;
    }

    if (!(event instanceof KeyboardEvent && event.target instanceof HTMLElement)) {
        return;
    }

    const {
        key,
        target: { role },
    } = event;

    const isArrowKey =
        key === 'ArrowDown' || key === 'ArrowLeft' || key === 'ArrowRight' || key === 'ArrowUp';

    const isTabKey = key === 'Tab';

    const isTargetInMenu =
        role === 'gridcell' ||
        role === 'menu' ||
        role === 'menuitem' ||
        role === 'menuitemcheckbox' ||
        role === 'menuitemradio';

    if (
        // Avoid restoring last focus when an `Arrow` key is pressed
        !isArrowKey &&
        // Avoid restoring last focus when `Tab` is pressed
        !isTabKey &&
        // Avoid restoring last focus when current focus is in a dropdown menu
        !isTargetInMenu
    ) {
        restoreFocus();
    }
};

export const RibbonKeytipLayer = observer(({ selectedTabId }: { selectedTabId: string }) => {
    useValueInitializedOnce(() => {
        // Delay registration of keytips until user is in keytip mode
        KeytipManager.getInstance().init(true);
    });

    useCustomKeytipEvents(isHostAppFeatureEnabled('altForKeytips'));

    return (
        <KeytipLayer
            content={getContent()}
            onEnterKeytipMode={onEnterKeytipMode(selectedTabId)}
            onExitKeytipMode={onExitKeytipMode}
        />
    );
}, 'RibbonKeytipLayer');

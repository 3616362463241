import type { PXNovaTelemetryEvent as NovaTelemetryEvent } from '@1js/midgard-types';
import type { IExtendedTelemetryItem } from '@microsoft/1ds-analytics-js';
import { getClientVersion, getLogicalRing, getSessionId } from 'owa-config';
import { getGuid } from 'owa-guid';
import { getAccountScopeUserSettings } from 'owa-session-store';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { lazyLogAddinsTelemetryEvent } from 'owa-analytics';

type TelemetryContextProps = {
    hostApp: string;
    hostAppRing: string;
    hostAppVersion: string;
    hostAppPlatform: string;
    complianceEnvironment: string;
    hostAppSessionCorrelationId: string;
    userId: string;
    tenantId: string;
    puid: string;
};

export class NovaLogger {
    private static ingestionKeys: Map<string, string> = new Map();
    private static middlewaresMap: Map<
        string,
        ((telemetryItem: IExtendedTelemetryItem) => IExtendedTelemetryItem)[]
    > = new Map();

    /**
     * This service enables Nova components to log PX Telemetry events.
     * When calling this service this function is used to tell it which app name to associate with an ingestion key.
     * _Always_ make sure to call this function
     * before making calls to _logEvent_ in order to log the events correctly.
     * @param ingestionKey an Aria ingestion key used to route the event to the correct Kusto cluster
     * @param appName the name of the app calling the telemetry service
     * @param middlewares function to be called on the event before it is logged
     */
    public static associateIngestionKeyWithAppName(
        ingestionKey: string,
        appName: string,
        middlewares?: ((telemetryItem: IExtendedTelemetryItem) => IExtendedTelemetryItem)[]
    ) {
        if (middlewares) {
            NovaLogger.middlewaresMap.set(appName, middlewares);
        }
        NovaLogger.ingestionKeys.set(appName, ingestionKey);
    }

    /**
     * Log an event to a Kusto cluster specified by an ingestion key. In order for this function call to work you need to call
     * _associateIngestionKeyWithAppName_first to tell the service which ingestion token an app name should route events to.
     * @param event an object that contain the event data
     */
    public static logEvent(
        event: NovaTelemetryEvent,
        complianceEnvironment?: string,
        platformType?: string
    ) {
        const appName = event.data().appName;
        const ingestionKey = NovaLogger.ingestionKeys.get(appName);
        if (ingestionKey) {
            const telemetryItem = event.data().telemetryItem;
            const telemetryItemWithContextProps = NovaLogger.addHostAppContextProperties(
                telemetryItem,
                complianceEnvironment,
                platformType
            );
            const modifiedTelemetryItem = NovaLogger.executeMiddleware(
                appName,
                telemetryItemWithContextProps
            );
            lazyLogAddinsTelemetryEvent.importAndExecute(ingestionKey, modifiedTelemetryItem);
        }
    }

    private static executeMiddleware(appName: string, telemetryItem: IExtendedTelemetryItem) {
        const middlewares = NovaLogger.middlewaresMap.get(appName);
        return middlewares
            ? middlewares.reduce((item, middleware) => middleware(item), telemetryItem)
            : telemetryItem;
    }

    private static addHostAppContextProperties(
        telemetryItem: IExtendedTelemetryItem,
        complianceEnvironment?: string,
        platformType?: string
    ): IExtendedTelemetryItem {
        const hostAppContextProps = NovaLogger.getHostAppContextProps(
            complianceEnvironment,
            platformType
        );
        return {
            ...telemetryItem,
            data: {
                ...telemetryItem.data,
                ...hostAppContextProps,
            },
        };
    }

    private static getHostAppContextProps(
        complianceEnvironment?: string,
        platformType?: string
    ): TelemetryContextProps {
        const viewerMailboxInfo = getGlobalSettingsAccountMailboxInfo();
        const accountScopeUserSettings = getAccountScopeUserSettings(viewerMailboxInfo);
        const { TenantGuid, UserPuid, ExternalDirectoryUserGuid } =
            accountScopeUserSettings.SessionSettings || {};
        const originalTenantId = TenantGuid ?? '';
        const tenantId = originalTenantId && originalTenantId.toLowerCase();
        const consumerCorrelationId = getSessionId() || getGuid();
        const hostAppRing = getLogicalRing() || '';
        const hostAppVersion = getClientVersion();
        return {
            hostAppSessionCorrelationId: consumerCorrelationId || '',
            hostApp: 'OneOutlook',
            hostAppPlatform: platformType || 'Web',
            hostAppRing,
            hostAppVersion,
            complianceEnvironment: complianceEnvironment || 'Unknown',
            userId: ExternalDirectoryUserGuid || '',
            tenantId,
            puid: UserPuid || '',
        };
    }
}

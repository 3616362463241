import { LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "CopilotService" */ './lazyIndex'),
    {
        name: 'CopilotService',
    }
);

export const lazyCallCopilotBizChatHandoffService = new LazyAction(
    lazyModule,
    m => m.callCopilotBizChatHandoffService
);

export type { BizChatHandoffResponse } from './types/BizChatHandoffResponse';

import type { MailboxInfo } from 'owa-client-types';
import { setItem, removeItem } from 'owa-local-storage';
import { getLocalOverrides, getLocalOverrideStorageKey } from './localOverrides';

export const setLocalOverride = (setting: string, value: any, mailboxInfo?: MailboxInfo) => {
    const localOverrides = getLocalOverrides(mailboxInfo);

    localOverrides[setting] = value;

    setItem(self, getLocalOverrideStorageKey(mailboxInfo), JSON.stringify(localOverrides));
};

export const resetLocalOverrides = (mailboxInfo?: MailboxInfo) => {
    /* eslint-disable-next-line no-restricted-globals  -- (https://aka.ms/OWALintWiki)
     * Baseline, should investigate if file should be in th worker or we can convert to getWindowData
     *	> Unexpected use of 'window'. Do not include "window" in code included in the worker build. Use self instead */
    removeItem(window, getLocalOverrideStorageKey(mailboxInfo));
};

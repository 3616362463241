import type {
    AccountSourceProtocolMetadata,
    AccountSourceType,
} from 'owa-account-source-list-types';
import type { ConfigState } from './ConfigState';
import type { SyncOptions } from './SyncOptions';
import type { AccountOriginContext } from './AccountOriginContext';

export const AccountMetadata_Version1 = 1;
export type AccountMetadataSource = 'Monarch';

/**
 * The purpose of the AccountMetadata is to provide the information needed
 * to create the account.
 */
export interface AccountMetadata {
    /**
     * Unique value for the metadata on the local machine, this value will remain
     * unchanged on the local machine for the life of the metadata.
     */
    uuid: string;

    /**
     * Version of the metadata, the version is updated when the required metadata
     * properties change. If only optional properties are added then the version
     * will not be updated.
     */
    version: number;

    /**
     * Sync options for the account, this will determine if the account can be roamed.
     */
    syncOptions: SyncOptions;

    /**
     * True if the account metadata has been synced to the server.
     */
    isSyncedToServer: boolean;

    /**
     * Optionally specifies the unique value for the metadata on the server. Normally
     * the server will use the metadata, if however two systems add the same account
     * then the server will use the first uuid supplied and the second system will have
     * a different uuid than the server.
     */
    serverUuid?: string;

    /**
     * The type of mailbox.
     */
    mailboxType: AccountSourceType;

    /**
     * The SMTP address for mailbox associated with the account.
     */
    smtpAddress: string;

    /**
     * The user principle name (or user identity) that is used to authenticate
     * to the mailbox associated with the account.
     */
    userIdentity: string;

    /**
     * Identifies the state of the account configuration on the local machine.
     */
    configState: ConfigState;

    /**
     * The sources (such a Win32, Universal) from which the account source was imported
     */
    sources: string[];

    /**
     * Timestamp string of the time the metadata is persisted to OWS'
     */
    lastModifiedTimestamp?: string;

    /**
     * Information about what supported by an account.
     * Empty array treated as account support everything.
     */
    contracts?: string[];

    /**
     * Some accounts, such as IMAP and iCloud accounts, need additional protocol specific
     * information to be able to create the account. This property optionally contains the
     * protocol specific metadata for the account.
     */
    protocolData?: AccountSourceProtocolMetadata[];

    /**
     * The SMTP aliases for the mailbox associated with the account.
     */
    aliases?: string[];

    /**
     * Account state in the universal client
     */
    accountState?: string;

    /**
     * Optional additional context provided by the origin of the account
     */
    originContext?: AccountOriginContext;

    /**
     * The display name for the account, currently used for account description.
     */
    displayName?: string;

    /**
     * True if the account is another mailbox, that is a mailbox that is not owned by the user
     * accesing it. This includes the shared and delagate mailbox access.
     */
    isAnotherMailbox?: boolean;
}

let parsedQueryString: Record<string, string> | null = null;

export function getQueryStringParametersFromURL(): {
    [key: string]: string;
} {
    const locationObj: Pick<Location, 'search'> = window.location;
    // Cache the parsed value for the main window
    return parsedQueryString || (parsedQueryString = parseQuery(locationObj));
}

function parseQuery(locationObj: Pick<Location, 'search'>) {
    if (typeof locationObj !== 'undefined') {
        return locationObj.search ? nodeParseQueryString(locationObj.search.substr(1)) : {};
    }
    // We're running outside a browser window, so return an empty property bag
    return {};
}

const maxKeys = 1000;
export function nodeParseQueryString(qs: string): {
    [key: string]: string;
} {
    const result: Record<string, string> = {};
    if (typeof qs === 'string' && qs.length > 0) {
        const qsParts = qs.split('&');
        const length = qsParts.length > maxKeys ? maxKeys : qsParts.length;
        for (let i = 0; i < length; ++i) {
            const qsPartSplit = qsParts[i].split('=');
            if (!result[decodeURIComponent(qsPartSplit[0])]) {
                result[decodeURIComponent(qsPartSplit[0])] = decodeURIComponent(
                    qsPartSplit.slice(1).join('=')
                );
            }
        }
    }
    return result;
}

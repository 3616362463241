import { type MailRibbonControlId, type MailRibbonGroupId } from 'owa-ribbon-ids/lib/mailRibbonId';
import {
    sections,
    singleRow,
    stack,
} from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components/RibbonControlGroupMLR/layoutTypings';

import type { Layout } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components/RibbonControlGroupMLR';
import { type MenuItemType } from 'owa-filterable-menu/lib/components/MenuItemType';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import type { RibbonGroupId } from 'owa-ribbon-ids';
import { owaComputedFn } from 'owa-computed-fn';
import { getMailMenuItemShouldShow } from 'owa-mail-filterable-menu-behavior';
import { getReadRibbonId } from 'owa-mail-ribbon-utils/lib/getReadRibbonId';
import { getRibbonMailboxInfo } from 'owa-mail-ribbon-utils/lib/getRibbonMailboxInfo';
import { getStringRibbonId } from 'owa-ribbon-ids';
import { getIgnoreBlockReportStack } from 'owa-mail-ribbon-utils/lib/getIgnoreBlockReportStack';

export const getMLRHomeTabGroupLayouts = owaComputedFn(function getMLRHomeTabGroupLayouts(
    group: RibbonGroupId,
    props: ReadOnlyRibbonControlDefProps
): Layout[] | undefined {
    const { itemId } = props;
    const mailboxInfo = getRibbonMailboxInfo(props);

    switch (group) {
        case 104:
            return [
                sections(
                    singleRow(
                        getIgnoreBlockReportStack(
                            mailboxInfo,
                            undefined /* itemId used to diferentiate readonly popout scenario */
                        )
                    ),
                    singleRow(getReadRibbonId(519, itemId).toString()),
                    /*
                     * Depending on whether or not we are showing Restore/RestoreAll, show eiter
                     * just the Archive button or all Archive/Restore/RestoreAll.
                     */
                    singleRow(
                        getMailMenuItemShouldShow(81, mailboxInfo) ||
                            getMailMenuItemShouldShow(81, mailboxInfo)
                            ? stack(
                                  getStringRibbonId(505),
                                  getStringRibbonId(683),
                                  getStringRibbonId(684)
                              )
                            : getStringRibbonId(505)
                    )
                ),
            ];

        case 197:
            return [sections(singleRow(getReadRibbonId(657, itemId).toString()))];

        case 113:
            return [
                sections(
                    singleRow(getStringRibbonId(572)),
                    singleRow(getStringRibbonId(540)),
                    singleRow(getReadRibbonId(559, itemId).toString())
                ),
                sections(
                    singleRow(getStringRibbonId(540)),
                    singleRow(stack(getStringRibbonId(572), getReadRibbonId(559).toString(), null))
                ),
            ];

        case 121:
            return [
                sections(
                    singleRow(
                        getReadRibbonId(556, itemId).toString(),
                        getReadRibbonId(557, itemId).toString(),
                        getReadRibbonId(555, itemId).toString(),
                        getReadRibbonId(678, itemId).toString(),
                        getStringRibbonId(553),
                        getStringRibbonId(696),
                        getReadRibbonId(686, itemId).toString(),
                        getReadRibbonId(712, itemId).toString()
                    )
                ),
                sections(
                    singleRow(
                        stack(
                            getReadRibbonId(556, itemId).toString(),
                            getReadRibbonId(557, itemId).toString(),
                            getReadRibbonId(555, itemId).toString()
                        ),
                        getReadRibbonId(678, itemId).toString(),
                        getStringRibbonId(553),
                        getStringRibbonId(696),
                        getReadRibbonId(686, itemId).toString(),
                        getReadRibbonId(712, itemId).toString()
                    )
                ),
            ];

        case 128:
            return [
                sections(
                    singleRow(
                        getReadRibbonId(552, itemId).toString(),
                        getStringRibbonId(509),
                        getReadRibbonId(527, itemId).toString(),
                        getStringRibbonId(548),
                        getStringRibbonId(571),
                        getReadRibbonId(616, itemId).toString()
                    )
                ),
                sections(
                    singleRow(
                        getReadRibbonId(552, itemId).toString(),
                        stack(
                            getStringRibbonId(509),
                            getReadRibbonId(527, itemId).toString(),
                            getStringRibbonId(548)
                        ),
                        getStringRibbonId(571),
                        getReadRibbonId(616, itemId).toString()
                    )
                ),
            ];
        case 118:
            return [sections(singleRow(getReadRibbonId(549, itemId).toString()))];

        case 195:
            return [sections(singleRow(getReadRibbonId(697, itemId).toString()))];

        case 198:
            return [
                sections(
                    singleRow(
                        getReadRibbonId(702, itemId).toString(),
                        getReadRibbonId(709, itemId).toString()
                    )
                ),
            ];

        case 200:
            return [sections(singleRow(getReadRibbonId(719, itemId).toString()))];

        default:
            return undefined;
    }
});

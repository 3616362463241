import { registerLazyOrchestrator } from 'owa-bundling';
import { migrateOutlookFavorites } from 'owa-favorites';
import { lazyModule } from './lazyFunctions';

export { default as getFavoriteNodeViewStateFromId } from './selectors/getFavoriteNodeViewStateFromId';
export { default as getMailFavoritesViewState } from './selectors/getMailFavoritesViewState';
export { getStore } from './store/store';

registerLazyOrchestrator(
    migrateOutlookFavorites,
    lazyModule,
    m => m.migrateOutlookFavoritesOrchestrator
);

export {
    lazyToggleFavoritesTreeExpansion,
    lazyRemoveFavoritePersona,
    lazyOnSelectedPersonaNodeChanged,
    lazyHandleToggleFavoritePersonaError,
    lazyMarkFavoritePersonaNodeAsAdded,
    lazyOnFavoritePersonaSelected,
    lazyAddFavoriteFolder,
    lazyRemoveFavoriteFolder,
    lazyAddFavoriteFolderV1,
    lazyRemoveFavoriteFolderV1,
    lazyOnFavoritePersonaUpdated,
    lazyAddFavoritePersona,
    lazyInitializeFavoritePersonas,
    lazyOutlookFavoritePersonasLoaded,
    lazyOutlookFavoriteFoldersLoaded,
    lazyInitializeFavoriteNodeViewState,
    lazyShowFindFavoritesPicker,
} from './lazyFunctions';

export { default as isFavoritingInProgress } from './selectors/isFavoritingInProgress';

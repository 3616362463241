import { errorThatWillCauseAlert } from 'owa-trace';
import { parseNovaEventToOwaAction } from './utils/parseNovaEventToOwaAction';
import type { NovaEvent, NovaEventing } from '@nova/types';
import type { NovaEventUnion } from 'owa-nova-actions';
import { NovaLogger } from 'owa-analytics-nova-logger';
import { NOVA_TELEMETRY_EVENT_TYPE } from '@1js/midgard-types';
import { getComplianceEnvironment } from 'owa-loki-request/lib/configuration/getComplianceEnvironment';
import { getPlatformType } from 'owa-loki-request/lib/configuration/getPlatformType';

export const novaEventing: NovaEventing = {
    bubble(event: { event: NovaEvent<unknown | NovaEventUnion> }): Promise<void> {
        try {
            const novaEvent = event.event as NovaEventUnion;

            if (novaEvent.type === NOVA_TELEMETRY_EVENT_TYPE) {
                NovaLogger.logEvent(novaEvent, getComplianceEnvironment(), getPlatformType());
                return Promise.resolve();
            }

            const action = parseNovaEventToOwaAction(novaEvent);
            if (action) {
                action(novaEvent);
            } else {
                errorThatWillCauseAlert('Failed to parse nova event to action: undefined');
            }
            return Promise.resolve();
        } catch (e) {
            // TODO: WI 171322; MOS3 instrumentation
            /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
             * The error name (message) must be a string literal (no variables in it).
             *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
            errorThatWillCauseAlert(`Failed to bubble nova event: ${e.toString()}`);
            return Promise.resolve();
        }
    },
};

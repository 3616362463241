import { FluentMenu } from 'owa-fluent-v9-shims';
import { logUsage } from 'owa-analytics';
import type MailListColumnHeaderType from '../types/MailListColumnHeaderType';
import calculateColumnWidths from '../utils/calculateColumnWidths';
import getColumnMinWidth from '../utils/getColumnMinWidth';
import { Icon } from '@fluentui/react/lib/Icon';
import { observer } from 'owa-mobx-react';
import { type FolderForestNodeType } from 'owa-favorites-types';
import { default as ChevronUp } from 'owa-fluent-icons-svg/lib/icons/ChevronUpRegular';
import { default as ChevronDown } from 'owa-fluent-icons-svg/lib/icons/ChevronDownRegular';
import loc, { format } from 'owa-localize';
import { lazySelectSort } from 'owa-mail-filter-actions';
import { getSelectedNode } from 'owa-mail-folder-forest-store';
import { getAnchorForContextMenu } from 'owa-positioning';
import { useMessageListTextStyle } from 'owa-mail-listitem-styles/lib/utils/useMessageListTextStyle';
import { useResizeObserver } from 'owa-react-hooks/lib/useResizeObserver';
import { ResizeHandle } from 'owa-resize-handle';
import type SortDirection from 'owa-service/lib/contract/SortDirection';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import { getDensityModeCssClass } from 'owa-fabric-theme';
import React from 'react';
import {
    getSortByForTable,
    getUserCategoryName,
    getViewFilterForTable,
    isDumpsterTable,
    getStore as getListViewStore,
    type SortColumn,
    type TableQueryType,
    getIsSearchTableShown,
} from 'owa-mail-list-store';
import {
    columnHeaderAriaLabel,
    fromColumnLabel,
    modifiedColumnLabel,
    receivedColumnLabel,
    returnTimeColumnLabel,
    sentColumnLabel,
    sortAscendingLabel,
    sortDescendingLabel,
    subjectColumnLabel,
    toColumnLabel,
} from './MailListColumnHeaders.locstring.json';
import type { IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import { DirectionalHint } from '@fluentui/react/lib/ContextualMenu';
import {
    getStore as getMailListLayoutStore,
    onColumnHeaderWidthsUpdated,
    onFirstColumnHandleChanged,
    onSecondColumnHandleChanged,
} from 'owa-mail-layout';
import { getDisplayDensityMenuItem } from 'owa-display-density-option';
import type { IPoint } from '@fluentui/react/lib/Utilities';

import {
    columnHeadersContainer,
    emptyFirstColumn,
    columnHeader,
    columnHeaderPadding,
    full,
    resizeHandle,
    resizeHandleMargin,
    columnHeaderHovered,
    columnHeaderRoomy,
    columnHeaderCozy,
    columnHeaderCompact,
    sortIconRoomyOrCozy,
    sortIconCompact,
} from './MailListColumnHeaders.scss';

import classnames from 'owa-classnames';
import { isFeatureEnabled } from 'owa-feature-flags';

const MailListColumnHeaders = observer(function MailListColumnHeaders(props: {
    tableViewId: string;
    folderId: string;
}) {
    const { folderId, tableViewId } = props;
    const densityModeCssClass = getDensityModeCssClass(full, undefined, undefined);
    const isRelocateHoverActionsFlightEnabled = isFeatureEnabled('tri-mlRelocateHoverActions');
    const { senderColumnWidth, subjectColumnWidth, receivedColumnWidth } = getMailListLayoutStore();
    /* eslint-disable-next-line owa-custom-rules/prefer-react-state-without-arrays-or-objects -- (https://aka.ms/OWALintWiki)
     * Please remove the array or object from React.useState() or leave a justification in case is not possible to do so.
     *	> It is preferable not to use arrays or objects as react state, use primitive data types, useReducer or satchel state instead, if its possible. */
    const [currentSortColumn, setCurrentSortColumn] = React.useState<SortColumn | null>(null);
    const [currentSortDirection, setCurrentSortDirection] = React.useState<SortDirection | null>(
        /* eslint-disable-next-line owa-custom-rules/prefer-react-state-without-arrays-or-objects -- (https://aka.ms/OWALintWiki)
         * Please remove the array or object from React.useState() or leave a justification in case is not possible to do so.
         *	> It is preferable not to use arrays or objects as react state, use primitive data types, useReducer or satchel state instead, if its possible. */
        null
    );

    const containerRef = React.useRef<HTMLDivElement>(null);

    /**
     * Using this tracking ref is a workaround as useResizeObserver isn't working
     * how I'd expect. Instead of only firing when the element ref's size changes,
     * it appears to be over-firing. I'm using this tracking ref to determine if
     * the callback needs to actually run.
     */
    const prevContainerWidth = React.useRef(null);

    React.useEffect(() => {
        /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
         * Baseline, please provide a proper justification if touching this code
         *	> 'offsetWidth' is restricted from being used. This property can cause performance problems by causing re-layouts. Please avoid if possible; if not, move to a requestAnimationFrame callback, and perform all DOM reads before performing any writes. */
        const containerWidth = containerRef?.current?.offsetWidth;
        if (!containerWidth) {
            return;
        }

        logUsage('MailListColumnHeadersInitializingWidths');

        const columnWidths = calculateColumnWidths(
            true /* isInitialLayout */,
            containerWidth,
            prevContainerWidth
        );
        if (
            !columnWidths.senderColumnWidth ||
            !columnWidths.subjectColumnWidth ||
            !columnWidths.receivedColumnWidth
        ) {
            return;
        }
        onColumnHeaderWidthsUpdated(
            columnWidths.senderColumnWidth,
            columnWidths.subjectColumnWidth,
            columnWidths.receivedColumnWidth
        );
    }, []);

    // Resize columns if container size changes.
    useResizeObserver('MailListColumnHeaders', containerRef, rect => {
        const containerWidth = rect.width;
        // Don't update column widths if container isn't visible on screen.
        if (!containerWidth) {
            return;
        }

        const columnWidths = calculateColumnWidths(
            false /* isInitialLayout */,
            containerWidth,
            prevContainerWidth
        );
        if (
            !columnWidths.senderColumnWidth ||
            !columnWidths.subjectColumnWidth ||
            !columnWidths.receivedColumnWidth
        ) {
            return;
        }
        onColumnHeaderWidthsUpdated(
            columnWidths.senderColumnWidth,
            columnWidths.subjectColumnWidth,
            columnWidths.receivedColumnWidth
        );
    });

    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope
     *	> JSX attribute values should not contain functions created in the same scope
     *	> JSX attribute values should not contain functions created in the same scope */
    const onColumnResize = (
        firstElementWidth: number,
        secondElementWidth: number,
        headerType: MailListColumnHeaderType
    ) => {
        if (headerType === 0) {
            onFirstColumnHandleChanged(firstElementWidth, secondElementWidth);
        } else if (!isRelocateHoverActionsFlightEnabled && headerType === 1) {
            onSecondColumnHandleChanged(firstElementWidth, secondElementWidth);
        }
    };

    const isSortingDisabled = React.useMemo(() => {
        const tableView = getListViewStore().tableViews.get(tableViewId);
        if (!tableView) {
            // Disable sorting if tableView is undefined
            return true;
        }

        const categoryName = getUserCategoryName(tableView);
        const isCategoryFolder = categoryName !== null;
        const isDumpster = isDumpsterTable(tableView.tableQuery);
        const isGroupTable = tableView.tableQuery.type === 2 || tableView.tableQuery.type === 3;
        const isSearchTable = tableView.tableQuery.type === 1;

        // Determine if current table is a new persona favorite.
        const isPersonaFavoriteFolder = getSelectedNode().type === 1;
        const isInitialPersonaFavoriteFolder =
            isPersonaFavoriteFolder && getViewFilterForTable(tableView) == null;

        return (
            isCategoryFolder ||
            isDumpster ||
            isGroupTable ||
            isSearchTable ||
            isInitialPersonaFavoriteFolder
        );
    }, [tableViewId]);

    // Reset sort column/direction when folder or folder sort changes.
    React.useEffect(() => {
        // If sorting is disabled, then we don't need to get sort info.
        if (isSortingDisabled) {
            return;
        }

        const tableView = getListViewStore().tableViews.get(tableViewId);
        if (!tableView) {
            return;
        }

        const sortBy = getSortByForTable(tableView);
        setCurrentSortColumn(sortBy.sortColumn);
        setCurrentSortDirection(sortBy.sortDirection);
    }, [folderId, isSortingDisabled, tableViewId]);

    const onColumnClicked = React.useCallback(
        (sortColumn: SortColumn) => {
            /**
             * The default sort for the "Received" column is "Descending" (most
             * recent on top) where as the default sort for the other columns is
             * "Ascending" (albabetical order).
             */
            let sortDirection: SortDirection = sortColumn === 1 ? 'Descending' : 'Ascending';

            /**
             * If sort column is the same as current sort, then invert the sort
             * direction. Otherwise, default to "Descending" sort.
             */
            if (sortColumn === currentSortColumn) {
                switch (currentSortDirection) {
                    case 'Descending':
                        sortDirection = 'Ascending';
                        break;
                    case 'Ascending':
                    default:
                        sortDirection = 'Descending';
                        break;
                }
            }

            setCurrentSortColumn(sortColumn);
            setCurrentSortDirection(sortDirection);

            lazySelectSort.importAndExecute(
                sortColumn,
                sortDirection,
                tableViewId,
                'ListViewColumnHeader'
            );
        },
        [currentSortColumn, currentSortDirection, tableViewId]
    );

    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope
     *	> JSX attribute values should not contain functions created in the same scope
     *	> JSX attribute values should not contain functions created in the same scope */
    const onSortByContextualMenu = (sortColumn: SortColumn, sortDirection: SortDirection) => {
        setCurrentSortColumn(sortColumn);
        setCurrentSortDirection(sortDirection);
    };

    /**
     * Don't render column headers if sort info hasn't been initialized and sorting
     * is enabled. If sorting is disabled, then we don't need sort info and we
     * can render.
     */
    if (!currentSortColumn && !isSortingDisabled) {
        return null;
    }

    return (
        <div className={columnHeadersContainer} ref={containerRef}>
            <div className={classnames(emptyFirstColumn, densityModeCssClass)} />
            <MailListColumnHeader
                currentSortColumn={currentSortColumn}
                currentSortDirection={currentSortDirection}
                folderId={folderId}
                headerType={0}
                isSortingDisabled={isSortingDisabled}
                onColumnClicked={onColumnClicked}
                onColumnResize={onColumnResize}
                onSortByContextualMenu={onSortByContextualMenu}
                tableViewId={tableViewId}
                width={senderColumnWidth ?? 0}
            />
            <MailListColumnHeader
                currentSortColumn={currentSortColumn}
                currentSortDirection={currentSortDirection}
                folderId={folderId}
                headerType={1}
                isSortingDisabled={isSortingDisabled}
                onColumnClicked={onColumnClicked}
                onColumnResize={onColumnResize}
                onSortByContextualMenu={onSortByContextualMenu}
                tableViewId={tableViewId}
                width={subjectColumnWidth ?? 0}
            />
            <MailListColumnHeader
                currentSortColumn={currentSortColumn}
                currentSortDirection={currentSortDirection}
                folderId={folderId}
                headerType={2}
                isSortingDisabled={isSortingDisabled}
                onColumnClicked={onColumnClicked}
                onColumnResize={onColumnResize}
                onSortByContextualMenu={onSortByContextualMenu}
                tableViewId={tableViewId}
                width={receivedColumnWidth ?? 0}
            />
        </div>
    );
},
'MailListColumnHeaders');

const MailListColumnHeader = observer(function MailListColumnHeader(props: {
    currentSortColumn: SortColumn | null;
    currentSortDirection: SortDirection | null;
    folderId: string;
    headerType: MailListColumnHeaderType;
    isSortingDisabled: boolean;
    onColumnClicked: (sortColumn: SortColumn) => void;
    onColumnResize: (
        firstElementWidth: number,
        secondElementWidth: number,
        headerType: MailListColumnHeaderType
    ) => void;
    onSortByContextualMenu: (sortColumn: SortColumn, sortDirection: SortDirection) => void;
    tableViewId: string;
    width: number;
}) {
    const {
        currentSortColumn,
        currentSortDirection,
        folderId,
        headerType,
        isSortingDisabled,
        onColumnClicked,
        onColumnResize,
        onSortByContextualMenu,
        tableViewId,
        width,
    } = props;

    // Context menu state
    const [isContextMenuVisible, setIsContextMenuVisible] = React.useState(false);
    /* eslint-disable-next-line owa-custom-rules/prefer-react-state-without-arrays-or-objects -- (https://aka.ms/OWALintWiki)
     * Please remove the array or object from React.useState() or leave a justification in case is not possible to do so.
     *	> It is preferable not to use arrays or objects as react state, use primitive data types, useReducer or satchel state instead, if its possible. */
    const [anchorPoint, setAnchorPoint] = React.useState<IPoint | null>(null);

    const isRelocateHoverActionsFlightEnabled = isFeatureEnabled('tri-mlRelocateHoverActions');

    const buttonStyles = useComputedValue(
        () => ({ width, cursor: isSortingDisabled ? 'default' : 'pointer' }),
        [isSortingDisabled, width]
    );

    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const onResized = (firstElementWidth: number, secondElementWidth: number) => {
        onColumnResize(firstElementWidth, secondElementWidth, headerType);
    };

    const folderName = folderId ? folderIdToName(folderId) : null;

    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const onClick = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        evt.stopPropagation();

        onColumnClicked(getSortColumnByMailListColumnHeaderType(headerType, folderName ?? ''));
    };

    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const onContextMenu = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        evt.preventDefault();
        evt.stopPropagation();
        setAnchorPoint(getAnchorForContextMenu(evt));
        setIsContextMenuVisible(true);
    };

    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const onDismiss = (
        evt?: Event | React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
    ) => {
        if (evt) {
            evt.stopPropagation();
        }

        setIsContextMenuVisible(false);
    };

    const icon = React.useMemo(() => {
        // Get sort column associated with header type.
        const sortColumnForHeader = getSortColumnByMailListColumnHeaderType(
            headerType,
            folderName ?? ''
        );

        /**
         * Don't render icon if table isn't sorted by this column or if sorting
         * is disabled.
         */
        if (currentSortColumn !== sortColumnForHeader || isSortingDisabled) {
            return null;
        }

        // Return icon based on sort direction.
        const isSortDirectionAscending = currentSortDirection === 'Ascending';
        const sortIcon = isSortDirectionAscending ? ChevronUp : ChevronDown;
        return (
            <Icon
                className={getDensityModeCssClass(
                    sortIconRoomyOrCozy,
                    sortIconRoomyOrCozy,
                    sortIconCompact
                )}
                iconName={sortIcon}
            />
        );
    }, [currentSortColumn, currentSortDirection, isSortingDisabled, tableViewId]);

    const columnLabel = getColumnLabel(folderId, headerType);

    return (
        <>
            <button
                aria-label={format(loc(columnHeaderAriaLabel), columnLabel)}
                className={classnames(
                    columnHeader,
                    getDensityModeCssClass(
                        columnHeaderRoomy,
                        columnHeaderCozy,
                        columnHeaderCompact
                    ),
                    isRelocateHoverActionsFlightEnabled && columnHeaderPadding,
                    useMessageListTextStyle('Major'),
                    !isSortingDisabled && columnHeaderHovered
                )}
                data-min-width={getColumnMinWidth(headerType)}
                disabled={isSortingDisabled}
                onClick={onClick}
                onContextMenu={onContextMenu}
                style={buttonStyles}
                title={columnLabel}
            >
                {columnLabel}
                {icon}
            </button>
            {(headerType !== 2 && !isRelocateHoverActionsFlightEnabled) ||
            (headerType === 0 && isRelocateHoverActionsFlightEnabled) ? (
                <ResizeHandle
                    onResized={onResized}
                    className={classnames(
                        resizeHandle,
                        isRelocateHoverActionsFlightEnabled && resizeHandleMargin
                    )}
                />
            ) : null}

            {isRelocateHoverActionsFlightEnabled && headerType === 1 && (
                <div
                    className={classnames(
                        resizeHandle,
                        isRelocateHoverActionsFlightEnabled && resizeHandleMargin
                    )}
                />
            )}
            {isContextMenuVisible && (
                <FluentMenu
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    items={getMenuItems(
                        getSortColumnByMailListColumnHeaderType(headerType, folderName ?? ''),
                        onSortByContextualMenu,
                        tableViewId
                    )}
                    onDismiss={onDismiss}
                    target={anchorPoint}
                />
            )}
        </>
    );
},
'MailListColumnHeader');

const getSortColumnByMailListColumnHeaderType = (
    mailListColumnHeaderType: MailListColumnHeaderType,
    folderName: string
): SortColumn => {
    switch (mailListColumnHeaderType) {
        case 0:
            return folderName == 'sentitems' || folderName == 'drafts' ? 9 : 3;
        case 1:
            return 8;
        case 2:
            return folderName == 'scheduled' ? 15 : 1;
    }
};

const getMenuItems = (
    sortColumn: SortColumn,
    onSortByContextualMenu: (sortColumn: SortColumn, sortDirection: SortDirection) => void,
    tableViewId: string
): IContextualMenuItem[] => {
    const menuItems: IContextualMenuItem[] = [];

    if (!getIsSearchTableShown()) {
        menuItems.push(
            {
                key: 'Ascending',
                name: loc(sortAscendingLabel),
                onClick: () => {
                    onSortByContextualMenu(sortColumn, 'Ascending');
                    lazySelectSort.importAndExecute(
                        sortColumn,
                        'Ascending',
                        tableViewId,
                        'ListViewColumnHeaderContextMenu'
                    );
                },
            },
            {
                key: 'Descending',
                name: loc(sortDescendingLabel),
                onClick: () => {
                    onSortByContextualMenu(sortColumn, 'Descending');
                    lazySelectSort.importAndExecute(
                        sortColumn,
                        'Descending',
                        tableViewId,
                        'ListViewColumnHeaderContextMenu'
                    );
                },
            }
        );
    }

    menuItems.push(getDisplayDensityMenuItem());

    return menuItems;
};

const getColumnLabel = (folderId: string, headerType: MailListColumnHeaderType): string => {
    const folderName = folderId ? folderIdToName(folderId) : null;

    switch (headerType) {
        case 0:
            return folderName == 'sentitems' || folderName == 'drafts' || folderName == 'outbox'
                ? loc(toColumnLabel)
                : loc(fromColumnLabel);
        case 1:
            return loc(subjectColumnLabel);
        case 2: {
            if (folderName == 'sentitems') {
                return loc(sentColumnLabel);
            } else if (folderName == 'drafts') {
                return loc(modifiedColumnLabel);
            } else if (folderName == 'scheduled') {
                return loc(returnTimeColumnLabel);
            } else {
                return loc(receivedColumnLabel);
            }
        }
        default:
            return '';
    }
};

export default MailListColumnHeaders;

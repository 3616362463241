import type { MailboxInfo } from 'owa-client-types';
import getThirdPartyImProvider from '../selector/selectors';

export function getRegisteredThirdPartyImIconName(mailboxInfo: MailboxInfo): string {
    const selectedMeetingProvider = getThirdPartyImProvider(mailboxInfo);
    if (!selectedMeetingProvider) {
        return 'unknown';
    }

    return selectedMeetingProvider.id;
}

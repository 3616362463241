import { logUsage } from 'owa-analytics';
import { orchestrator } from 'satcheljs';
import { isCapabilityEnabled } from 'owa-capabilities';
import { bleedThroughCapability } from 'owa-capabilities-definitions/lib/bleedThroughCapability';
import { copilotThemeCapability } from 'owa-capabilities-definitions/lib/copilotThemeCapability';
import { getIsDarkTheme } from 'owa-fabric-theme';
import { getCurrentThemeId, getFallbackBackgroundColor } from 'owa-theme';
import { onLoadTheme } from 'owa-theme-common';
import setBackgroundColor from '../mutators/setBackgroundColor';
import setBackgroundImage from '../mutators/setBackgroundImage';
import fetchBackgroundImage from '../utils/fetchBackgroundImage';
import { removeImageUrlFromLocalStorage } from '../utils/localStorageHelper';

let currentCopilotImage: string | undefined;

orchestrator(onLoadTheme, async () => {
    try {
        const isDarkTheme = getIsDarkTheme();
        const currentThemeId = getCurrentThemeId();
        const backgroundColor = getFallbackBackgroundColor(currentThemeId, isDarkTheme);
        const isBleedThroughEnabled = isCapabilityEnabled(bleedThroughCapability);

        setBackgroundImage(undefined);
        setBackgroundColor(backgroundColor);

        // Revoke the current copilot image if it exists
        if (currentCopilotImage) {
            URL.revokeObjectURL(currentCopilotImage);
            currentCopilotImage = undefined;
        }

        if (isBleedThroughEnabled) {
            const image = await fetchBackgroundImage(currentThemeId, isDarkTheme);

            if (image) {
                if (isCapabilityEnabled(copilotThemeCapability)) {
                    // Save the copilot image to revoke it later
                    currentCopilotImage = image;
                }

                setBackgroundImage(image);
            }
        } else {
            removeImageUrlFromLocalStorage();
        }
    } catch (error) {
        logUsage('ErrorLoadingBackgroundImage', {
            error: error.message,
            stack: error.stack,
            themeId: getCurrentThemeId(),
        });
    }
});

import { getAccountByMailboxInfo } from 'owa-account-source-list-store';
import { shouldShowAccountDescription } from 'owa-account-source-list';
import type { MailboxInfo } from 'owa-client-types';

export const getMailboxLabelForFavorite = (mailboxInfo: MailboxInfo): string | undefined => {
    const accountSource = getAccountByMailboxInfo(mailboxInfo);
    if (accountSource && shouldShowAccountDescription(accountSource)) {
        return accountSource.displayName;
    }
    return undefined;
};

/**
 * Determines the first differing character between two strings
 * @param first First string to be compared
 * @param second Second string to be compared
 * @returns Index of the first differing character between the two strings
 */
export function findFirstDiff(first: string, second: string) {
    const minLength = Math.min(first.length, second.length);
    for (let current = 0; current < minLength; current++) {
        if (first[current] !== second[current]) {
            return current;
        }
    }

    return minLength;
}

import LoadMoreFolderNode from './LoadMoreFolderNode';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline. Do not copy and paste"
 *	> '../index' import is restricted from being used. */
import { FolderOperationNode } from '../index';
import { Spinner } from '@fluentui/react-components';
import { observer } from 'owa-mobx-react';
import { type FolderForestNodeType } from 'owa-favorites-types';
import type { FolderForestTreeType, MailboxInfoInput } from 'owa-graph-schema';
import { showFolderTreeContextMenu } from 'owa-mail-folder-store/lib/actions/folderTreeContextMenu';
import { getAnchorForContextMenu } from 'owa-positioning';
import { LazyPublicFolderPicker } from 'owa-publicfolder-picker';
import MailFolderNodeChildren from './MailFolderNodeChildren';
import type { RenderFolderNodeFunc } from './MailFolderNodeTreeProps';
import React from 'react';
import { isPaid } from 'owa-session-store';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import { SEARCH_FOLDER_ROOT_DISTINGUISHED_ID } from 'owa-folders-constants';

export interface MailFolderListProps extends React.HTMLProps<HTMLDivElement> {
    childFolderIds: string[]; // Children folder ids to render the sub-folders under root
    hasMoreData: boolean; // Whether the tree has more data that can be fetched
    isFavoritesSupported: boolean; // this tree's folders can or can't support favorites for folder
    rootNodeId: string;
    rootFolderId: string; // Root folder details
    shouldShowLoadingSpinner: boolean;
    treeType: FolderForestTreeType; // Type of folder tree
    mailboxInfo: MailboxInfoInput;
    nestDepth?: number;

    // Callbacks
    renderFolderNode: RenderFolderNodeFunc;
    renderLoadNode?: () => JSX.Element;
    onLoadMoreClickedCallback?: () => void; // On load more
}

/**
 * Folder list that renders folders supplied to it and their children. This is responsible to render
 * the expanded state of any folder hierarchy
 */
export default observer(function MailFolderList(props: MailFolderListProps) {
    const {
        childFolderIds,
        hasMoreData,
        isFavoritesSupported,
        onLoadMoreClickedCallback,
        renderFolderNode,
        rootNodeId,
        rootFolderId,
        shouldShowLoadingSpinner,
        treeType,
        mailboxInfo,
        renderLoadNode,
        nestDepth,
    } = props;

    const distinguishedFolderParentIdList = React.useMemo(() => [rootNodeId], [rootNodeId]);

    const onContextMenu = React.useCallback(
        (
            evt: React.MouseEvent<unknown, MouseEvent>,
            folderId: string,
            distinguishedFolderParentIds: string[]
        ) => {
            showFolderTreeContextMenu(
                folderId,
                0,
                folderId,
                getAnchorForContextMenu(evt as React.MouseEvent<HTMLElement, MouseEvent>),
                treeType,
                rootNodeId,
                false /* showRootNodeMenu */,
                distinguishedFolderParentIds
            );
        },
        [treeType]
    );

    const renderAddNewNode = (
        folderId: string,
        nodeTreeType: FolderForestTreeType,
        nodeNestDepth: number
    ): JSX.Element => {
        return (
            <FolderOperationNode
                folderId={folderId}
                treeType={nodeTreeType}
                nestDepth={nodeNestDepth}
                operationType={'newNode'}
                mailboxInfo={mailboxInfo}
            />
        );
    };

    const shouldRenderSubTree = props.childFolderIds && props.childFolderIds.length > 0;

    // Loading UI and "New folder" node, which appear beneath the mail folders, but above the Search Folders node if it exists
    const contentBeneathMailFolders = (
        <>
            {renderLoadNode?.()}

            {/* Spinner will be shown for initial load and load more scenarios */}
            {!renderLoadNode && shouldShowLoadingSpinner && <Spinner size="tiny" />}

            {/* Render LoadMore if hasMoreData and it is not loading */}
            {!renderLoadNode &&
                hasMoreData &&
                !shouldShowLoadingSpinner &&
                onLoadMoreClickedCallback && (
                    <LoadMoreFolderNode onLoadMoreClickedCallback={onLoadMoreClickedCallback} />
                )}

            {/* Add new folder node at the end of the tree. */}
            {rootFolderId && renderAddNewNode(rootFolderId, treeType, 0 /* nestDepth */)}
        </>
    );

    const searchFolderList: string[] | undefined = getSearchFolderIdArray(
        childFolderIds,
        mailboxInfo
    );

    return (
        <>
            {shouldRenderSubTree && (
                <MailFolderNodeChildren
                    folderIdsToRender={childFolderIds}
                    nestDepth={nestDepth || 1} // Increase depth when rendering root's children
                    treeType={treeType}
                    isFavoritesSupported={isFavoritesSupported}
                    onContextMenu={onContextMenu}
                    renderFolderNode={renderFolderNode}
                    distinguishedFolderParentIds={distinguishedFolderParentIdList}
                    ignoreSearchFolderParent={true}
                />
            )}

            {contentBeneathMailFolders}

            {searchFolderList && (
                <MailFolderNodeChildren
                    folderIdsToRender={searchFolderList}
                    nestDepth={1}
                    treeType={treeType}
                    isFavoritesSupported={isFavoritesSupported}
                    onContextMenu={onContextMenu}
                    renderFolderNode={renderFolderNode}
                    distinguishedFolderParentIds={distinguishedFolderParentIdList}
                />
            )}

            {/* Initialize public folder picker */}
            {<LazyPublicFolderPicker />}
        </>
    );
}, 'MailFolderList');

const getSearchFolderIdArray = (
    childFolderIds: string[],
    mailboxInfo: MailboxInfoInput
): string[] | undefined => {
    if (!isPaid(mailboxInfo) || !childFolderIds) {
        return undefined;
    }

    const folderListSearchFolderId = childFolderIds.find(
        id => folderIdToName(id) == SEARCH_FOLDER_ROOT_DISTINGUISHED_ID
    );
    if (folderListSearchFolderId) {
        return [folderListSearchFolderId];
    } else {
        return undefined;
    }
};

import { mutatorAction } from 'satcheljs';
import brandSubscriptionStore from '../store/Store';
import getBrandsSubscriptionsService from '../services/getBrandsSubscriptionsService';
import { getSubscriptionFromCache } from '../selectors/subscriptionsCacheOperations';
import type { MailboxInfo } from 'owa-client-types';
import { trace } from 'owa-trace';
import type BrandSubscriptionModel from '../store/schema/BrandSubscriptionModel';

export default (smtpAddress: string, mailboxInfo: MailboxInfo) => {
    const subscription = getSubscriptionFromCache(smtpAddress);
    if (subscription) {
        unsubscribeFromCache(subscription, smtpAddress);
    } else {
        // If there is no item in Cache, maybe it got stale so reload the Cache
        getBrandsSubscriptionsService(mailboxInfo, undefined /* dontLoadBrandsInfo */).catch(
            error => trace.warn(error)
        );
    }
};

const unsubscribeFromCache = mutatorAction(
    'unsubscribeFromCache',
    (subscription: BrandSubscriptionModel, smtpAddress: string) => {
        brandSubscriptionStore.subscriptions.delete(smtpAddress.toLowerCase());
        brandSubscriptionStore.unsubscribedSubscriptions.set(
            smtpAddress.toLowerCase(),
            subscription
        );
    }
);

import { orchestrator } from 'satcheljs';
import onAcquisitionsRemoteRefresh from 'owa-m365-acquisitions/lib/actions/onAcquisitionsRemoteRefresh';
import { isMOS3AppServiceAvailable } from 'owa-m365-acquisitions/lib/utils/isMOS3AppServiceAvailable';
import { updateAppBarTiles } from '../utils/updateAppBarTiles';

orchestrator(onAcquisitionsRemoteRefresh, ({ mailboxInfo }) => {
    if (isMOS3AppServiceAvailable(mailboxInfo)) {
        updateAppBarTiles(mailboxInfo);
    }
});

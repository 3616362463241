import { logGreyError } from 'owa-analytics';
import { getItem, type LocalStorageKeys, removeItem, setItem } from 'owa-local-storage';
import { isCachingEnabled } from './isCachingEnabled';

interface VersionedCache<T> {
    version: number;
    value: T;
}

function getLocalStorageKey(persistenceId: string, name: string): LocalStorageKeys {
    return `AccountBootstrapCache_${persistenceId}_${name}`;
}

export function tryGetVersionedCacheValue<T>(
    persistenceId: string,
    name: string,
    version: number
): T | undefined {
    try {
        const key = getLocalStorageKey(persistenceId, name);
        const cachedValue = getItem(self, key);
        if (cachedValue) {
            const parsedValue = JSON.parse(cachedValue) as VersionedCache<T>;
            if (parsedValue.version === version) {
                return parsedValue.value;
            }
        }
    } catch (error) {
        logGreyError('Error reading account bootstrap cache', error, {
            persistenceId,
            name,
            version,
        });
    }

    return undefined;
}

export function setVersionedCacheValue<T>(
    persistenceId: string,
    name: string,
    version: number,
    value: T
) {
    const key = getLocalStorageKey(persistenceId, name);
    if (isCachingEnabled()) {
        const versionedValue: VersionedCache<T> = { value, version };
        setItem(self, key, JSON.stringify(versionedValue));
    } else {
        removeItem(self, key);
    }
}

import { logStartUsage } from 'owa-analytics-start';
import {
    addConnectedAccount,
    removeConnectedAccount,
    updateConnectedAccountState,
} from 'owa-account-source-list-api';
import { isHostAppFeatureEnabled } from 'owa-hostapp-feature-flags';
import { mutator, orchestrator } from 'satcheljs';

import {
    addOWAConnectedAccount,
    removeOWAConnectedAccount,
    updateOWAConnectedAccountStatus,
} from '../actions';
import { OWAConnectedAccountState } from '../store/schema/OWAConnectedAccount';
import getStore from '../store/store';
import { getAccountKeyForConnectedAccount } from '../utils/getAccountKeyForConnectedAccount';

function isNonMonarchSourceListSupportEnabled() {
    // If we are in an application that does not support Monarch multiple accounts we need to initialize
    // the owa-account-source-list-store with an account that represents the OWA user configuration. The
    // owa-account-source-list-store will be the way that information about account mailboxes is obtained
    // so it need to be populated before the render process.
    return !isHostAppFeatureEnabled('acctmonaccounts');
}

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(addOWAConnectedAccount, ({ account }) => {
    logStartUsage('owaAccountAdded', {
        accountStatus_1: OWAConnectedAccountState[account.accountState],
    });
    getStore().accountsMap.set(account.accountKey, account);
});

// To provide source list compatibility we add the connected account to the owa-account-source-list-store
// when it is added to the connected account store
/* eslint-disable-next-line owa-custom-rules/forbid-orchestrator-in-same-package -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Do not register an orchestrator with an action that is defined in the same package */
orchestrator(addOWAConnectedAccount, ({ account }) => {
    if (isNonMonarchSourceListSupportEnabled()) {
        addConnectedAccount(
            account.userIdentity,
            account.accountProviderType.toString(),
            account.anchorMailbox,
            account.accountState as number
        );
    }
});

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(removeOWAConnectedAccount, ({ userIdentity: userIdentity }) => {
    getStore().accountsMap.delete(userIdentity);
});

// To provide source list compatibility we removed the connected account from the owa-account-source-list-store
/* eslint-disable-next-line owa-custom-rules/forbid-orchestrator-in-same-package -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Do not register an orchestrator with an action that is defined in the same package */
orchestrator(removeOWAConnectedAccount, ({ userIdentity: userIdentity }) => {
    if (isNonMonarchSourceListSupportEnabled()) {
        removeConnectedAccount(userIdentity);
    }
});

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(
    updateOWAConnectedAccountStatus,
    ({ accountKeyOrUserIdentity: accountKeyOrUserIdentity, accountState: accountState }) => {
        const connectedAccount = getStore().accountsMap.get(
            getAccountKeyForConnectedAccount(accountKeyOrUserIdentity)
        );
        if (
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2532 (85,13): Object is possibly 'undefined'.
            // @ts-expect-error
            connectedAccount.accountState == OWAConnectedAccountState.Valid &&
            accountState != OWAConnectedAccountState.Valid
        ) {
            // datapoint to help us track how many times accounts get in to invalid state
            logStartUsage('owaConnectedAccountStatusUpdated', {
                accountStatus_1: OWAConnectedAccountState[accountState],
            });
        }
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2532 (96,9): Object is possibly 'undefined'.
        // @ts-expect-error
        connectedAccount.accountState = accountState;
    }
);

// To provide source list compatibility update the connected account in the owa-account-source-list-store
/* eslint-disable-next-line owa-custom-rules/forbid-orchestrator-in-same-package -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Do not register an orchestrator with an action that is defined in the same package */
orchestrator(
    updateOWAConnectedAccountStatus,
    ({ accountKeyOrUserIdentity: accountKeyOrUserIdentity, accountState: accountState }) => {
        if (isNonMonarchSourceListSupportEnabled()) {
            updateConnectedAccountState(accountKeyOrUserIdentity, accountState as number);
        }
    }
);

import { lazyEditGroup } from 'owa-group-edit-integration';
import { lazyEditGroupV2 } from 'owa-group-edit-integration-v2';
import { lazyOpenGroupSettings } from 'owa-group-settings-integration';
import { lazySelectGroup } from 'owa-mail-folder-forest-actions';
import {
    lazyGroupHeaderCommandBarAction,
    lazyGroupHeaderNavigationButton,
} from 'owa-group-header-actions';
import {
    lazyInitializeDeeplink,
    lazyDeeplinkActionType,
    lazyDeeplinkOnGroupDetailsLoadError,
    lazyDeeplinkOnGroupDetailsLoaded,
} from 'owa-group-deeplinkaction-integration';
import type { MailGroupRouteParameters } from './utils/mailGroupRouteSettings';
import { ensureValidGroupSmtpAddress } from './utils/mailGroupRouteSettings';
import selectRow from './selectRow';
import { logUsage } from 'owa-analytics';
import { getSourceQueryParam } from 'owa-querystring';
import { IsShareableCRUDEnabled } from 'owa-groups-shared-store/lib/utils/shareableCRUDFlight';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { lazySwitchToGroupsModule } from 'owa-groups-dash-nav-actions/lib/lazyCalendarBoot';
import { lazyLoadGroupsList } from 'owa-groups-left-nav-actions/lib/lazyFunction';
import transformGroupSmtp from 'owa-group-common/lib/utils/transformGroupSmtp';
import { isFeatureEnabled } from 'owa-feature-flags';

export default async function mailGroupRouteHandler(parameters: MailGroupRouteParameters) {
    let groupSmtp = ensureValidGroupSmtpAddress(parameters);
    const mailboxInfo = getGlobalSettingsAccountMailboxInfo();

    if (!groupSmtp) {
        return;
    }

    groupSmtp = transformGroupSmtp(groupSmtp);
    logUsage('MailGroupRouteHandlerEvent', { source: getSourceQueryParam() });

    let onGroupLoadSuccess = undefined;
    let onGroupLoadError = undefined;
    let onGroupLoadPromise: Promise<void> | undefined;

    if (parameters.actionId) {
        // import here since we expect to use this
        await lazyDeeplinkOnGroupDetailsLoaded.import();

        onGroupLoadPromise = new Promise(resolve => {
            onGroupLoadSuccess = () => {
                lazyDeeplinkOnGroupDetailsLoaded.importAndExecute(mailboxInfo);
                resolve();
            };

            onGroupLoadError = () => {
                lazyDeeplinkOnGroupDetailsLoadError.importAndExecute();
                resolve();
            };
        });
    }

    if (!parameters.actionId && isFeatureEnabled('grp-WeSpaceDeprecation', mailboxInfo)) {
        const loadGroupsList = await lazyLoadGroupsList.import();
        await loadGroupsList(mailboxInfo, false /*isReload*/, true /*SubscribeAll */);
        lazySwitchToGroupsModule.importAndExecute(
            mailboxInfo,
            groupSmtp,
            undefined /*folderId*/,
            undefined /*tabId*/,
            'MailAppGroupsRouting'
        );
        return;
    }

    await lazySelectGroup.importAndExecute(
        groupSmtp,
        'groups',
        mailboxInfo,
        onGroupLoadSuccess,
        onGroupLoadError
    );

    const commandBarAction = await lazyGroupHeaderCommandBarAction.import();
    const groupHeaderNavigationButton = await lazyGroupHeaderNavigationButton.import();
    commandBarAction(groupHeaderNavigationButton.Email);

    if (parameters.actionId) {
        const DeeplinkActionType = await lazyDeeplinkActionType.import();

        // if this is a valid deeplink action, set up the deeplink dialog
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS7015 (76,43): Element implicitly has an 'any' type because index expression is not of type 'number'.
        // @ts-expect-error
        const action = DeeplinkActionType[parameters.actionId.toLowerCase()];
        if (action != null) {
            const openGroupSettings = async (groupSmtpInternal: string) => {
                const openGroupSettingsInternal = await lazyOpenGroupSettings.import();
                openGroupSettingsInternal(groupSmtpInternal, mailboxInfo);
            };
            const editGroup = async (groupSmtpInternal: string) => {
                if (IsShareableCRUDEnabled(mailboxInfo)) {
                    await lazyEditGroupV2.importAndExecute(
                        groupSmtpInternal,
                        'GroupHub_CommandBar',
                        mailboxInfo
                    );
                } else {
                    const editGroupInternal = await lazyEditGroup.import();
                    editGroupInternal(groupSmtpInternal, mailboxInfo);
                }
            };

            lazyInitializeDeeplink.importAndExecute(
                groupSmtp,
                action,
                openGroupSettings,
                editGroup,
                mailboxInfo
            );
        }
    }

    if (parameters.rowId) {
        selectRow(parameters.rowId);
    }

    if (onGroupLoadPromise) {
        await onGroupLoadPromise;
    }
}

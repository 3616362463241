import './whyDidYouRender';
import React from 'react';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 *   LPC utilizes the 'react-dom' instance attached to the window below. For unknown reasons,
 *   using 'owa-react-dom' instead causes the LPC to crash
 */
import ReactDOM from 'react-dom';

if (typeof window !== 'undefined') {
    window.React = React;
    window.ReactDOM = ReactDOM;
}

import type { BootstrapOptions } from './types/BootstrapOptions';
import asyncRender from './asyncRender';
import { ErrorBoundary } from 'owa-error-boundary';
import initializeSatchelMiddleware from './initializeSatchelMiddleware';
import initializeWindowTitle from './initializeWindowTitle';
import initializeDateTime from './initializeDateTime';
import updateLocalMailboxTimeZone from './updateLocalMailboxTimeZone';
import { getUserConfiguration } from 'owa-session-store';
import { initializeStartupDataV1 } from 'owa-shared-startupdata-bootstrap';
import { updateServiceConfig } from 'owa-service/lib/config';
import { logUsage } from 'owa-analytics';
import { addAnalyticsCallbacks } from 'owa-analytics-start';
import {
    isAccountFeatureEnabled,
    isFeatureEnabled,
    type GlobalFeatureName,
    type AccountFeatureName,
} from 'owa-feature-flags';
import { trackBottleneck, markFunction, markStart, markEnd } from 'owa-performance';
import { preloadStrategies } from './preloadStrategies';
import { type OwaWorkload, setOwaWorkload } from 'owa-workloads';
import { registerFontSubsets } from 'owa-icons';
import { registerDefaultFontFaces } from '@fluentui/style-utilities';
import { isUserIdle } from './isUserIdle';
import {
    getResourcePath,
    getCdnUrl,
    getOpxHostData,
    getClientVersion,
    setDatetimeBootstrapOptions,
    doesClientBuildMatch,
} from 'owa-config';
import { setBundlingConfig } from 'owa-bundling';
import { lazySetupSharedPostBoot } from 'owa-shared-post-boot';
import { registerCreateServiceResponseCallback as registerOwsCreateServiceResponseCallback } from 'owa-service/lib/fetchWithRetry';
import { registerCreateServiceResponseCallback as registerOwsPrimeCreateServiceResponseCallback } from 'owa-ows-gateway/lib/registerCreateServiceResponseCallback';
import { initializeRouter } from 'owa-router';
import createBootError from 'owa-shared-start/lib/createBootError';
import { getQueryStringParameter, hasQueryStringParameter } from 'owa-querystring';
import type { PromiseWithKey } from 'owa-performance';
import { initOwaAccountSourceListStore } from 'owa-account-source-list-api/lib/initOwaAccountSourceListStore';
import { BootState } from 'owa-account-source-list-store';
import { lazyGovern, getWithinGovernor } from 'owa-tti';
import { getLocalizationContext } from './getLocalizationContext';
import { findMetatag } from 'owa-metatags';
import type { MailboxInfo } from 'owa-client-types';
import {
    getApplicationSettings,
    getApplicationSettingsFromSessionData,
    type ApplicationSettingGroup,
} from 'owa-application-settings';
import type { SessionData } from 'owa-service/lib/types/SessionData';
import { patchReaction } from 'owa-configure-mobx';
import { trackTti } from 'owa-tti/lib/tti';
import { lazySetStartAccountDataOverride } from 'owa-account-start-data';
import { lazyCheckAndHandleHipException } from 'owa-arkose-captcha';
import type { HeadersWithoutIterator } from 'owa-service/lib/RequestOptions';
import { bootstrapSecondaryAccountSettings } from 'owa-account-bootstrap-cache';
import { setSafeModeSession } from 'owa-config/lib/isSafeModeSession';
import { setRecoveryModeSession } from 'owa-config/lib/isRecoveryModeSession';

const thirtyDaysInMilleseconds = 2592000000;

/**
 *  Bootstraps the application
 *  See [README.md](../../../../README.md) for details on boot flow
 */
export default markFunction(bootstrap, 'btsp');

function bootstrap(options: BootstrapOptions): Promise<any> {
    setBundlingConfig(
        logUsage,
        task => lazyGovern.importAndExecute(task).then(results => results[0]),
        getWithinGovernor
    );

    addAnalyticsCallbacks(
        registerOwsCreateServiceResponseCallback,
        registerOwsPrimeCreateServiceResponseCallback
    );
    registerDefaultFontFaces(`${getCdnUrl()}assets/mail/fonts/v1`);
    setOwaWorkload(options.workload || 0);
    initializeSatchelMiddleware();

    if (!options.disableFeature?.registerFontSubsets) {
        registerFontSubsets(getResourcePath(), options.iconFonts);
    }

    return options.sessionPromise.then(async data => {
        markStart('sp');
        // sessionData should never be undefined in the bootstrap flow. It will only be undefined
        // when switching modules
        const sessionData = data as SessionData;

        if (!hasQueryStringParameter('bO')) {
            const jsTimestamp = findMetatag('jsTimestamp');
            if (jsTimestamp) {
                const expirationDate = parseInt(jsTimestamp) + thirtyDaysInMilleseconds;
                if (
                    typeof expirationDate == 'number' &&
                    data?.currentEpochInMs &&
                    data.currentEpochInMs > expirationDate
                ) {
                    throw createBootError(new Error('ExpiredBuild'), 'ExpiredBuild');
                }
            }

            // Since poisoning the build means to restart the client with a bO query string parameter
            // we don't want to do it if we already have the query string paramter present.
            if (
                doesClientBuildMatch(
                    getApplicationSettingsFromSessionData(sessionData, 'PoisonedBuild').skipBuilds
                )
            ) {
                throw createBootError(
                    /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
                     * Error constructor names can only be a string literals.
                     *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
                    new Error(`PoisonedBuild ${getClientVersion()}`),
                    'PoisonedBuild'
                );
            }
        } else {
            // Handle safe mode session
            if (getQueryStringParameter('bO') === '6') {
                setSafeModeSession();
            } else if (getQueryStringParameter('bO') === '7') {
                setRecoveryModeSession();
            }
        }

        // We want to minimize the amount of time that the account source list store is not initialized
        // so we initialize it as soon as we have the session data
        const sourceId = initOwaAccountSourceListStore(sessionData?.owaUserConfig);

        // Initialize stores from the startup data
        const analyticsOptions = options.analyticsOptions;
        const workerOptions = options.workerOptions;
        initializeStartupDataV1(
            sessionData,
            sourceId,
            BootState.StartupComplete,
            analyticsOptions,
            workerOptions
        );

        // Ensure secondary account feature flags and application settings are initialized
        bootstrapSecondaryAccountSettings();

        // we update the service config here as isUserIdle depends on the user configuration
        // and feature flags and isFeatureEnabled depends on the feature flags to be initialized
        updateServiceConfig({
            isUserIdle,
            isFeatureEnabled: (f: GlobalFeatureName, mailboxInfo?: MailboxInfo) =>
                isFeatureEnabled(f, mailboxInfo, true /*dontThrowErrorIfNotInitialized */),
            isAccountFeatureEnabled: (f: AccountFeatureName, mailboxInfo: MailboxInfo) =>
                isAccountFeatureEnabled(f, mailboxInfo, true /*dontThrowErrorIfNotInitialized */),
            checkAndHandleHipException: (
                headers?: HeadersWithoutIterator,
                mailboxInfo?: MailboxInfo
            ) => lazyCheckAndHandleHipException.importAndExecute(headers, mailboxInfo),
            getApplicationSettings: <TGroup extends ApplicationSettingGroup>(
                group: TGroup,
                mailboxInfo?: MailboxInfo,
                dontErrorIfNotInitialized?: boolean
            ) => getApplicationSettings(group, mailboxInfo, dontErrorIfNotInitialized),
        });

        let preRenderPromises: PromiseWithKey<unknown>[] = options.prerenderPromises || [];
        const hostPromise = getOpxHostData();
        if (hostPromise) {
            preRenderPromises.push({ promise: hostPromise, key: 'hp' });
        }

        const { locale, culture, dir } = getLocalizationContext(getUserConfiguration());
        preRenderPromises.push({
            promise: options
                .initializeLocalization(locale, culture, dir)
                .catch(e => {
                    addIfNotNull(e, 'source', 'InitLoc');
                    addIfNotNull(e, 'status', e.httpStatus);
                    return Promise.reject(e);
                })
                .then(() => {
                    initializeWindowTitle(options.getWindowTitle);
                }),
            key: 'lcl',
        });

        setDatetimeBootstrapOptions(options);
        if (!options.disableFeature?.dateTimeInitialization) {
            // Make sure shouldInitializeTimeZoneAnonymously is a boolean as undefined
            // will not initialize the time zones
            const dateTimePromise = initializeDateTime(
                !!options.shouldInitializeTimeZoneAnonymously,
                options.getTimeZoneOverrides
            );
            if (dateTimePromise && options.waitForDateTimeStore) {
                preRenderPromises.push({ promise: dateTimePromise, key: 'dt' });
            }

            dateTimePromise.then(updateLocalMailboxTimeZone);
        }

        // Start the application
        const initializeStatePromise = options.initializeState(sessionData);
        if (initializeStatePromise) {
            preRenderPromises.push({
                promise: initializeStatePromise,
                key: 'ist',
            });
        }

        if (options.routerOptions) {
            // Router initialization depends on user configuration, so initialize it after
            preRenderPromises.push({
                promise: initializeRouter(options.routerOptions),
                key: 'rt',
            });
        }

        const strategies = options.strategies;
        preRenderPromises = preRenderPromises.concat(preloadStrategies(strategies));

        // Await any additional promises before final render
        await trackBottleneck('ren', preRenderPromises);

        // Patch MobX to detect render loops
        if (isFeatureEnabled('fwk-detect-render-loop')) {
            patchReaction();
        }

        if (!options.renderMainComponent) {
            throw createBootError(new Error('RenderApp'), 'RenderApp');
        }

        const getAppContainer = () => {
            const container = document.getElementById('app');
            if (!container) {
                throw createBootError(new Error('NoContainer'), 'NoContainer');
            }

            return container;
        };

        // Return a Promise to render the initial app state
        const renderPromise = asyncRender(
            <ErrorBoundary
                fullErrorComponent={options.fullErrorComponent}
                onError={options.onComponentError}
                windowIcons={options.windowIcons}
            >
                {options.renderMainComponent()}
            </ErrorBoundary>,
            getAppContainer
        );

        trackTti();

        lazySetupSharedPostBoot.importAndExecute(options.swConfig, analyticsOptions ?? {});

        const postLazyAction = options.postLazyAction;
        if (postLazyAction) {
            postLazyAction.importAndExecute.apply(postLazyAction, options.postLazyArgs || []);
        }

        if (options.getStartAccountDataOverride) {
            lazySetStartAccountDataOverride.importAndExecute(options.getStartAccountDataOverride);
        }

        markEnd('sp');
        return renderPromise;
    });
}

function addIfNotNull(error: any, column: string, value: any) {
    if (error[column] === undefined) {
        error[column] = value;
    }
}

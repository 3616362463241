import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getAccountScopeUserSettings } from './getAccountScopeUserSettings';
import isConsumer from '../utils/isConsumer';

const PREMIUM_CONSUMER_OPTION = 'PremiumConsumerSetting';
const PREMIUM_CONSUMERBUSINESS_OPTION = 'IsBusinessConsumerOptions';

export default function isPremiumConsumer(mailboxInfo: MailboxInfo): boolean {
    if (!isConsumer(undefined, mailboxInfo)) {
        return false;
    }

    const settings = getAccountScopeUserSettings(mailboxInfo);
    if (settings.SessionSettings?.IsPremiumConsumerMailbox) {
        return true;
    }

    const primeSettingsItems = settings.PrimeSettings?.Items;
    if (isFeatureEnabled('auth-vsbisPremium')) {
        const primeIsBusinessConsumerSetting: any = primeSettingsItems?.filter(
            item => item?.Id === PREMIUM_CONSUMERBUSINESS_OPTION
        );
        if (primeIsBusinessConsumerSetting?.[0]?.Value.options?.[0]?.isBusinessConsumer === true) {
            return true;
        }
    }

    const primePremiumConsumerSetting: any = primeSettingsItems?.filter(
        item => item?.Id === PREMIUM_CONSUMER_OPTION
    );
    return primePremiumConsumerSetting?.[0]?.Value ?? false;
}

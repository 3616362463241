import { isFeatureEnabled } from 'owa-feature-flags';

type SubstrateSearchEndpoint =
    | 'suggestions'
    | 'events'
    | 'init'
    | 'query'
    | 'searchHistory'
    | 'feedback'
    | 'usercontrols';

export default function getSubstrateSearchEndpoint(
    endpoint: SubstrateSearchEndpoint,
    version?: number
) {
    const versionPath = version === 2 ? 'v2' : 'v1';
    const searchPath =
        isFeatureEnabled('sea-3sB2Url') && endpoint == 'query' ? 'searchservice' : 'search';

    return `${searchPath}/api/${versionPath}/${endpoint}`;
}

import type VirtualizedGroupHeader from '../type/VirtualizedGroupHeader';
import virtualizedMailGroupHeadersStore from '../store/store';

export default function getRowKeysFromCollapsedGroupHeaders() {
    const hiddenRowKeys: string[] = [];

    virtualizedMailGroupHeadersStore().virtualizedGroupHeaders.forEach(
        (header: VirtualizedGroupHeader) => {
            if (header.isCollapsed) {
                hiddenRowKeys.push(...header.rowKeys);
            }
        }
    );

    return hiddenRowKeys;
}

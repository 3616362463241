import { LazyImport, LazyModule, LazyAction, registerLazyOrchestrator } from 'owa-bundling';
import { fetchDumpsterFolder } from './actions/fetchDumpsterFolder';
import { fetchArchiveDumpsterFolder } from './actions/fetchArchiveDumpsterFolder';

const lazyModule = new LazyModule(() => import(/* webpackChunkName: "Folders" */ './lazyIndex'), {
    name: 'Folders',
});

// Export lazy loaded actions
export const lazyGetArchiveFolders = new LazyImport(lazyModule, m => m.getArchiveFolders);

export const lazyGetFolderPermissions = new LazyImport(lazyModule, m => m.getFolderPermissions);

export const lazyUpdateFolderPermissions = new LazyImport(
    lazyModule,
    m => m.updateFolderPermissions
);

export const lazyUpdateFolderPermissionsInFolderStore = new LazyImport(
    lazyModule,
    m => m.updateFolderPermissionsInStore
);

export const lazyFetchArchiveDumpsterFolder = new LazyAction(
    lazyModule,
    m => m.fetchArchiveDumpsterFolder
);
export const lazyFetchFavoriteFolders = new LazyImport(lazyModule, m => m.fetchFavoriteFolders);

// Export utils
export const lazyArePermissionArraysEqual = new LazyImport(
    lazyModule,
    m => m.arePermissionArraysEqual
);

export const lazyArePermissionsEqual = new LazyImport(lazyModule, m => m.arePermissionsEqual);

export const lazyGetSharedFoldersForSpecificRoot = new LazyImport(
    lazyModule,
    m => m.getSharedFolders
);

export const lazyUpdateFolder = new LazyAction(lazyModule, m => m.updateFolder);

export const lazyFetchMoreFolders = new LazyImport(lazyModule, m => m.fetchMoreFolders);

export const lazyCreateNewFolderInStore = new LazyAction(lazyModule, m => m.createNewFolderInStore);

export const lazyDeleteFoldersFromFolderTable = new LazyAction(
    lazyModule,
    m => m.deleteFoldersFromFolderTableMutator
);
export const lazyRemoveFolderFromParentFolder = new LazyAction(
    lazyModule,
    m => m.removeFolderFromParentFolderMutator
);
export const lazyMoveFolderToParentFolder = new LazyAction(
    lazyModule,
    m => m.moveFolderToParentFolderMutator
);
export const lazyFetchFavoriteFoldersResponse = new LazyAction(
    lazyModule,
    m => m.fetchFavoriteFoldersResponseMutator
);

export const lazyOutlookFavoritePersonaSearchFoldersLoaded = new LazyAction(
    lazyModule,
    m => m.outlookFavoritePersonaSearchFoldersLoaded
);

/**
 * Makes a service call to fetch the primary mail folders.
 */
export const lazyFetchPrimaryMailFolders = new LazyImport(
    lazyModule,
    m => m.fetchPrimaryMailFolders
);

export const lazyFetchDumpsterFolder = new LazyAction(lazyModule, m => m.fetchDumpsterFolder);

export const lazyFetchSearchFolders = new LazyAction(lazyModule, m => m.fetchSearchFolders);

export const lazyGetChildFolderIds = new LazyImport(lazyModule, m => m.getChildFolderIds);

export const lazyGetChildFolderNames = new LazyImport(lazyModule, m => m.getChildFolderNames);

export const lazyUpdateFoldersParentFolder = new LazyImport(
    lazyModule,
    m => m.updateFoldersParentFolder
);

export const lazyInitializeAlphabetizeFoldersList = new LazyAction(
    lazyModule,
    m => m.initializeAlphabetizeFoldersList
);

export const lazyToggleIsAlphabetizeFoldersEnabled = new LazyAction(
    lazyModule,
    m => m.toggleIsAlphabetizeFoldersEnabled
);

// Lazy orchestrators
registerLazyOrchestrator(fetchDumpsterFolder, lazyModule, m => m.fetchDumpsterFolderOrchestrator);
registerLazyOrchestrator(
    fetchArchiveDumpsterFolder,
    lazyModule,
    m => m.fetchArchiveDumpsterFolderOrchestrator
);

import { getAccountScopeUserSettings } from 'owa-session-store/lib/selectors/getAccountScopeUserSettings';
import { getIndexerValueForMailboxInfo, type MailboxInfo } from 'owa-client-types';

const puidMap: Map<string, string | undefined> = new Map();

export function getPuidFromMailboxInfo(mailboxInfo: MailboxInfo) {
    try {
        const indexer = getIndexerValueForMailboxInfo(mailboxInfo);
        if (puidMap.has(indexer)) {
            return puidMap.get(indexer);
        }

        /**
         * The analytics worker doesn't have account information (by design). The missing
         * store should not generate a grey error in this case.
         */
        const userSettings = getAccountScopeUserSettings(mailboxInfo, true /* noGreyError */);
        if (userSettings) {
            const puid = userSettings.SessionSettings?.UserPuid;
            if (puid) {
                puidMap.set(indexer, puid);
                return puid;
            }
        }
    } catch (e) {
        // No-op, we do not want to fail a datapoint creation if we can't get the ActingPuid
    }

    return undefined;
}

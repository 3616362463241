import type { ObservableMap } from 'mobx';
import { isMonarchMultipleAccountsEnabled } from 'owa-account-source-list/lib/flights';
import { isFeatureEnabled } from 'owa-feature-flags';
import folderStore, {
    getEffectiveFolderDisplayName,
    getMailRootFolderChildIds,
    getPrimaryFolderTreeRootFolder,
} from 'owa-folders';
import type { MailboxType, MailFolder } from 'owa-graph-schema';
import type { MailboxInfo } from 'owa-client-types';
import { isGroupSelected } from 'owa-group-utils';
import loc from 'owa-localize';
import mruStore from 'owa-mail-move-mru/lib/store/store';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import type MoveToFoldersType from '../store/schema/MoveToFoldersType';
import type MoveToFolderType from '../store/schema/MoveToFolderType';
import { getStore as getMoveToStore } from '../store/store';
import {
    DEFAULT_GROUP_FOLDER_MOVETO_DEPTH,
    getAllMoveToFoldersForSelectedGroup,
    getAllMoveToFoldersForGroup,
} from './getAllGroupFolders';
import { getRootFolderIdForGivenContext } from './moveToCommonUtils';
import { rootFolderString } from './moveToStringUtils.locstring.json';
import { isSameCoprincipalAccountMailboxInfos } from 'owa-client-types';
export const DEFAULT_MAX_FOLDERS = 8;

export function IsMailboxesFilterSupported(
    moveToMailboxType: MailboxType,
    mailboxInfo: MailboxInfo
): boolean {
    if (isMonarchMultipleAccountsEnabled()) {
        return mailboxInfo.type === 'UserMailbox' && moveToMailboxType === 'UserMailbox';
    }

    return false;
}

function sortByDisplayName(array: Array<MoveToFolderType>) {
    array.sort((folder1: MoveToFolderType, folder2: MoveToFolderType) => {
        return folder1.displayName.localeCompare(folder2.displayName);
    });
}

function sortByPrefixMatch(array: Array<MoveToFolderType>, searchText: string) {
    array.sort((folder1: MoveToFolderType, folder2: MoveToFolderType) => {
        const folder1Name = folder1.displayName.toLocaleLowerCase();
        const folder2Name = folder2.displayName.toLocaleLowerCase();
        const folder1IsPrefixMatch = folder1Name.indexOf(searchText) == 0;
        const folder2IsPrefixMatch = folder2Name.indexOf(searchText) == 0;

        // Sort array so that folders with prefix match shows first
        if (folder1IsPrefixMatch && !folder2IsPrefixMatch) {
            return -1;
        } else if (!folder1IsPrefixMatch && folder2IsPrefixMatch) {
            return 1;
        } else {
            return 0;
        }
    });
}

function unique(array: Array<any>): Array<any> {
    const map = {};

    for (var i = 0, len = array.length; i < len; i++) {
        const value = array[i];

        if (value) {
            // We assign 0 just to store something. We only care about the keys anyway.
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS7053 (81,13): Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{}'.
            // @ts-expect-error
            map[value] = 0;
        }
    }

    return Object.keys(map);
}

function getFolderToMoveToMailboxInfo(
    moveToMailboxType: MailboxType,
    mailboxInfo: MailboxInfo
): MailboxInfo {
    let smtpAddress = mailboxInfo.mailboxSmtpAddress;
    if (moveToMailboxType === 'SharedMailbox' && getMoveToStore().moveToSharedFolderRootId) {
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        smtpAddress = getMoveToStore().moveToSharedFolderRootId!;
    }
    return {
        type: moveToMailboxType,
        userIdentity: mailboxInfo.userIdentity,
        mailboxSmtpAddress: smtpAddress,
    };
}

/**
 * Gets folder ids from current moveTo mailbox for searched text
 * @param moveToMailboxType - moveTo mailbox type for search
 * @param search - search text
 * @param folderTable - folder table from folder store
 * @param mailboxInfo - MailboxInfo object
 * @param searchFoldersIds - if present, folder ids for search folders
 * @return - folder ids
 */
function getFolderIdsForSearch(
    moveToMailboxType: MailboxType,
    search: string,
    folderTable: ObservableMap<string, MailFolder>,
    mailboxInfo: MailboxInfo,
    searchFoldersIds?: string[]
): string[] {
    let allFolderIds: string[] = [];

    allFolderIds = searchFoldersIds
        ? [...searchFoldersIds]
        : getMailRootFolderChildIds(getFolderToMoveToMailboxInfo(moveToMailboxType, mailboxInfo));

    const folderIds: string[] = [];
    let folderId;
    while ((folderId = allFolderIds.pop())) {
        const folder = folderTable.get(folderId);
        if (folder) {
            const folderName = getEffectiveFolderDisplayName(folder).toLocaleLowerCase();
            if (folderName.indexOf(search) >= 0) {
                folderIds.push(folderId);
            }

            // Append child folderIds to the queue.
            if (folder.childFolderIds) {
                for (const childFolderId of folder.childFolderIds) {
                    allFolderIds.push(childFolderId);
                }
            }
        }
    }

    return folderIds;
}

/**
 * Gets all folders for given mailbox type
 * @param moveToMailboxType - moveTo mailbox type
 * @param shouldShowRootFolder - flag indicating whether to show root folder in all folder menu
 * @return - all folders of the mailbox contained in interface MoveToFoldersType
 */
export function getAllFoldersForMoveTo(
    moveToMailboxType: MailboxType,
    mailboxInfo: MailboxInfo,
    shouldShowRootFolder?: boolean,
    useMailboxInfoForRootDisplayName?: boolean
): MoveToFoldersType {
    const allFoldersList: MoveToFolderType[] = [];
    const rootFolderChildren = getMailRootFolderChildIds(
        getFolderToMoveToMailboxInfo(moveToMailboxType, mailboxInfo)
    );
    const rootFolder = getPrimaryFolderTreeRootFolder(mailboxInfo);
    let depthValue = 0;
    if (
        rootFolder &&
        shouldShowRootFolder &&
        (moveToMailboxType === 'UserMailbox' || moveToMailboxType === 'PstFile')
    ) {
        allFoldersList.push({
            folderId: rootFolder.id,
            displayName: useMailboxInfoForRootDisplayName
                ? rootFolder.mailboxInfo.mailboxSmtpAddress
                : loc(rootFolderString),
            parentFolderId: rootFolder.parentFolderId,
            parentFolderDisplayName: '',
            depth: depthValue++,
            mailboxInfo: rootFolder.mailboxInfo,
        });
    }
    addAllChildFoldersToFolderList(allFoldersList, rootFolderChildren, depthValue);
    return { folders: allFoldersList, hasExactMatch: false };
}

// Add child folders
function addAllChildFoldersToFolderList(
    allFoldersList: MoveToFolderType[],
    childFolders: string[],
    depth: number
) {
    const folderTable = folderStore.folderTable;

    for (const childFolderId of childFolders) {
        const childFolder = folderTable.get(childFolderId);

        // Don't show Notes folder or search folders in move to option
        if (
            childFolder &&
            folderIdToName(childFolderId) != 'notes' &&
            folderIdToName(childFolderId) != 'searchfoldersview'
        ) {
            const folder = folderTable.get(childFolder.id);
            if (!folder) {
                continue;
            }
            const parentFolderId = folder?.parentFolderId;
            allFoldersList.push({
                folderId: folder.id,
                displayName: getEffectiveFolderDisplayName(folder),
                parentFolderId: parentFolderId ?? '',
                parentFolderDisplayName: parentFolderId
                    ? getEffectiveFolderDisplayName(folderTable.get(parentFolderId))
                    : '',
                depth,
                mailboxInfo: folder.mailboxInfo,
            });

            // Don't show deleted folders in "Move to"
            if (
                childFolder.childFolderIds &&
                childFolder.distinguishedFolderType !== 'deleteditems'
            ) {
                addAllChildFoldersToFolderList(
                    allFoldersList,
                    childFolder.childFolderIds,
                    depth + 1
                );
            }
        }
    }
}

export interface GetFoldersForMoveToState {
    folderTable: ObservableMap<string, MailFolder>;
}

/**
 * get folders for move to menu
 * @param moveToMailboxType - moveTo mailbox type
 * @param search - searched text
 * @param maxItems - maximum number of folders that should be returned
 * @param customFilter - function defining custom filter for folders found
 * @param disableSelectedFolder - flag to determine whether to hide the current selected folder
 * @param selectedFolderId - the selected folder id
 * @param onlySearchFolders - should only search folders be fetched?
 * @param state - folderStore
 * @return - folders found contained in MoveToFoldersType interface
 */
const getFoldersForMoveTo = function getFoldersForMoveTo(
    moveToMailboxType: MailboxType,
    mailboxInfo: MailboxInfo,
    depth: number,
    search?: string,
    maxItems?: number,
    customFilter?: (folderId: string) => boolean,
    disableSelectedFolder?: boolean,
    selectedFolderId?: string,
    shouldShowNotes?: boolean,
    searchFoldersIds?: string[],
    state: GetFoldersForMoveToState = { folderTable: folderStore.folderTable }
): MoveToFoldersType {
    search = (search || '').trim().toLocaleLowerCase();
    maxItems = maxItems || DEFAULT_MAX_FOLDERS;
    let hasExactMatch = false;
    const isSearchTextPresent = search.length > 0;
    let folderIds: string[] = [];

    folderIds =
        isSearchTextPresent || searchFoldersIds
            ? getFolderIdsForSearch(
                  moveToMailboxType,
                  search,
                  state.folderTable,
                  mailboxInfo,
                  searchFoldersIds
              )
            : mruStore(mailboxInfo).mruFolders;

    if (customFilter) {
        folderIds = folderIds.filter(customFilter);
    }
    folderIds = unique(folderIds);

    if (IsMailboxesFilterSupported(moveToMailboxType, mailboxInfo)) {
        folderIds = folderIds.filter(folderId =>
            isSameCoprincipalAccountMailboxInfos(
                folderStore.folderTable.get(folderId)?.mailboxInfo,
                mailboxInfo
            )
        );
    }

    const folders: MoveToFolderType[] = [];
    const rootFolderIdForGivenMailboxType =
        moveToMailboxType === 'SharedMailbox'
            ? getMoveToStore().moveToSharedFolderRootId
            : getRootFolderIdForGivenContext(moveToMailboxType, mailboxInfo);
    for (const folderId of folderIds) {
        const folder = state.folderTable.get(folderId);
        if (folder) {
            // Check to skip notes folder option in move to menu
            if (folderIdToName(folder.id) === 'notes' && !shouldShowNotes) {
                continue;
            }

            // check to skip search folders in move to menu
            if (folderIdToName(folder.parentFolderId) === 'searchfoldersview') {
                continue;
            }

            if (!isSearchTextPresent && disableSelectedFolder && folderId === selectedFolderId) {
                continue;
            }
            const folderName = getEffectiveFolderDisplayName(folder);
            // Parent folder Id can be null if folder is root, and parent folder is not necessarily in
            // the folder table (mru can be out of sync because of desktop deletion)
            const parentFolderId = folder.parentFolderId || null;
            const parentFolder = parentFolderId ? state.folderTable.get(parentFolderId) : null;
            // We have an exact match if the search string is non-empty and it matches a folder
            // at the root of the current mailbox type
            if (
                isSearchTextPresent &&
                parentFolderId == rootFolderIdForGivenMailboxType &&
                folderName.toLocaleLowerCase() == search
            ) {
                hasExactMatch = true;
            }
            folders.push({
                folderId: folder.id,
                displayName: folderName,
                parentFolderId: parentFolderId ?? '',
                parentFolderDisplayName: parentFolder
                    ? getEffectiveFolderDisplayName(parentFolder)
                    : '',
                depth,
                mailboxInfo: folder.mailboxInfo,
            });
        }
    }
    if (search) {
        sortByDisplayName(folders);
        sortByPrefixMatch(folders, search);
    }
    return {
        folders: folders.slice(0, maxItems),
        hasExactMatch,
    };
};

/**
 * get all group folders for move to menu
 * @param clickedGroupId Group id of the group whose context menu is being used(in the left nav)
 * @returns all group folders of the mailbox contained in interface MoveToFoldersType
 */
function getAllGroupFoldersForMoveTo(clickedGroupId?: string): MoveToFoldersType {
    const folders = clickedGroupId
        ? getAllMoveToFoldersForGroup(clickedGroupId)
        : getAllMoveToFoldersForSelectedGroup();

    return {
        folders,
        hasExactMatch: false,
    };
}

/**
 * get group folders for move to menu
 * @param findText search text
 * @param resetDepth flag indicating whether the caller expects fixed depth for all the folders (for UI)
 * @param disableSelectedFolder flag to determine whether to hide the current selected folder
 * @param selectedFolderId he selected folder id
 * @param maxItems maximum number of folders that should be returned
 * @param clickedGroupId Group id of the group whose context menu is being used(in the left nav)
 * @returns group folders found contained in MoveToFoldersType interface
 */
function getFilteredGroupFoldersForMoveTo(
    findText: string,
    resetDepth: boolean,
    disableSelectedFolder?: boolean,
    selectedFolderId?: string,
    maxItems?: number,
    clickedGroupId?: string
): MoveToFoldersType {
    const allGroupFolders = clickedGroupId
        ? getAllMoveToFoldersForGroup(clickedGroupId)
        : getAllMoveToFoldersForSelectedGroup();

    const result: MoveToFolderType[] = [];
    let hasExactMatch = false;

    findText = (findText || '').trim().toLocaleLowerCase();
    maxItems = maxItems || DEFAULT_MAX_FOLDERS;

    for (const currentFolder of allGroupFolders) {
        if (!findText && disableSelectedFolder && currentFolder?.folderId === selectedFolderId) {
            continue;
        }

        if (findText && currentFolder?.displayName.toLocaleLowerCase() === findText) {
            hasExactMatch = true;
        }

        if (!findText || currentFolder?.displayName.toLocaleLowerCase().indexOf(findText) >= 0) {
            result.push(currentFolder);
        }
    }

    if (findText) {
        sortByDisplayName(result);
        sortByPrefixMatch(result, findText);
    }

    if (resetDepth) {
        // MoveTo UI needs a fix depth when showing folders
        result.forEach(element => (element.depth = DEFAULT_GROUP_FOLDER_MOVETO_DEPTH));
    }

    return {
        folders: result.slice(0, maxItems),
        hasExactMatch,
    };
}

/**
 * get group folders filtered by search text
 * @param findText searched text
 * @param shouldShowAllFolders flag indicating whether to show all folders
 * @param resetDepth flag indicating whether the caller expects fixed depth for all the folders (for UI)
 * @param disableSelectedFolder flag to determine whether to hide the current selected folder
 * @param selectedFolderId the selected folder id
 * @param clickedGroupId Group id of the group whose context menu is being used(in the left nav)
 * @returns groups folders with MoveToFoldersType interface
 */
function getGroupFoldersForMoveTo(
    findText: string,
    shouldShowAllFolders: boolean,
    resetDepth: boolean,
    disableSelectedFolder?: boolean,
    selectedFolderId?: string,
    clickedGroupId?: string
): MoveToFoldersType {
    if (shouldShowAllFolders && !findText) {
        return getAllGroupFoldersForMoveTo(clickedGroupId);
    } else {
        return getFilteredGroupFoldersForMoveTo(
            findText,
            resetDepth,
            disableSelectedFolder,
            selectedFolderId,
            DEFAULT_MAX_FOLDERS,
            clickedGroupId
        );
    }
}

/**
 * get folders for a given mailbox filtered by search text
 * @param moveToMailboxType - moveTo mailbox type
 * @param findText - searched text
 * @param shouldShowAllFolders - flag indicating whether to show all folders
 * @param shouldShowRootFolder - flag indicating whether to show root folder in all folder menu
 * @param disableSelectedFolder - flag to determine whether to hide the current selected folder
 * @param selectedFolderId - the selected folder id
 * @param supportedMailBoxTypes - Used to provide mailbox configurations not supported by MoveTo store
 * @param clickedGroupId Group id of the group whose context menu is being used(in the left nav)
 * @return - folders with MoveToFoldersType interface
 */
export function getFoldersForGivenMailboxType(
    moveToMailboxType: MailboxType,
    findText: string,
    shouldShowAllFolders: boolean,
    mailboxInfo: MailboxInfo,
    shouldShowRootFolder?: boolean,
    disableSelectedFolder?: boolean,
    selectedFolderId?: string,
    supportedMailBoxTypes?: MailboxType[],
    clickedGroupId?: string,
    useMailboxInfoForRootDisplayName?: boolean
): MoveToFoldersType {
    if (
        isFeatureEnabled('grp-loadFolders') &&
        supportedMailBoxTypes &&
        supportedMailBoxTypes.indexOf('GroupMailbox') !== -1 &&
        (isGroupSelected() || clickedGroupId)
    ) {
        return getGroupFoldersForMoveTo(
            findText,
            shouldShowAllFolders,
            true, // resetDepth : Move To UI expects a fix depth irrespective of the folder heirarchy.
            disableSelectedFolder,
            selectedFolderId,
            clickedGroupId
        );
    } else if (shouldShowAllFolders && !findText) {
        // show all folders
        return getAllFoldersForMoveTo(
            moveToMailboxType,
            mailboxInfo,
            shouldShowRootFolder,
            useMailboxInfoForRootDisplayName
        );
    } else {
        // show filtered list of folders of selected mbx
        return getFoldersForMoveTo(
            moveToMailboxType,
            mailboxInfo,
            0 /* depth */,
            findText,
            DEFAULT_MAX_FOLDERS,
            undefined /* customFilter */,
            disableSelectedFolder,
            selectedFolderId
        );
    }
}

export default getFoldersForMoveTo;

import type { AddinCommandSurfaceItem } from 'owa-addins-types';
import getStore from '../store';
import type { MailboxInfo } from 'owa-client-types';

export default function getThirdPartyImProvider(
    mailboxInfo: MailboxInfo
): AddinCommandSurfaceItem | undefined {
    const matchedInfo = getStore().thirdPartyImProviderInfoArray.find(
        info => info.mailboxInfo?.mailboxSmtpAddress === mailboxInfo?.mailboxSmtpAddress
    );

    return matchedInfo?.imProvider;
}

import type { PerformanceDatapoint } from 'owa-analytics';
import type { ReadingPaneSetting } from 'owa-mail-store';
import type LoadItemOptions from '../store/schema/LoadItemOptions';
import { isOfflineMailDataEnabled } from 'owa-offline-sync-feature-flags';
import endSelectMailItemDatapoint from 'owa-mail-logging/lib/utils/endSelectMailItemDatapoint';
import { type DatapointStatus } from 'owa-analytics-types';

export function addCustomDataToSelectMailItem(
    selectMailItemDatapoint?: PerformanceDatapoint | null,
    readingPaneSetting?: ReadingPaneSetting,
    isSingleLineView?: boolean
) {
    if (!!selectMailItemDatapoint) {
        selectMailItemDatapoint.addToCustomWaterfall(
            1,
            'readingPaneSetting',
            false /* discardIfDefined */,
            readingPaneSetting as string
        );
        if (isSingleLineView !== undefined) {
            selectMailItemDatapoint.addToCustomWaterfall(
                2,
                'isSingleLineView',
                false /* discardIfDefined */,
                isSingleLineView ? 'true' : 'false'
            );
        }
        selectMailItemDatapoint.addToCustomWaterfall(
            6,
            'isOfflineSyncEnabled',
            false /* discardIfDefined*/,
            isOfflineMailDataEnabled() ? 'true' : 'false'
        );
    }
}

export function isCoreScenario(isSmime: boolean, options?: LoadItemOptions): boolean {
    return !(
        isSmime ||
        (options &&
            (options.isDeepLink ||
                options.isPreviewPane ||
                options.isPrint ||
                options.isProjection ||
                options.isReload))
    );
}

export function endDatapointWithErrorState(
    dpStatus: DatapointStatus,
    error: Error,
    nonCoreScenarioDp?: PerformanceDatapoint | null,
    actionSource?: string,
    isCached?: boolean,
    isPreviewPane?: boolean,
    isPrint?: boolean
) {
    endSelectMailItemDatapoint('Error', false /* shouldInvalidate */, dpStatus, error);
    if (nonCoreScenarioDp) {
        // End and log current 'OpenItemNonCritical / OpenConversationNonCritical' datapoint with error.
        nonCoreScenarioDp.addCustomData({ actionSource, isCached, isPreviewPane, isPrint });
        nonCoreScenarioDp.endWithError(dpStatus, error);
    }
}

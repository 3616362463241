export const archiveButtonDesc = "dtCfId";
export const assignPolicyButtonDesc = "M9WoTd";
export const blockButtonDesc = "CE1mRb";
export const browseGroupsButtonDesc = "lHjvz";
export const neverBlockButtonDesc = "agMVmc";
export const categorizeButtonDesc = "w5JQrc";
export const createRuleButtonDesc = "VIFFXc";
export const customizeButtonDesc = "CVdg$";
export const deleteButtonDesc = "Uaj9F";
export const discardButtonDesc = "ZvsDAc";
export const deleteEmptyFolderButtonDesc = "zhVEEb";
export const encryptButtonDesc = "KM5EDd";
export const flagButtonDesc = "dYqvdd";
export const flagClearFlagButtonDesc = "iCXTYb";
export const flagNextWeekButtonDesc = "mpMGw";
export const flagNoDateButtonDesc = "eN_ecc";
export const flagThisWeekButtonDesc = "noCNpb";
export const flagTodayButtonDesc = "ugSIRb";
export const flagTomorrowButtonDesc = "A3tJX";
export const flagCustomButtonDesc = "gh1M1b";
export const flagMarkCompleteButtonDesc = "hvjrdc";
export const forwardButtonDesc = "AveHx";
export const forwardEmailAsAttachmentDesc = "ZpxI5";
export const getAddinsButtonDesc = "UuVODe";
export const getMessageExtensionsButtonDesc = "oNVHle";
export const getM365AppsButtonDesc = "wh9ezd";
export const ignoreButtonDesc = "NLZuwb";
export const immersiveReaderButtonDesc = "Vl$w5c";
export const junkButtonDesc = "zBJRoe";
export const manageRulesButtonDesc = "PsXX3c";
export const moreRetentionPoliciesButtonDesc = "w5eJX";
export const moveButtonDesc = "kVEfhc";
export const newMessageButtonDesc = "KjYDj";
export const newFolderPostButtonDesc = "rG6Zzd";
export const phishingButtonDesc = "H9iTk";
export const pinButtonDesc = "YzVDdc";
export const readUnreadButtonDesc = "_EZ5Cb";
export const replyButtonDesc = "ltmaR";
export const replyAllButtonDesc = "Ymp2tb";
export const replyWithMeetingButtonDesc = "O5oded";
export const copilotReplyWithMeetingButtonDesc = "ahokCd";
export const rulesButtonDesc = "oIp6Mb";
export const sendDraftButtonDesc = "wzvKbd";
export const sensitivityButtonDesc = "UoulWc";
export const sensitivityLearnMoreButtonDesc = "F9LvT";
export const snoozeButtonDesc = "p3Y8le";
export const sweepButtonDesc = "tk62Kd";
export const undoButtonDesc = "VeZmEb";
export const deleteNoteDesc = "AU6vf";
export const changeNoteColorDesc = "mPELQc";
export const viewEmailFromNotesDesc = "XWM8re";
export const layoutDesc = "RyRE_";
export const messagesDesc = "PU8OIb";
export const viewSettingsDesc = "LEV$Cb";
export const conversationsDesc = "xVvxPb";
export const messagePreviewDesc = "a7v7Ee";
export const folderPaneDesc = "NawIie";
export const readingPaneDesc = "FjQnMe";
export const densityDesc = "XmOINc";
export const markAllAsReadDesc = "hjmTo";
export const manageQuickStepsDesc = "eckUGb";
export const viewGroupsPanelButtonDesc = "ijr3ne";
export const setupQuickStepsDesc = "AvdhUc";
export const restoreDesc = "fhpBmc";
export const restoreAllDesc = "oXZcib";
export const remindersWindowDesc = "s4YrM";
export const printEmailDesc = "ol0yRd";
export const messageRecallDesc = "fg9Iic";
export const expandCollapseConversationDesc = "ROncw";
export const zoomDesc = "gTtzjd";
export const ribbonModeDesc = "ejzitd";
export const trackReadReceiptsDesc = "TGb5Rd";
export const syncMailboxDesc = "urvgVd";
export const chatAroundEmail = "lI9Sqb";
export const chatAroundEmailAll = "MwtiBe";
export const resendDesc = "sxeI8c";
export const shareToTeamsChat = "W2bYBd";
export const myDayButtonDesc = "zh7xWd";
export const chatwithThirdPartyIM = "P0PLA";
export const lightRPDesc = "JJEqN";
export default {archiveButtonDesc,assignPolicyButtonDesc,blockButtonDesc,browseGroupsButtonDesc,neverBlockButtonDesc,categorizeButtonDesc,createRuleButtonDesc,customizeButtonDesc,deleteButtonDesc,discardButtonDesc,deleteEmptyFolderButtonDesc,encryptButtonDesc,flagButtonDesc,flagClearFlagButtonDesc,flagNextWeekButtonDesc,flagNoDateButtonDesc,flagThisWeekButtonDesc,flagTodayButtonDesc,flagTomorrowButtonDesc,flagCustomButtonDesc,flagMarkCompleteButtonDesc,forwardButtonDesc,forwardEmailAsAttachmentDesc,getAddinsButtonDesc,getMessageExtensionsButtonDesc,getM365AppsButtonDesc,ignoreButtonDesc,immersiveReaderButtonDesc,junkButtonDesc,manageRulesButtonDesc,moreRetentionPoliciesButtonDesc,moveButtonDesc,newMessageButtonDesc,newFolderPostButtonDesc,phishingButtonDesc,pinButtonDesc,readUnreadButtonDesc,replyButtonDesc,replyAllButtonDesc,replyWithMeetingButtonDesc,copilotReplyWithMeetingButtonDesc,rulesButtonDesc,sendDraftButtonDesc,sensitivityButtonDesc,sensitivityLearnMoreButtonDesc,snoozeButtonDesc,sweepButtonDesc,undoButtonDesc,deleteNoteDesc,changeNoteColorDesc,viewEmailFromNotesDesc,layoutDesc,messagesDesc,viewSettingsDesc,conversationsDesc,messagePreviewDesc,folderPaneDesc,readingPaneDesc,densityDesc,markAllAsReadDesc,manageQuickStepsDesc,viewGroupsPanelButtonDesc,setupQuickStepsDesc,restoreDesc,restoreAllDesc,remindersWindowDesc,printEmailDesc,messageRecallDesc,expandCollapseConversationDesc,zoomDesc,ribbonModeDesc,trackReadReceiptsDesc,syncMailboxDesc,chatAroundEmail,chatAroundEmailAll,resendDesc,shareToTeamsChat,myDayButtonDesc,chatwithThirdPartyIM,lightRPDesc};
import { createLazyComponent, LazyModule, LazyAction } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "OwaHipCheckModal" */ './lazyIndex')
);

export const ArkoseModal = createLazyComponent(lazyModule, m => {
    return m.ArkoseModal;
});

export const lazyHandleHipException = new LazyAction(lazyModule, m => {
    return m.handleHipException;
});

export const lazyCheckAndHandleHipException = new LazyAction(lazyModule, m => {
    return m.checkAndHandleHipException;
});

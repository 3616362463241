import { type StateChangeSource } from '../types/StateChangeSource';

// State change source for logging
export const STATE_CHANGE_SOURCES_MAP = {
    [0]: 'TnS_FolderPaneChange_Ribbon',
    [1]: 'TnS_FolderPaneChange_LeftNav',
    [3]: 'TnS_FolderPaneChange_MailList',
    [2]: 'TnS_FolderPaneChange_Tabs',
    [4]: 'TnS_FolderPaneChange_Shortcut',
    [5]: 'TnS_FolderPaneChange_RibbonViewTab',
    [6]: 'TnS_FolderPaneChange_LeftNavToggleButton',
    [7]: 'TnS_FolderPaneChange_LeftNavDrag',
};

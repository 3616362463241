import { useIntersectionObserver } from 'owa-react-hooks/lib/useIntersectionObserver';
import React from 'react';
import { logGreyError } from 'owa-analytics';
import { isFeatureEnabled } from 'owa-feature-flags';

type DivProperties = {
    id: string;
    scrollTopValue: number;
    scrollHeightValue: number;
    height: number;
    width: number;
};

const appContainerDivName = 'appContainer';

/* eslint-disable no-restricted-properties --
 * Reading restricted properties from the DOM is necessary for detecting elements causing the app to render improperly in the viewport
 */

function getDivProperties(appId: string): DivProperties | undefined {
    const appElement = document.getElementById(appId) as HTMLElement;
    if (!appElement) {
        return undefined;
    }

    const style = window.getComputedStyle(appElement);
    const divProperties: DivProperties = {
        id: appElement.id,
        scrollTopValue: appElement.scrollTop,
        scrollHeightValue: appElement.scrollHeight,
        height: parseInt(style.height, 10),
        width: parseInt(style.width, 10),
    };

    return divProperties;
}

export function useInitializeViewportAdjustment(titleBarRef: React.RefObject<HTMLDivElement>) {
    const intersectionCallback = React.useCallback(
        (entry: IntersectionObserverEntry) => {
            if (titleBarRef) {
                const titleBarElement = titleBarRef.current;
                if (
                    titleBarElement &&
                    entry.target == titleBarElement &&
                    entry.intersectionRatio < 1 &&
                    entry.boundingClientRect.top != 0
                ) {
                    logTitleBarAboveViewportError(entry);
                }
            }
        },
        [titleBarRef.current]
    );

    useIntersectionObserver('TitleBarAboveViewport', titleBarRef, intersectionCallback, {
        root: null, // Use the viewport as the root
        threshold: 1, // Trigger as soon as any part of the element is hidden
    });
}

var timesLoggedTitleBarAboveViewportError = 0;

// Title bar should be positioned at the top of the window with y-coord 0
// If it's not, OWA is rendering improperly and this will log the position
// Limit to 10x per session to reduce volume in long-lived sessions with this error
function logTitleBarAboveViewportError(entry: IntersectionObserverEntry) {
    if (timesLoggedTitleBarAboveViewportError < 10) {
        timesLoggedTitleBarAboveViewportError++;

        // We want to ignore any transient render issues, e.g. during app boot; so measure and report after
        // a short timeout
        window.setTimeout(() => {
            const appContainerDiv = getDivProperties(appContainerDivName);

            logGreyError(
                'TitleBarNotPositionedAtTopOfWindow',
                new Error('TitleBar not positioned at y-coordinate 0'),
                {
                    titleBarTop: entry.target.getBoundingClientRect().y,
                    appContainerScrollTop: appContainerDiv?.scrollTopValue,
                    appContainerScrollHeight: appContainerDiv?.scrollHeightValue,
                    appContainerHeight: appContainerDiv?.height,
                    appContainerWidth: appContainerDiv?.width,
                    mainAppRelative: isFeatureEnabled('fwk-mainAppRelative'),
                    bodyScrollTop: document.body.scrollTop,
                    appScrollTop: document.getElementById('app')?.scrollTop,
                    ver: 2,
                }
            );
        }, 1000);
    }
}

/* eslint-enable no-restricted-properties */

import type { GlobalFeatureName } from 'owa-feature-flags';
import { isFeatureEnabled } from 'owa-feature-flags';

import { default as getApplicationSettings } from '../../selectors/getApplicationSettings';
import { ResolverEnabledFor } from './ResolverEnabledFor';

// Repeating type defintion from owa-graph-schema to avoid circular dependency
type ResolverPolicy =
    | 'localFirst' // Try the idb resolver first and fall back to the web resolver if the idb resolver fails
    | 'serverFirst' // Try the web resolver first and fall back to the idb resolver if the web resolver fails
    | 'serverOnly' // Only use the web resolver
    | 'localOnly'; // Only use the idb resolver

const IndividualResolverFeatureMappings = new Map([
    ['markItemAsRead', 'fwk-useoutlookgateway-markItemAsRead'],
    ['collab', 'fwk-useoutlookgateway-collabObject'],
    ['cities', 'fwk-useoutlookgateway-places-cities'],
    ['roomLists', 'fwk-useoutlookgateway-places-roomLists'],
    ['rooms', 'fwk-useoutlookgateway-places-rooms'],
    ['spaces', 'fwk-useoutlookgateway-places-spaces'],
    ['getScheduleWithLocation', 'msplaces-useOutlookGatewayReferenceResolversOverLocal'],
]);

const LocalOnlyResolvers: Set<string> = new Set([
    // Persona Queries
    'me',
    'peopleAppAccounts',
    'contactPersona',
    'personas',
    'peopleAppPersonaSearch',
    'shadowPdvPersona',
    'pgalSyncState',

    // Persona Mutations
    'bulkDeleteContacts',
    'createContact',
    'updateContact',
    'deleteContact',
    'addTagForPerson',
    'removeTagFromPerson',

    // Persona Subscriptions
    'subscribeToPersonasChangeNotifications',
    'contactsMutationEvent',
    'pgalSyncStateEvent',

    // Other resolvers
    'undoSend',
    'subscribeToConversationNotifications',
    'isDisplayNameOrderChangedEvent',
]);

export const CalendarQueryResolvers: Set<string> = new Set([
    'calendarEvents',
    'calendarGroups',
    'collab',
    'fullBirthdayCalendarEvent',
    'fullCalendarEvent',
    'getSchedule',
    'sharedCalendarAdditionalInfo',
    'subscribeToCalendarEventNotifications',
]);

export const MailQueryResolvers: Set<string> = new Set([
    'conversation',
    'conversationNode',
    'conversationNodes',
    'conversationRows',
    'item',
    'items',
    'itemRows',
    'subscribeToConversationNotifications',
    'subscribeToRowNotifications',
]);

export const MailMutateResolvers: Set<string> = new Set([
    'createDraft',
    'createSmartResponse',
    'saveDraft',
    'saveSmartResponse',
    'discardDraft',
    'sendItem',
    'undoSaveSend',
]);

export const FolderQueryResolvers: Map<string, GlobalFeatureName | undefined> = new Map([
    ['folderHierarchy', 'fwk-offline-mail'],
    ['folder', 'fp-offline-folder-query'],
    ['folders', 'fp-offline-folder-query'],
    ['startAccountData', 'fp-start-account-data-idb'],
]);

export const FolderMutateResolvers: Map<string, GlobalFeatureName | undefined> = new Map([
    ['renameFolder', 'fp-offline-folder-actions'],
    ['createFolder', 'fp-offline-folder-actions-v2'],
    ['moveFolder', 'fp-offline-folder-actions-v2'],
    ['deleteFolder', 'fp-offline-folder-actions-v2'],
]);

export const FavoriteMutateResolvers: Map<string, GlobalFeatureName | undefined> = new Map([
    ['addFavorite', 'fp-favorites-resolvers'],
    ['removeFavorite', 'fp-favorites-resolvers'],
]);

// Will keep triage idb mutators that are enabled by flights. When fully gratudated, then removing the entry should be enough
// If no flight, then mutator is disabled by default
const MailTriageIdbMutateResolvers: Map<string, GlobalFeatureName | undefined> = new Map([
    ['updateUserConfiguration', 'fwk-aq-userConfig'],
    ['saveAutomaticRepliesConfig', 'fwk-aq-userConfig'],
    ['autoReplyConfig', 'fwk-aq-userConfig'],
    ['updateMasterCategoryList', 'fwk-aq-userConfig'],
    ['updateMobileDevicesStatistics', 'fwk-aq-userConfig'],
    ['mobileDevicesStatistics', 'fwk-aq-userConfig'],
]);

export type ResolverEnabledSettings = {
    Query: {
        [k: string]: ResolverEnabledFor;
    };
    Mutation: {
        [k: string]: ResolverEnabledFor;
    };
    Subscription: {
        [k: string]: ResolverEnabledFor;
    };
};

export function enabledForWeb(
    op: 'Query' | 'Mutation' | 'Subscription',
    resolver: string,
    skipRemoteCallForcefully: boolean,
    resolverPolicy?: ResolverPolicy
) {
    if (resolverPolicy === 'localOnly') {
        return false;
    }
    const enabled = getEnabled(op, resolver, skipRemoteCallForcefully);
    return (enabled & ResolverEnabledFor.Web) == ResolverEnabledFor.Web;
}

// The preferred pattern for offline capable mutations is:
// - submit a queuedaction and have the idbresolver persist the local lie associated with the mutation
// - have the action queue execute the webresolver when it can (e.g., when the user is back online)
//
// Executing an idb mutation without queuing the webresolver portion can lead to the local lie being
// persisted without the webresolver being executed. This can lead to inconsistencies between the local and remote state.
//
// But, there are some cases where the idb resolver executes both the online portion of the mutation and the local lie
// without attempting queuing.  Either both succeed or both fail.  For these cases, execute the idb resolver on an opt-in basis.
export function enabledForIdbNonQueuedMutation(
    scope: string, // parent of the resolver: 'Mutation', 'Persona', 'Conversation', etc
    resolver: string
) {
    const allowedResolvers = [
        'bulkDeleteContacts',
        'createContact',
        'updateContact',
        'deleteContact',
        'addTagForPerson',
        'removeTagFromPerson',

        // Person source Mutations
        'deletePersonSources',
        'unlinkPersonSources',
        'updatePersonSource',
    ];
    if (scope === 'Mutation' && allowedResolvers.includes(resolver)) {
        return true;
    }

    return false;
}

export function enabledForIdb(
    op: 'Query' | 'Mutation' | 'Subscription',
    resolver: string,
    skipRemoteCallForcefully: boolean,
    resolverPolicy?: ResolverPolicy
) {
    if (resolverPolicy === 'serverOnly') {
        return false;
    }
    if (CalendarQueryResolvers.has(resolver) && !isFeatureEnabled('fwk-offline-calendar')) {
        return false;
    }
    if (MailQueryResolvers.has(resolver) && !isFeatureEnabled('fwk-offline-mail')) {
        return false;
    }
    if (
        MailMutateResolvers.has(resolver) &&
        (!isFeatureEnabled('fwk-offline-mail') || !isFeatureEnabled('cmp-offline-mail-newdraft'))
    ) {
        return false;
    }

    if (FolderQueryResolvers.has(resolver)) {
        const flightName = FolderQueryResolvers.get(resolver);
        if (!isFeatureEnabled('fwk-offline-mail') || !flightName || !isFeatureEnabled(flightName)) {
            return false;
        }
    }

    if (FolderMutateResolvers.has(resolver)) {
        const flightName = FolderMutateResolvers.get(resolver);
        if (!isFeatureEnabled('fwk-offline-mail') || !flightName || !isFeatureEnabled(flightName)) {
            return false;
        }
    }

    if (FavoriteMutateResolvers.has(resolver)) {
        const flightName = FavoriteMutateResolvers.get(resolver);
        if (!isFeatureEnabled('fwk-offline-mail') || !flightName || !isFeatureEnabled(flightName)) {
            return false;
        }
    }

    if (MailTriageIdbMutateResolvers.has(resolver)) {
        const flightName = MailTriageIdbMutateResolvers.get(resolver);
        if (!flightName || !isFeatureEnabled(flightName)) {
            return false;
        }
    }

    const enabled = getEnabled(op, resolver, skipRemoteCallForcefully);
    return (enabled & ResolverEnabledFor.Idb) == ResolverEnabledFor.Idb;
}

export function enabledForRemote(
    op: 'Query' | 'Mutation' | 'Subscription',
    resolver: string,
    skipRemoteCallForcefully: boolean,
    resolverPolicy?: ResolverPolicy
) {
    if (resolverPolicy === 'localOnly') {
        return false;
    }
    const enabled = getEnabled(op, resolver, skipRemoteCallForcefully);
    return (enabled & ResolverEnabledFor.Remote) == ResolverEnabledFor.Remote;
}

// The following function determines whether or not the resolver will go through the Outlook Gateway or not
// If you want the resolver to have a scorecard during the roll-out, please create a client-side flight, add the mapping to the IndividualResolverFeatureMappings list
// If you do this, it will ignore any setting related to this resolver name in the ECS setting.
// After the resolver API is graduated, you can move it to ECS to act a killswitch.
// If you do not want the resolver to have a scorecard during the rollout, you can add the configuration of the resolver to ECS directly.
function getEnabled(
    op: 'Query' | 'Mutation' | 'Subscription',
    resolver: string,
    skipRemoteCallForcefully: boolean
) {
    const defaultEnabled = LocalOnlyResolvers.has(resolver)
        ? ResolverEnabledFor.Idb
        : ResolverEnabledFor.All;

    if (IndividualResolverFeatureMappings.has(resolver)) {
        const featureName = IndividualResolverFeatureMappings.get(resolver);
        if (featureName) {
            return isFeatureEnabled(featureName as GlobalFeatureName) && !skipRemoteCallForcefully
                ? ResolverEnabledFor.Remote
                : defaultEnabled;
        }
    }

    const resolverLocations = getApplicationSettings('EnabledResolvers');
    const configuredEnabled = resolverLocations?.[op]?.[resolver];
    return configuredEnabled == undefined ? defaultEnabled : configuredEnabled;
}

/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * With support for additional (shared/delegate) mailboxes as accounts userIdentity can no longer be counted on to uniquely identify an account. Replace userIdentity usage with AccountKey usage.
 *	> 'owa-account-source-list/lib/utils/getSourceIdForUserIdentity' import is restricted from being used. UserIdentity does not uniquely identify a mailbox. Use getCoprincipalAccountForAccountKey instead. */
import getSourceIdForUserIdentity from 'owa-account-source-list/lib/utils/getSourceIdForUserIdentity';
import { updateConnectedAccountState as updateConnectedAccountStateInStore } from 'owa-account-source-list-store';
import { logStartUsage } from 'owa-analytics-start';

// Updates the account associated with the specified user identity
export function updateConnectedAccountState(userIdentity: string, accountState: number) {
    const sourceId = getSourceIdForUserIdentity(userIdentity);
    const sourceIdFound = !!sourceId;
    const accountFound = false;
    if (sourceId) {
        updateConnectedAccountStateInStore(sourceId, accountState);
    }

    logStartUsage('updateConnectedAccountStateForAccountSourceList', {
        sourceIdFound,
        accountFound,
    });
}

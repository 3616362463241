import { trace } from 'owa-trace';
import type { MailboxInfo } from 'owa-client-types';
import getThirdPartyImProvider from '../selector/selectors';

interface ThirdPartyOnlineMeetingProviderDetails {
    Name: string;
    DisplayName: string;
    Url: string;
}

// TODO: Add more providers as needed and get this list from manifest or some other source
const thirdPartyOnlineMeetingProviderDetails: ThirdPartyOnlineMeetingProviderDetails[] = [
    {
        Name: 'BlueJeans',
        DisplayName: 'BlueJeans Meeting',
        Url: 'https://bluejeans.com/',
    },
    {
        Name: 'Webex',
        DisplayName: 'Cisco Webex Scheduler',
        Url: 'webexteams://',
    },
    {
        Name: 'GoogleMeet',
        DisplayName: 'Google Meet',
        Url: 'https://meet.google.com/',
    },
    {
        Name: 'GoToMeeting',
        DisplayName: 'GoToMeeting',
        Url: 'https://www.goto.com/meeting',
    },
    {
        Name: 'JioMeet',
        DisplayName: 'JioMeet',
        Url: 'https://jiomeetpro.jio.com/',
    },
    {
        Name: 'Zoom',
        DisplayName: 'Zoom',
        Url: 'zoomus://',
    },
];

function processProtocolHandler(sipUrl: string, providerName: string) {
    window.open(sipUrl, '_blank');
    trace.info('ThirdParty Opening: ' + providerName + ' with sipUrl :' + sipUrl);
}

export async function launchThirdPartyIMChat(mailboxInfo: MailboxInfo): Promise<void> {
    const selectedProviderName = getThirdPartyImProvider(mailboxInfo)?.name;

    if (!selectedProviderName) {
        return;
    }

    const providerName = thirdPartyOnlineMeetingProviderDetails.find(
        provider => provider.DisplayName === selectedProviderName
    );

    if (providerName === undefined) {
        trace.info('ThirdParty provider details not found for ' + selectedProviderName);
        processProtocolHandler('sip:', selectedProviderName);
    } else {
        processProtocolHandler(providerName.Url, providerName.DisplayName);
    }
}

import type { AnalyticsCachedSettings } from 'owa-analytics-types';

let analyticsCachedSettings: AnalyticsCachedSettings | null = null;

export function getCachedSettings() {
    return analyticsCachedSettings;
}

export function setCachedSettings(settings: AnalyticsCachedSettings) {
    analyticsCachedSettings = settings;
}

export function updatePartialCachedSettings(partialSettings: Partial<AnalyticsCachedSettings>) {
    analyticsCachedSettings = {
        ...analyticsCachedSettings,
        ...partialSettings,
    } as AnalyticsCachedSettings;
}

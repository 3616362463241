import React from 'react';
import { FluentProvider, makeStyles, mergeClasses } from '@fluentui/react-components';
import { observer } from 'owa-mobx-react';
import { useV9BrandTheme } from './FluentContext';
import { getIsDarkTheme } from 'owa-fabric-theme';

interface OwaFluentProviderProps {
    children: React.ReactNode;
    isDarkModeEnabled: boolean;
    unsetTextAlign?: boolean;
    fullSize?: boolean;
}

const useFluentProviderStyles = makeStyles({
    root: {
        backgroundColor: 'transparent',
        lineHeight: 'normal',
    },
    unsetTextAlign: {
        textAlign: 'unset',
    },
    fullSize: {
        height: 'auto',
        width: '100%',
        display: 'flex',
    },
});

export const OwaFluentProvider = observer(function OwaFluentProvider(
    props: OwaFluentProviderProps
) {
    const { children, isDarkModeEnabled, unsetTextAlign = true, fullSize } = props;
    const theme = useV9BrandTheme(isDarkModeEnabled);
    const styles = useFluentProviderStyles();

    // Only render the theme when the user is in dark mode and the force in light mode setting overrides it
    const shouldRenderTheme = getIsDarkTheme() && !isDarkModeEnabled;

    return (
        <FluentProvider
            className={mergeClasses(
                styles.root,
                unsetTextAlign && styles.unsetTextAlign,
                fullSize && styles.fullSize
            )}
            theme={shouldRenderTheme ? theme : undefined}
        >
            {children}
        </FluentProvider>
    );
},
'OwaFluentProvider');

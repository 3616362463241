import { getItem } from 'owa-local-storage';
import { getDefaultImportSources } from './getDefaultImportSources';
import { tryParseImportSources } from './tryParseImportSources';
import { ImportSourcesKey } from './ImportSourcesKey';
import type { ImportSources } from './ImportSources';

/**
 * Gets the default value for the ImportSource structure
 * @returns ImportSources with the default value
 */
export function getImportHistory(): ImportSources {
    const json = getItem(self, ImportSourcesKey);
    const value = tryParseImportSources(json);
    return value ?? getDefaultImportSources();
}

import getLocalizedStrings from './getLocalizedStrings';
import { format } from './utilities/format';
import { orchestrator } from 'satcheljs';
import { addLocstringsToStore } from './actions/addLocstringsToStore';
import type { ResourceId } from './ResourceId';
import type { LocalizedString } from './LocalizedString';
import type { FormatArg } from './utilities/format';

/**
 * Produces a localized string, given its resourceId and optional format arguments.
 *
 * @param resourceId The ID of the localized string, read from a *.locstrings.json file.
 * @param args An array of format arguments to be substituted into the localized string.
 * @returns The localized string, with the format arguments substituted in.
 *
 * This function is intended to be used in observer components, where the component
 * can re-render when the localized string is loaded.
 *
 * It is a bug to call `loc()` at the module level, in static initializers, or inside
 * `useMemo()`, because the string produced by `loc()` will not update when the user
 * changes their language preference.
 *
 * In very, very rare cases, you may need to use `getPromiseForLocalizedString()` instead,
 * if you expect your code to be called before the localized string is loaded at all.
 *
 * There are three different cases for `loc()`:
 * - In development mode, *.locstring.json files are processed as normal json files, so the resourceId
 *   we get from an `import { id } from './string.locstring.json'` statement is the localized string itself
 *   and therefore there is no need to lookup the localized string in the store.
 *   Because there is no lookup, if you happen to pass an invalid resourceId you simply get back the same string.
 *
 * - In production mode, *.locstring.json files are preprocessed by the build system, and the resourceId
 *   we get from an `import { id } from './string.locstring.json'` statement is a minimized/opaque string
 *   and therefore there we need to lookup the localized string in the store. owa-build produces runtime
 *   code that will populate the store with the localized strings before the bundle that needs it is loaded,
 *   except in rare cases of boot bundles (where loc strings are rarely necessary).
 *   Because there is a lookup, if you happen to pass an invalid resourceId you get blank an empty string.
 *   We use the ResourceId to reduce the chances of passing an invalid resourceId, but it is still possible with bad casts.
 *
 * - In test mode, owa-build's locstring-preprocessor.ts will preprocess the *.locstring.json files and the resourceId
 *   we get from an `import { id } from './string.locstring.json'` statement is the id itself, allowing one to easily
 *   mock the call to `loc()` and return a string of their choosing without relying on the English strings from the files.
 *   To eliminate the need for mocking, `loc()` is now returning, by default, a string that mimics the call.
 *   If one inspects the output during a test run, the string will look like `loc(resourceId, arg1, arg2, ...)`.
 *   With this in place, one can simply check if the string is what they expect by calling loc() with the same arguments,
 *   and this work for unit tests and Storybook `play()` functions.
 */
export function loc(
    resourceId: ResourceId | null | undefined,
    ...args: FormatArg[]
): LocalizedString {
    if (!process.env.JEST_WORKER_ID) {
        const locString =
            (process.env.NODE_ENV === 'dev'
                ? (resourceId as LocalizedString)
                : getLocalizedStrings().get(resourceId || '')) ?? '';
        return args.length ? format(locString, ...args) : locString;
    } else {
        // In test more, return a string that mimics the caller
        return ('loc(' +
            [String(resourceId) || '""', ...args.map(a => String(JSON.stringify(a)))].join(',') +
            ')') as LocalizedString;
    }
}

const unresolvedStringRequests = {} as Record<
    ResourceId,
    ((localizedString: LocalizedString) => void)[]
>;

/**
 * This function returns a Promise that is guaranteed to resolve with the actual localized string,
 * rather than returning an empty string if the string is not yet loaded. This is primarily intended
 * for GraphQL resolvers, not for observable components that can re-render when a string is loaded.
 */
export function getPromiseForLocalizedString(resourceId: ResourceId): Promise<LocalizedString> {
    if (process.env.NODE_ENV === 'dev') {
        return Promise.resolve(resourceId as LocalizedString);
    }

    if (getLocalizedStrings().has(resourceId)) {
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        return Promise.resolve(getLocalizedStrings().get(resourceId)!);
    } else {
        return new Promise<LocalizedString>(resolve => {
            unresolvedStringRequests[resourceId] = unresolvedStringRequests[resourceId] ?? [];
            unresolvedStringRequests[resourceId].push(resolve);
        });
    }
}

/* eslint-disable-next-line owa-custom-rules/forbid-orchestrator-in-same-package -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Do not register an orchestrator with an action that is defined in the same package */
orchestrator(addLocstringsToStore, actionMessage => {
    for (const resourceId of Object.keys(actionMessage.str) as ResourceId[]) {
        if (unresolvedStringRequests[resourceId]) {
            for (const callback of unresolvedStringRequests[resourceId]) {
                callback(actionMessage.str[resourceId] as LocalizedString);
            }
            delete unresolvedStringRequests[resourceId];
        }
    }
});

import { LazyAction, LazyModule } from 'owa-bundling-light';

const MAX_FAILED_RETRIES = 100;

const lazyAnalytics = new LazyModule(
    () => import(/* webpackChunkName: "Analytics" */ './lazyIndex'),
    { maxFailedRetries: MAX_FAILED_RETRIES, name: 'Analytics' }
);

export const lazyOneDSFlush = new LazyAction(lazyAnalytics, m => m.oneDSFlush);
export const lazyAddMsaAuthTicket = new LazyAction(lazyAnalytics, m => m.addMsaAuthTicket);
export const lazyLogDatapoint = new LazyAction(lazyAnalytics, m => m.logDatapoint);
export const lazyLogPerformanceDatapoint = new LazyAction(
    lazyAnalytics,
    m => m.logPerformanceDatapoint
);
export const lazyGetResourceTimingForUrl = new LazyAction(
    lazyAnalytics,
    m => m.getResourceTimingForUrl
);

export const lazyLogAddinsCustomerContent = new LazyAction(
    lazyAnalytics,
    m => m.logAddinsCustomerContent
);

export const lazyLogAddinsTelemetryEvent = new LazyAction(
    lazyAnalytics,
    m => m.logAddinsTelemetryEvent
);

export const lazyLogUsage = new LazyAction(lazyAnalytics, m => m.logUsage);
export const lazyTrackNetworkResponse = new LazyAction(lazyAnalytics, m => m.trackNetworkResponse);

export const lazyLogProfilerDatapoint = new LazyAction(lazyAnalytics, m => m.logProfilerDatapoint);

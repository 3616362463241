import strings from './getRollupText.locstring.json';
import type InboxViewType from 'owa-service/lib/contract/InboxViewType';
import { getStore } from '../store/store';
import loc, { format } from 'owa-localize';
import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';

/**
 * Get rollup text for current folder
 */
export default function getRollupText(viewType: InboxViewType, mailboxInfo: MailboxInfo): string {
    const unseenCount = getStore(mailboxInfo).unseenCountToDisplay;
    const shouldUseNewRollupText =
        isFeatureEnabled('tri-fo-style-updates') && isFeatureEnabled('tri-fo-rollup-text');

    switch (viewType) {
        case 1:
            return shouldUseNewRollupText
                ? format(loc(strings.focusedRollupText), unseenCount)
                : format(loc(strings.focusedNewMessagesRollupText), unseenCount);
        case 2:
            return shouldUseNewRollupText
                ? format(loc(strings.otherRollupText), unseenCount)
                : format(loc(strings.otherNewMessagesRollupText), unseenCount);
    }

    return '';
}

let recoveryMode: boolean = false;

// Set the Recovery Mode session for Monarch. This will force an online boot that will fetch the latest version of the app and flights and avoid local storage.
export function setRecoveryModeSession(): void {
    recoveryMode = true;
}

// Check if this is a Recovery Mode session for Monarch. This will force an online boot that will fetch the latest version of the app and flights and avoid local storage.
// This is an automated recovery triggered by the Native Host
export function isRecoveryModeSession(): boolean {
    return recoveryMode;
}

import isMultiAccountsCombinedFavoritesEnabled from './isMultiAccountsCombinedFavoritesEnabled';
import { isFeatureEnabled } from 'owa-feature-flags';
import type { MailFolder } from 'owa-graph-schema';

export function isLocalFolderFavoriteSupported(): boolean {
    return (
        isMultiAccountsCombinedFavoritesEnabled() && isFeatureEnabled('fp-local-folder-favorites')
    );
}

export function isLocalFolderFavoriteSupportedForFolder(folder: MailFolder | undefined): boolean {
    if (!folder) {
        return false;
    } else {
        // Local folder favorites are only supported for shared mailboxes for now, add more types here
        return isLocalFolderFavoriteSupported() && folder.mailboxInfo.type === 'SharedMailbox';
    }
}

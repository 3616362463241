import type { MailboxInfo } from 'owa-client-types';
import type WebSessionType from 'owa-service/lib/contract/WebSessionType';
import getUserConfiguration from '../actions/getUserConfiguration';
import { getAccountScopeUserSettings } from '../selectors/getAccountScopeUserSettings';
import getConnectedAccountUserConfiguration from '../selectors/getConnectedAccountUserConfiguration';
import { checkGlobalSettingsReady } from './checkGlobalSettingsReady';
import { mailboxInfoOrTemporaryGlobal } from './mailboxInfoOrTemporaryGlobal';

/***
 * Function that checks whether the primary account of logged in user represents a consumer account
 * @param smtpAddress - THIS PARAM IS TO BE UNDEFINED as using emailAddress to identify an account is not supported under in Multi Account Mode.
 * @param mailboxInfo - The MailboxInfo of the account you want to check if it is a consumer account. Pass in undefined to check the primary account.
 *
 * @returns boolean - True if the account is a consumer account, false otherwise
 */
export default function isConsumer(smtpAddress?: string, mailboxInfo?: MailboxInfo): boolean {
    if (!smtpAddress && mailboxInfo) {
        return getAccountScopeUserSettings(mailboxInfo).SessionSettings?.WebSessionType !== 0;
    }

    checkGlobalSettingsReady();
    // If we are running on worker thread, there is no connected account user configuration to
    // evaluate as it is only setup in main thread. Hence we should pass through
    if (smtpAddress && getUserConfiguration().SessionSettings?.LogonEmailAddress !== smtpAddress) {
        const userConfiguration = getConnectedAccountUserConfiguration(smtpAddress);
        return (
            !!userConfiguration?.SessionSettings &&
            userConfiguration.SessionSettings.WebSessionType !== 0
        );
    }

    return (
        getAccountScopeUserSettings(mailboxInfoOrTemporaryGlobal(mailboxInfo)).SessionSettings
            ?.WebSessionType !== 0
    );
}

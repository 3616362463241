import type VirtualizedGroupHeader from '../type/VirtualizedGroupHeader';
import { mutatorAction } from 'satcheljs';
import getMailListGroupHeader from '../helpers/getMailListGroupHeader';
import getMailListGroupHeaderGenerator from '../helpers/getMailListGroupHeaderGenerator';
import virtualizedMailGroupHeadersStore from '../store/store';
import { getCanTableLoadMore } from 'owa-mail-triage-table-load-extra';
import type { TableView } from 'owa-mail-list-store';

// @param shouldReset - If true, the store is reset before adding the group headers
export default mutatorAction(
    'addVirtualizedGroupHeadersAction',
    function addVirtualizedGroupHeadersAction(tableView: TableView, shouldReset: boolean) {
        const tableRowKeys = [...tableView.rowKeys];
        let previousRowKey: string = '';
        let currentRowKey: string = '';
        let newGroupHeader: VirtualizedGroupHeader;
        let currentGroupHeaderText: string = '';

        // If the store should be reset, all the headers are removed from the store
        if (shouldReset) {
            virtualizedMailGroupHeadersStore().virtualizedGroupHeaders.clear();
        }

        const groupHeadersFromStore = virtualizedMailGroupHeadersStore().virtualizedGroupHeaders;

        for (const rowKey of tableRowKeys) {
            currentRowKey = rowKey;
            const headerText = getMailListGroupHeader(
                previousRowKey,
                currentRowKey,
                tableView,
                getMailListGroupHeaderGenerator(tableView)
            );
            // If a header text is generated a check is run to see if it already exists in the store. If not, it is added to the store.
            if (headerText) {
                if (currentGroupHeaderText) {
                    const previousGroupHeader = groupHeadersFromStore.get(currentGroupHeaderText);
                    if (previousGroupHeader) {
                        previousGroupHeader.isLoadingOfRowKeysComplete = true;
                    }
                }

                // Header text is generated for the row key(and other row keys after it) & the store has it already - eg. new mail list item arrives in mail list
                const preExistingGroupHeaderFromStore = groupHeadersFromStore.get(headerText);
                if (preExistingGroupHeaderFromStore) {
                    // If the row key is not already in the list of row keys attached to the header text, we add it
                    if (!preExistingGroupHeaderFromStore.rowKeys.includes(currentRowKey)) {
                        preExistingGroupHeaderFromStore.rowKeys.push(currentRowKey);
                        removeRowKeyFromOtherGroupHeaders(currentRowKey, headerText);
                    }
                } else {
                    // New header text that does not exist in store
                    newGroupHeader = {
                        isCollapsed: false,
                        isLoadingOfRowKeysComplete: false,
                        rowKeys: [currentRowKey],
                    };
                    groupHeadersFromStore.set(headerText, newGroupHeader);
                    removeRowKeyFromOtherGroupHeaders(currentRowKey, headerText);
                }

                // Update the currentGroupHeaderText to the header text generated
                // for the current row key because that's the new current group
                // we're processing.
                currentGroupHeaderText = headerText;

                // If no headerText is generated, we add it to the itemIds attached to the last header text
            } else {
                const existingGroupHeaderValue = groupHeadersFromStore.get(currentGroupHeaderText);
                if (
                    existingGroupHeaderValue &&
                    !existingGroupHeaderValue.rowKeys.includes(currentRowKey)
                ) {
                    existingGroupHeaderValue.rowKeys.push(currentRowKey);
                    removeRowKeyFromOtherGroupHeaders(currentRowKey, currentGroupHeaderText);
                }
            }
            previousRowKey = currentRowKey;
        }

        // For the last header - if all rows in the table are loaded and more cannot be loaded, the loading is considered complete.
        const lastGroupHeaderLoaded = groupHeadersFromStore.get(currentGroupHeaderText);
        if (lastGroupHeaderLoaded && !getCanTableLoadMore(tableView)) {
            lastGroupHeaderLoaded.isLoadingOfRowKeysComplete = true;
        }
    }
);

/**
 * Removes the row key from other group headers
 * @param targetRowKey The row key to remove from other group headers
 * @param targetHeaderText The header text of the group header that the row key now belongs to
 */
function removeRowKeyFromOtherGroupHeaders(targetRowKey: string, targetHeaderText: string) {
    const groupHeadersFromStore = virtualizedMailGroupHeadersStore().virtualizedGroupHeaders;
    for (const groupHeaderEntry of groupHeadersFromStore) {
        const groupHeaderText = groupHeaderEntry[0];
        const groupHeader = groupHeaderEntry[1];

        if (groupHeaderText !== targetHeaderText && groupHeader.rowKeys.includes(targetRowKey)) {
            groupHeader.rowKeys = groupHeader.rowKeys.filter(rowKey => rowKey !== targetRowKey);
        }
    }
}

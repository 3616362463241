import { isFeatureEnabled } from 'owa-feature-flags';
import { SubstrateSearchScenario } from '../data/schema/SubstrateSearchScenario';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { getHasArchive } from 'owa-session-store';

/**
 * Helper function to return "X-Client-Flights" header value to compose with
 * server flights
 */
export default function getXClientFlightsHeaderValue(
    scenarioId: SubstrateSearchScenario
): string | undefined {
    if (scenarioId !== SubstrateSearchScenario.Mail) {
        return undefined;
    }

    const flightNames: string[] = ['OWA_BestMatch_V15'];
    flightNames.push('CalendarInsightsFlight');

    if (getHasArchive(getGlobalSettingsAccountMailboxInfo())) {
        flightNames.push('OWA_HasArchiveMailbox');
    }

    const tidbitsSearchFlights = [
        'EESForEmailConv',
        'EESForEmailConvSsa',
        'EnableTidBits',
        'EnableTidBits2',
        'PopulateTidBits',
        'PopulateTidBits2',
    ];

    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    tidbitsSearchFlights.forEach(tidbitFlight => flightNames.push(tidbitFlight));

    if (isFeatureEnabled('sea-folderRecursive')) {
        flightNames.push('DisableAncestorContainerIDOnlyValidation');
    }

    if (isFeatureEnabled('sea-3sB2Url')) {
        flightNames.push('querymicroservice');
    }

    return flightNames.length > 0 ? flightNames.join(',') : undefined;
}

import getStore from '../store/store';
import { raiseAlertForDefaultSettings } from '../utils/allowDefaultApplicationSettings';

import type { ApplicationSettings } from '../store/schema/ApplicationSettings';
import type { MailboxInfo } from 'owa-client-types';

export default function getAllApplicationSettings(mailboxInfo: MailboxInfo): ApplicationSettings {
    const store = getStore(mailboxInfo);

    if (!store.initialized && !process.env.JEST_WORKER_ID) {
        raiseAlertForDefaultSettings(
            mailboxInfo,
            'AllSettings',
            false /* dontErrorIfNotInitialized */
        );
    }

    return store.settings;
}

import { getQueryStringParameters, stringify } from 'owa-querystring';
import type { AccountOriginContext, AccountOriginContextType } from 'owa-account-storage-types';
import { type PrioritizedAccountDataWithError } from 'owa-account-config-types';
import type { AccountDialogActionOrigin } from 'owa-account-source-list-types';
import { logUsageFromAccounts } from 'owa-account-analytics';
import { getAccountOriginDiagnostics } from 'owa-account-storage-types/lib/getAccountOriginDiagnostics';

enum CONTEXT_DATA_QUERY_PARAM {
    smtpAddress = 'r_email',
    context = 'r_src',
    subContext = 'r_cmp',
}

export const getAccountConfigDialogContextData = (
    locationObj?: Pick<Location, 'search'>
): AccountConfigDialogContextData | null => {
    const queryParams = getProcessedQueryStringParameters(locationObj);

    const context = queryParams[CONTEXT_DATA_QUERY_PARAM.context] as AccountOriginContextType;

    if (!context) {
        return null;
    }

    const contextData: AccountConfigDialogContextData = {
        dialogTrigger: 'Referral',
        fallbackData: queryParams[CONTEXT_DATA_QUERY_PARAM.smtpAddress]
            ? { smtpAddress: queryParams[CONTEXT_DATA_QUERY_PARAM.smtpAddress] }
            : undefined,
        originContext: {
            context,
            subContext: queryParams[CONTEXT_DATA_QUERY_PARAM.subContext],
        },
    };

    const originDiagnostics = getAccountOriginDiagnostics(
        contextData.dialogTrigger,
        contextData.originContext
    );
    logUsageFromAccounts('AddAccountReferral', {
        ...originDiagnostics,
        acctHint: !!contextData?.fallbackData?.smtpAddress,
    });

    return contextData;
};

export const stripContextDataFromQueryParam = () => {
    const queryParams = getQueryStringParameters();

    for (const key of Object.keys(CONTEXT_DATA_QUERY_PARAM)) {
        delete queryParams[CONTEXT_DATA_QUERY_PARAM[key as keyof typeof CONTEXT_DATA_QUERY_PARAM]];
    }

    const newSearch = stringify(queryParams);
    history.replaceState(history.state, '/', location.pathname + '?' + newSearch);
};

/**
 * Extracts the context data query parameters from the location object and processes them.
 *
 * @param locationObj - location object from which to extract query parameters
 * @returns - object containing the query parameters after processing. Eg. { r_email: 'xyz@gmail.com', r_src: 'InboxCampaign', r_cmp: '120' }
 */
export const getProcessedQueryStringParameters = (
    locationObj: Pick<Location, 'search'> | undefined
): Record<string, string> => {
    const queryParams = getQueryStringParameters(locationObj);

    const processedParams: Record<string, string> = {};
    for (const value of Object.values(CONTEXT_DATA_QUERY_PARAM)) {
        processedParams[value] = queryParams[value]?.split('/')?.[0];
    }

    return processedParams;
};

export interface AccountConfigDialogContextData {
    dialogTrigger: AccountDialogActionOrigin;
    fallbackData?: PrioritizedAccountDataWithError;
    originContext?: AccountOriginContext;
}

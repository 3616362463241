import { mutatorAction } from 'satcheljs';
import { getStore } from '../store/Store';

export const updateClipboardReadable = mutatorAction(
    'updateClipboardReadable',
    (readable: boolean) => {
        const store = getStore();
        store.clipboardReadable = readable;
    }
);

import { composeStore } from 'owa-mail-compose-store';
import { lazyDiscardCompose, lazyLoadDraftToCompose } from 'owa-mail-compose-actions';
import { lazyNewMessage } from 'owa-mail-message-actions';
import selectFolderWithFallback from './selectFolderWithFallback';
import getSelectedFolderMailboxInfoOrDefault from 'owa-mail-store/lib/utils/getSelectedFolderMailboxInfoOrDefault';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getQueryStringParametersFromURL } from 'owa-content-handler/lib/utils/queryStringHelper';
import {
    lazyCallCopilotBizChatHandoffService,
    type BizChatHandoffResponse,
} from 'owa-copilot-service/lib/lazyFunctions';
import { type CopilotResponse } from 'owa-copilot-service';

export interface MailFolderRouteParameters {
    folderId?: string;
    draftId?: string;
}

export async function mailComposeNavigationRouteHandler(parameters: MailFolderRouteParameters) {
    selectFolderWithFallback(parameters.folderId);

    if (parameters.draftId) {
        await lazyLoadDraftToCompose.importAndExecute(
            { Id: parameters.draftId, mailboxInfo: getSelectedFolderMailboxInfoOrDefault() },
            null /* sxsId */,
            'Route' /* actionSource */
        );
    } else {
        await lazyNewMessage.importAndExecute('Route');
    }
}

export function mailComposeCleanupRouteHandler(): Promise<boolean> | boolean {
    if (composeStore.primaryComposeId) {
        const viewState = composeStore.viewStates.get(composeStore.primaryComposeId);
        if (viewState) {
            return lazyDiscardCompose.importAndExecute(viewState).then(
                () => false, // Confirm ('Discard draft') means we should not block navigation
                () => true
            ); // Cancel ('Dont' discard') means we should block navigation
        }
    }
    return false;
}

export async function mailComposeBizChatRouteHandler() {
    const mailboxInfo = getSelectedFolderMailboxInfoOrDefault();
    if (isFeatureEnabled('cmp-copilot-bizchat', mailboxInfo)) {
        const handoffId = getQueryStringParametersFromURL().h;

        /* Call API with handoffId to retrieve the handoff data */
        const response = await lazyCallCopilotBizChatHandoffService.importAndExecute(
            handoffId,
            mailboxInfo
        );
        if (isBizChatResponse(response)) {
            // Apply to mail compose init props
            const mailComposeInitProps = {
                subject: response.subject,
                toEmailAddressWrappers: response.toRecipients.map(recipient => ({
                    EmailAddress: recipient.emailAddress,
                    //not yet using recipient.name here because wrong name can be given
                })),
                ccEmailAddressWrappers: response.ccRecipients.map(recipient => ({
                    EmailAddress: recipient.emailAddress,
                })),
                bccEmailAddressWrappers: response.bccRecipients.map(recipient => ({
                    EmailAddress: recipient.emailAddress,
                })),
            };

            // Open compose with handoff data
            await lazyNewMessage.importAndExecute('ApplinkRoute', mailComposeInitProps);
        }
    }
}

function isBizChatResponse(
    response: BizChatHandoffResponse | CopilotResponse
): response is BizChatHandoffResponse {
    return 'emailBody' in response;
}

import {
    getSelectMailItemDatapoint,
    setSelectMailItemDatapoint,
} from './selectMailItemDatapointGetterAndSetter';
import { addTimingsAndEndDatapointOnRender } from 'owa-analytics-shared';
import { DatapointStatus } from 'owa-analytics-types';

export default function endSelectMailItemDatapoint(
    contentType: string = '',
    shouldInvalidate?: boolean,
    overrideStatus?: DatapointStatus,
    error?: Error
) {
    const selectMailItemDp = getSelectMailItemDatapoint();
    if (selectMailItemDp && !selectMailItemDp.hasEnded) {
        // Empty content type means there is no active selection at the time of ending the datapoint.
        if (shouldInvalidate || contentType === 'Empty') {
            selectMailItemDp.invalidate();
        } else if (
            overrideStatus === DatapointStatus.ServerError ||
            overrideStatus === DatapointStatus.ClientError
        ) {
            selectMailItemDp.endWithError(overrideStatus, error);
        } else {
            addContentTypeToCustomDataOfSmi(contentType);
            if (window.document && window.document.visibilityState == 'visible') {
                // Passing logOnly as false ends the datapoint after logging render end time if it has not already ended.
                addTimingsAndEndDatapointOnRender(selectMailItemDp, false /* logOnly */);
            } else {
                selectMailItemDp.end(undefined /* duration */, DatapointStatus.BackgroundSuccess);
            }

            setSelectMailItemDatapoint(undefined);
        }
    }
}

function addContentTypeToCustomDataOfSmi(contentType: string) {
    const selectMailItemDp = getSelectMailItemDatapoint();
    selectMailItemDp?.addCustomData({ contentType });
}

import type { MailboxInfo } from 'owa-client-types';
import type { ImplementedCopilotFeatures, CopilotSettingsStore } from 'owa-copilot-settings-store';
import { getApplicationSettings } from 'owa-application-settings';
import checkLanguage from './checkLanguage';
import createStoreCheckForGenericEnablement from './createStoreCheckForGenericEnablement';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isServiceRequestSupportedForMailbox } from 'owa-service/lib/utils/isServiceRequestSupportedForMailbox';
import { isAnalyzeContentDisabledForPrivacy } from 'owa-privacy-utils/lib/selectors/isAnalyzeContentDisabledForPrivacy';
import {
    getCoprincipalMailboxInfo,
    getGlobalSettingsAccountMailboxInfo,
} from 'owa-account-source-list-store';

const isCopilotFeatureEnabled = createStoreCheckForGenericEnablement<
    ImplementedCopilotFeatures,
    boolean
>('CopilotEnabled', isCopilotFeatureEnabledInternal);

export default isCopilotFeatureEnabled;

function isCopilotFeatureEnabledInternal(
    store: CopilotSettingsStore,
    language: string,
    scenario: ImplementedCopilotFeatures,
    mailboxInfo: MailboxInfo,
    skipLanguageCheck?: boolean
) {
    // Respect the privay settings based on the global(primary) mailbox
    if (
        isFeatureEnabled('mon-copilot-respectG1', mailboxInfo) &&
        isAnalyzeContentDisabledForPrivacy()
    ) {
        return false;
    }

    if (store.isEnabled && isServiceRequestSupportedForMailbox(mailboxInfo)) {
        const featureEnabled = store.featureInfo.get(scenario);
        if (featureEnabled?.isEnabled) {
            const coprincipalMailboxInfo =
                mailboxInfo.type === 'GroupMailbox'
                    ? getCoprincipalMailboxInfo(mailboxInfo) ??
                      getGlobalSettingsAccountMailboxInfo()
                    : mailboxInfo;

            return (
                isEnabledInECS(scenario, coprincipalMailboxInfo) &&
                (skipLanguageCheck || checkLanguage(language, featureEnabled.langauges))
            );
        }
    }
    return false;
}

function isEnabledInECS(scenario: ImplementedCopilotFeatures, mailboxInfo: MailboxInfo): boolean {
    // retrieve application settings for the specific feature as a small performance optimization
    switch (scenario) {
        case 'AttachmentSummarization':
            return getApplicationSettings('Copilot', mailboxInfo).attachmentSummarizationEnabled;
        case 'AttendanceSuggestion':
            return getApplicationSettings('Copilot', mailboxInfo).attendanceSuggestionEnabled;
        case 'Elaborate':
            return getApplicationSettings('Copilot', mailboxInfo).elaborateEnabled;
        case 'Summarize':
            return getApplicationSettings('Copilot', mailboxInfo).summarizeEnabled;
        case 'Coach':
        case 'CoachApply':
            return getApplicationSettings('Copilot', mailboxInfo).coachEnabled;
        case 'SuggestedDrafts':
            return getApplicationSettings('Copilot', mailboxInfo).suggestedDraftsEnabled;
        case 'SuggestCategory':
            return isFeatureEnabled('time-copilot-categories-row-v2');
        case 'ExplainPlacesReport':
            return isFeatureEnabled('msplaces-explain-report'); // client side feature flag.
        case 'Rewrite':
            return isFeatureEnabled('mon-copilot-elaborate-rewrite');
        case 'MeetingPrepSummary':
            // Used by FetchCopilotSettings - Service: https://ecs.skype.com/OlkCopilot/OlkCopilot/configurations/1116121
            // getApplicationSettings('Copilot', mailboxInfo).meetingPrepSummaryEnabled - Client: https://ecs.skype.com/Substrate/OutlookWebClient/configurations/1092852
            return getApplicationSettings('Copilot', mailboxInfo).meetingPrepSummaryEnabled;
        case 'ReplyByMeeting':
            // Used by FetchCopilotSettings - Service: https://ecs.skype.com/OlkCopilot/OlkCopilot/configurations/1123944
            // getApplicationSettings('Copilot', mailboxInfo).meetingPrepSummaryEnabled - Client: https://ecs.skype.com/Substrate/OutlookWebClient/configurations/1092852
            return getApplicationSettings('Copilot', mailboxInfo).replyByMeetingEnabled;
        case 'Chat':
            // Used by FetchCopilotSettings - Service: https://ecs.skype.com/OlkCopilot/OlkCopilot/configurations/1098830
            return true; // Rely on license check for Chat.
        case 'Inbox':
            return (
                // https://ecs.skype.com/Substrate/OutlookWebClient/configurations/1092852
                getApplicationSettings('Copilot', mailboxInfo).prioritizeEnabled
            );
        case 'TimeCopilotChat':
            return getApplicationSettings('Copilot', mailboxInfo).timeCopilotChatEnabled;
        case 'VisualTheming':
            return isFeatureEnabled('fwk-copilot-themes');
        case 'MChat':
            return true; // Rely on license check for MChat.
        case 'AutoResourceBooking':
            // https://ecs.skype.com/Substrate/OutlookWebClient/configurations/1092852
            return getApplicationSettings('Copilot', mailboxInfo).autoResourceBookingEnabled;
        case 'BizChatHandoff':
            return isFeatureEnabled('cmp-copilot-bizchat', mailboxInfo);
    }
}

import { NovaLogger } from 'owa-analytics-nova-logger';
import { isFeatureEnabled } from 'owa-feature-flags';

export const RingAppName = 'PeopleHighlightsRing';

export default function initializeRingNovaLogger() {
    // Initialize nova logger for Reading Pane Persona Ring.
    NovaLogger.associateIngestionKeyWithAppName(getRingIngestionKey(), RingAppName);
}

function getRingIngestionKey() {
    if (isFeatureEnabled('ph-isPhAriaProjectEnabled')) {
        // People highlights aria tenant
        return '136931d8fe8a4f5ab06063c9a693c8e6-1bf92fb9-223c-4b4b-8807-4114496f98af-7433';
    }
    // LPC Aria tenant
    return '9156e11e549943e5970de3f1661df5b2-1c673737-125c-416c-9121-b8a0db2ec86a-6465';
}

import { createStore } from 'satcheljs';
import type { AddinCommandSurfaceItem } from 'owa-addins-types';
import type { MailboxInfo } from 'owa-client-types';
export interface ThirdPartyImProviderInfo {
    imProvider?: AddinCommandSurfaceItem;
    mailboxInfo?: MailboxInfo;
    isThirdPartyMeetingProvider: boolean;
    isInitialized: boolean;
}

const initialThirdPartyImProviderInfoArray: ThirdPartyImProviderInfo[] = [
    {
        imProvider: undefined,
        mailboxInfo: undefined,
        isThirdPartyMeetingProvider: false,
        isInitialized: false,
    },
];

export default createStore('owaThirdPartyImIntegrationStore', {
    thirdPartyImProviderInfoArray: initialThirdPartyImProviderInfoArray,
});

import { getScriptPath, getScriptBackupPath } from 'owa-config';
import sleep from 'owa-sleep';
import { errorThatWillCauseAlert, type TraceErrorObject } from 'owa-trace';
import { default as rawSchemaUri } from '../__generated__/schema.all.min.graphql';

// Capture this value at the time of import, so we can replace it as needed.
// If we just use `getScriptPath()`, we could be in a situation where the path
// is some other value (like the backup path)
const rawSchemaUriBasePath = process.env.IS_WEBPACK ? __webpack_public_path__ : undefined;

let types: Promise<string> | undefined;
const retryDelayMs = 250;

export async function loadTypes(): Promise<string> {
    if (!types) {
        types = new Promise<string>(async (resolve, reject) => {
            try {
                const schema = await loadSchema();
                resolve(schema.text());
            } catch (e) {
                // setting undefined will retry if loadtypes is called again
                types = undefined;
                reject(e);
            }
        });
    }

    return types;
}

async function loadSchema(): Promise<Response> {
    let rv: Response | null = null;

    const RETRIES = 3;

    // try a few times against the main CDN
    for (let i = 0; i < RETRIES && rv == null; ++i) {
        rv = await internalLoadSchema(getScriptPath());
    }

    // try a few times against the backup
    for (let i = 0; i < RETRIES && rv == null; ++i) {
        // throw on the final attempt
        rv = await internalLoadSchema(getScriptBackupPath());
    }

    // try once more and throw if we fail
    if (!rv) {
        rv = await internalLoadSchema(getScriptBackupPath(), true /* fatal */);
    }

    if (!rv) {
        // unreachable because we're passing fatal to the last call but ts doesn't know that.
        throw new Error('Failed to load schema');
    }

    return rv;
}

async function internalLoadSchema(
    scriptPath: string,
    fatal: boolean = false
): Promise<Response | null> {
    let fetchPromise = null;
    let uri = rawSchemaUri?.toString() || '';
    const owaPublicPath = /^OwaPublicPath/;
    if (uri.match(owaPublicPath)) {
        // this would mean something went wrong with the OwaPublicPathPlugin/workerTemplate handshake for setting
        // the path
        uri = uri.replace(owaPublicPath, scriptPath);
        const err: TraceErrorObject = new Error('OwaPublicPath was not set before loading types');
        errorThatWillCauseAlert(err);
    }

    try {
        // The raw schema url is imported as an absolute URL using the configured public path. We should replace that.
        if (rawSchemaUriBasePath) {
            uri = uri.replace(rawSchemaUriBasePath, scriptPath);
        }

        fetchPromise = fetch(uri);

        // await here to throw any exception, but after setting the public path back
        const response = await fetchPromise;
        if (!response.ok) {
            throw new Error('Failed to fetch schema');
        }

        return response;
    } catch (e) {
        if (fatal) {
            throw e;
        }

        await sleep(retryDelayMs);
        return null;
    }
}

export function testReset() {
    types = undefined;
}

import viewStateStore from '../store/store';
import { isAccountFeatureEnabled } from 'owa-feature-flags';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';

export function getShouldOverrideToSortBySizeForMailFolder(): boolean {
    return (
        !!viewStateStore?.shouldOverrideToSortBySizeBasedOnURL &&
        (isAccountFeatureEnabled('storage-handleIsConsumer', getModuleContextMailboxInfo()) ||
            isConsumer(undefined, getModuleContextMailboxInfo()))
    );
}

import { addM365ConnectedMailboxToStore } from 'owa-account-source-list/lib/utils/sourceList';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * With support for additional (shared/delegate) mailboxes as accounts userIdentity can no longer be counted on to uniquely identify an account. Replace userIdentity usage with AccountKey usage.
 *	> 'owa-account-source-list/lib/utils/getSourceIdForUserIdentity' import is restricted from being used. UserIdentity does not uniquely identify a mailbox. Use getCoprincipalAccountForAccountKey instead. */
import getSourceIdForUserIdentity from 'owa-account-source-list/lib/utils/getSourceIdForUserIdentity';
import {
    accountRankTypeChecker,
    accountSourceDataTypeChecker,
    getCoprincipalAccounts,
    getGlobalSettingsAccount,
    isAccountSourceListStoreInitialized,
    removeCoprincipalAccountById,
    StartupFilter,
    ContractsSupportedFilter,
} from 'owa-account-source-list-store';
import type { AccountSource } from 'owa-account-source-list-store';
import { AccountSourceType } from 'owa-account-source-list-types';
import type { CoprincipalAccount } from 'owa-account-storage-types';
import { CoprincipalAccount_Version1 } from 'owa-account-storage-types';
import { logStartUsage } from 'owa-analytics-start';
import { escapePersistenceId } from './escapePersistenceId';

/**
 * OWA only supports a single connected account, use a well know guid for the
 * persistence id of this account
 */
const connectedAccountPersistenceIdPrefix = 'C0C0C0C0-C0C0-C0C0-C0C0-C0C0C0C0C0C0';

/**
 * Helper function that will try and get the persistence id of the specified account
 * @param account Account to get the persistence id for
 */
function getCoprincipalAccountPersistenceId(account?: AccountSource): string | undefined {
    if (accountRankTypeChecker.isCoprincipal(account)) {
        return account.persistenceId;
    }

    return undefined;
}

/**
 * Gets the AccountSourceType for the specified account provider type, the account provider type
 * comes from the OWA calendar connected account data. The OWA calendar connected accounts only
 * support Outlook, Google, and ICloud, and Yahoo accounts (and ICloud/Yahoo are flighted) so these
 * are the only accounts for which conversions are provided.
 * @param accountProviderType Type of the OWA calendar connected account
 * @returns Corresponding SourceAuthType for the account provider type
 */
function getAccountSourceType(accountProviderType: string): AccountSourceType {
    switch (accountProviderType) {
        case 'Outlook':
            return AccountSourceType.OutlookDotCom;
        case 'Google':
            return AccountSourceType.Google;
        case 'ICloud':
            return AccountSourceType.ICloud;
        case 'Yahoo':
            return AccountSourceType.Yahoo;
        default:
            logStartUsage('AcctAdd-ConnectedAccount-UnknownAuthProviderType', {
                accountProviderType,
            });

            // Default to Outlook.com
            return AccountSourceType.OutlookDotCom;
    }
}

/**
 * This function is for use in OWA to get the sourceId that should be used for an
 * OWA calendar connected account
 * @param userIdentity User identity associated with the OWA connected account
 * @param accountProviderType Provider for the OWA connected account
 */
export function getConnectedAccountPersistenceId(
    userIdentity: string,
    accountProviderType: string
) {
    const original = `${connectedAccountPersistenceIdPrefix}-${accountProviderType}-${userIdentity}`;
    const escaped = escapePersistenceId(original);
    if (original != escaped) {
        logStartUsage('AcctAdd-ConnectedAccount-PersistenceId-Escaped');
    }

    return escaped;
}

// Adds or updates the OWAConnectedAccount in the source list store
export function addConnectedAccount(
    userIdentity: string,
    accountProviderType: string,
    anchorMailbox: string,
    accountState: number
) {
    // We need to build a persistence id that is unique for the connected account, to do this we use
    // the connected account prefix and combine it with the account provider type and the user identity.
    const persistenceId = getConnectedAccountPersistenceId(userIdentity, accountProviderType);

    const account: CoprincipalAccount = {
        uuid: persistenceId,
        version: CoprincipalAccount_Version1,
        accountType: getAccountSourceType(accountProviderType),
        emailAddress: userIdentity,
        userIdentity,
        aliases: [],
    };
    let noAccountForPersistenceId: boolean | undefined = undefined;
    const noAccountForUserIdentity = !getSourceIdForUserIdentity(userIdentity);

    // Look for another (non-global settings) account that matches the persistance
    // if for the coprincipal account.
    const matching = getCoprincipalAccounts(
        StartupFilter.StartingOrCompleteOrError,
        ContractsSupportedFilter.Any
    )
        .slice(1)
        .filter(item => item.persistenceId === persistenceId);
    noAccountForPersistenceId = matching.length === 0;

    // Remove any preexisting connected accounts
    matching.forEach(match => {
        removeCoprincipalAccountById(match.sourceId);
    });

    // Now add the connected account
    addM365ConnectedMailboxToStore(account, anchorMailbox, {
        connectedAccountState: accountState,
    });

    // the global settings account
    const isInitialzied = isAccountSourceListStoreInitialized();
    const defaultSettings = isInitialzied ? getGlobalSettingsAccount() : undefined;
    const isGSACoId = getCoprincipalAccountPersistenceId(defaultSettings) === persistenceId;

    logStartUsage('AcctAdd-ConnectedAccount', {
        noAccountForUserIdentity,
        noAccountForPersistenceId,
        isGSACoId,
        uit: typeof userIdentity,
        uil: userIdentity?.length,
        actt: typeof accountProviderType,
        actl: accountProviderType?.length,
        defuit: typeof defaultSettings?.mailboxInfo?.userIdentity,
        isdefcon: accountSourceDataTypeChecker.isM365ConnectedMailbox(defaultSettings),
        defuitl: defaultSettings?.mailboxInfo?.userIdentity?.length,
        equal: userIdentity === defaultSettings?.mailboxInfo?.userIdentity,
        isInit: isInitialzied,
    });
}

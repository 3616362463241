import type FolderId from 'owa-service/lib/contract/FolderId';
import type { CalendarEntry, LocalCalendarEntry, LinkedCalendarEntry } from 'owa-graph-schema';

export function getCalculatedFolderId(
    calendar: CalendarEntry | LocalCalendarEntry
): FolderId | null {
    // local calendar entry
    const localCalendarEntry: LocalCalendarEntry = calendar as LocalCalendarEntry;

    if (localCalendarEntry.FolderId) {
        return {
            Id: localCalendarEntry.FolderId.Id,
            ChangeKey:
                localCalendarEntry.FolderId.ChangeKey != null
                    ? localCalendarEntry.FolderId.ChangeKey
                    : '',
        };
    }

    const linkedCalendarEntry: LinkedCalendarEntry = calendar as LinkedCalendarEntry;

    if (linkedCalendarEntry.IsGeneralScheduleCalendar) {
        return linkedCalendarEntry.OwnerEmailAddress
            ? { Id: linkedCalendarEntry.OwnerEmailAddress, ChangeKey: '' }
            : null;
    }

    if (linkedCalendarEntry.SharedFolderId) {
        return linkedCalendarEntry.SharedFolderId.Id
            ? {
                  Id: linkedCalendarEntry.SharedFolderId.Id,
                  ChangeKey:
                      linkedCalendarEntry.SharedFolderId.ChangeKey != null
                          ? linkedCalendarEntry.SharedFolderId.ChangeKey
                          : '',
              }
            : null;
    }

    return null;
}

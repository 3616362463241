import { createLazyComponent, LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MeetingMessage"*/ './lazyIndex')
);

// Export types and schema
export type { MeetingRequestViewState } from './store/schema/MeetingRequestViewState';
export { default as deleteMeetingMessage } from './actions/deleteMeetingMessage';
export { onMeetingActionCompleted } from './actions/onMeetingActionCompleted';
export type { MeetingMessageActionType } from './utils/MeetingMessageActionType';
export type { MeetingRequestViewStateHost } from './store/schema/MeetingRequestViewState';

// Lazy-load actions
export const lazyAddItemToCalendar = new LazyAction(lazyModule, m => m.addItemToCalendar);

export const lazyInitializeMeetingRequestViewState = new LazyAction(
    lazyModule,
    m => m.initializeMeetingRequestViewStateAction
);

export const lazyRemoveEventOnCancellation = new LazyAction(
    lazyModule,
    m => m.removeEventOnCancellation
);

// Lazy-load components
export const MeetingMessageInfo = createLazyComponent(lazyModule, m => m.MeetingMessageInfo);
export const MeetingRequestInfo = createLazyComponent(lazyModule, m => m.MeetingRequestInfo);

export const MeetingRequestResponseEditor = createLazyComponent(
    lazyModule,
    m => m.MeetingRequestResponseEditor
);

export const MeetingDetails = createLazyComponent(lazyModule, m => m.MeetingDetails);
export const MeetingMessageSummary = createLazyComponent(lazyModule, m => m.MeetingMessageSummary);
export const PreviewSummary = createLazyComponent(lazyModule, m => m.PreviewSummary);
export const SenderSummary = createLazyComponent(lazyModule, m => m.SenderSummary);
export const CollapsedMeetingMessage = createLazyComponent(
    lazyModule,
    m => m.CollapsedMeetingMessage
);
export const MeetingIcon = createLazyComponent(lazyModule, m => m.MeetingIcon);
export const AttendeePanel = createLazyComponent(lazyModule, m => m.AttendeePanel);

// Lazy-load utilities
export const lazyLogMeetingMessageActionForInvite = new LazyAction(
    lazyModule,
    m => m.logMeetingMessageActionForInvite
);
export const lazyLogMeetingMessageActionForEvent = new LazyAction(
    lazyModule,
    m => m.logMeetingMessageActionForEvent
);

export const lazyShowNotificationWhenRespondToMeeting = new LazyAction(
    lazyModule,
    m => m.showNotificationWhenRespondToMeeting
);

import type { AttachmentDetails } from 'owa-addins-core';
import { AddinsSupportedAttachmentType } from 'owa-addins-core';
import type {
    AttachmentFileAttributes,
    MailItemFile,
    UriFile,
    Base64File,
} from 'owa-attachment-file-types';
import { AttachmentFileType } from 'owa-attachment-file-types';
import { getExtensionFromFileName } from 'owa-file';

export default function createAttachmentFiles(
    attachments: AttachmentDetails[] | undefined
): AttachmentFileAttributes[] {
    const attachmentFiles: AttachmentFileAttributes[] = [];
    if (!!attachments) {
        for (const attachment of attachments) {
            let file: MailItemFile | UriFile | Base64File | undefined = undefined;
            if (attachment.attachmentType == AddinsSupportedAttachmentType.Item) {
                file = {
                    fileType: AttachmentFileType.MailItem,
                    name: attachment.name,
                    size: attachment.size || 0,
                    itemId: attachment.id,
                };
            } else if (attachment.attachmentType == AddinsSupportedAttachmentType.File) {
                file = {
                    fileType: AttachmentFileType.Uri,
                    name: attachment.name,
                    size: attachment.size || 0,
                    uri: attachment.id,
                    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
                     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
                     *	> Forbidden non-null assertion. */
                    type: getExtensionFromFileName(attachment.id)!,
                };
            } else if (attachment.attachmentType == AddinsSupportedAttachmentType.Base64) {
                file = {
                    fileType: AttachmentFileType.Base64File,
                    name: attachment.name,
                    isInline: attachment.isInline,
                    size: attachment.size || 0,
                    base64file: attachment.id,
                    type: getExtensionFromFileName(attachment.id) || '',
                };
            }

            if (file) {
                attachmentFiles.push({ file, isInline: attachment.isInline });
            }
        }
    }
    return attachmentFiles;
}

import {
    LazyAction,
    LazyImport,
    LazyModule,
    registerLazyOrchestrator,
    createLazyComponent,
} from 'owa-bundling';
import { mountFullOptions } from 'owa-options-actions-shared/lib/actions/mountFullOptions';

const lazyModule = new LazyModule(() => import(/* webpackChunkName: "OptionsView"*/ './lazyIndex'));

export { OPTION_ROUTE_KEYWORD } from './optionRouteKeyword';

// Export delay loaded actions
export const lazyMountAndShowFullOptions = new LazyAction(
    lazyModule,
    m => m.mountAndShowFullOptions
);
export const lazyHandleFullOptionsDeepLink = new LazyAction(
    lazyModule,
    m => m.handleFullOptionsDeepLink
);
export const lazyHideFullOptions = new LazyImport(lazyModule, m => m.hideFullOptions);
export const lazyConfirmDirtyOptionsAndPerformAction = new LazyImport(
    lazyModule,
    m => m.confirmDirtyOptionsAndPerformAction
);

export const lazyExitOptionsFullPage = new LazyImport(lazyModule, m => m.exitOptionsFullPage);

// Export delay loaded components
export const ProjectionOptionsFull = createLazyComponent(lazyModule, m => m.ProjectionOptionsFull);
export const DeepLinkOptionsFull = createLazyComponent(lazyModule, m => m.DeepLinkOptionsFull);

// Register lazy orchestrator
registerLazyOrchestrator(mountFullOptions, lazyModule, m => m.mountFullOptionsOrchestrator);

import { LazyImport, LazyModule, LazyAction } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "AddinsCore" */ './lazyIndex'),
    { name: 'AddinsCore' }
);

// apis
export const lazyRemoveExtensibilityNotification = new LazyImport(
    lazyModule,
    m => m.removeExtensibilityNotification
);
export const lazyUpdatePersistentNotifications = new LazyImport(
    lazyModule,
    m => m.updatePersistentNotifications
);

// events
export const lazyTriggerAppointmentTimeChangedEvent = new LazyImport(
    lazyModule,
    m => m.triggerAppointmentTimeChangedEvent
);
export const lazyTriggerRecipientsChangedEvent = new LazyImport(
    lazyModule,
    m => m.triggerRecipientsChangedEvent
);
export const lazyTriggerRecurrenceChangedEvent = new LazyImport(
    lazyModule,
    m => m.triggerRecurrenceChangedEvent
);
export const lazyTriggerLocationsChangedEvent = new LazyImport(
    lazyModule,
    m => m.triggerLocationsChangedEvent
);
export const lazyTriggerAttachmentsChangedEvent = new LazyImport(
    lazyModule,
    m => m.triggerAttachmentsChangedEvent
);
export const lazyTriggerSensitivityLabelChangedEvent = new LazyImport(
    lazyModule,
    m => m.triggerSensitivityLabelChangedEvent
);
export const lazyTriggerSelectedItemsChangedEvent = new LazyImport(
    lazyModule,
    m => m.triggerSelectedItemsChangedEvent
);
export const lazyTriggerOfficeThemeChangedEvent = new LazyImport(
    lazyModule,
    m => m.triggerOfficeThemeChangedEvent
);

// boot
export const lazyWhenItemHasContextualAddinKeywords = new LazyImport(
    lazyModule,
    m => m.whenItemHasContextualAddinKeywords
);

// store
export const lazyShouldAddinsForceOnSendCompliantBehavior = new LazyImport(
    lazyModule,
    m => m.shouldForceOnSendCompliantBehavior
);

//Autorun actions
export const lazyLogAppTeachingUICalloutTelemetry = new LazyAction(
    lazyModule,
    m => m.logAppTeachingUICalloutTelemetry
);

export const lazyLogCustomizeActionButtonAction = new LazyAction(
    lazyModule,
    m => m.logCustomizeActionButtonAction
);

export const lazyConvertRecurrenceToAddinFormat = new LazyImport(
    lazyModule,
    m => m.convertRecurrenceToAddinFormat
);
export const lazySeriesTimeJsonConverter = new LazyImport(
    lazyModule,
    m => m.seriesTimeJsonConverter
);

// Owa experiment (AwarenessNudge) : Highlighting addins and buttons
export const lazyHighlightOverflowAddins = new LazyAction(
    lazyModule,
    m => m.highlightOverflowAddins
);

export const lazyHighlightCustomizeActionButton = new LazyAction(
    lazyModule,
    m => m.highlightCustomizeActionButton
);

export const lazyLogUpdatedAppPinningStatus = new LazyAction(
    lazyModule,
    m => m.logUpdatedAppPinningStatus
);
//EMO functions
export const lazyLaunchThirdPartyOnlineMeetingProviderAddin = new LazyAction(
    lazyModule,
    m => m.launchThirdPartyOnlineMeetingProviderAddin
);

import type {
    AnalyticsFlightsAndAppSettings,
    AnalyticsFlightsAndEcsSettingsAddon,
} from 'owa-analytics-types';
import { getAnalyticsAddon } from 'owa-analytics-addons';
import { trace } from 'owa-trace';
import { getCachedSettings } from './getCachedSettings';

let flightsAndApplicationSettingsCache: AnalyticsFlightsAndAppSettings | null = null;

export function getAnalyticsFlightsAndAppSettings(): AnalyticsFlightsAndAppSettings | null {
    /**
     * When running tests, we won't pull the actual flights so we need to pass some default ones instead.
     * This will be removed in prod builds.
     */
    if (process.env.JEST_WORKER_ID) {
        return TEST_ONLY_getDefaultSettings();
    }

    // On the main thread, use the local cache if available for speed
    if (flightsAndApplicationSettingsCache) {
        return flightsAndApplicationSettingsCache;
    }

    // On the workers, use the cached settings if available
    const cachedSettings = getCachedSettings();
    if (cachedSettings?.flightsAndEcsSettings) {
        return cachedSettings?.flightsAndEcsSettings;
    }

    // If the settings don't exist, fallback to trying to initialize them (only works on the main thread)
    if (getAnalyticsAddon('AnalyticsFlightsAndEcsSettings').isRegistered) {
        flightsAndApplicationSettingsCache = getAnalyticsAddon<AnalyticsFlightsAndEcsSettingsAddon>(
            'AnalyticsFlightsAndEcsSettings'
        )
            .executeNow()
            ?.initializeAnalyticsFlightsAndAppSettings();
        return flightsAndApplicationSettingsCache;
    }

    trace.warn(' AnalyticsFlightsAndEcsSettingsAddon is not registered', 'analytics');

    return null;
}

export function updateFlightsAndApplicationSettingsCache(cache: AnalyticsFlightsAndAppSettings) {
    flightsAndApplicationSettingsCache = cache;
}

function TEST_ONLY_getDefaultSettings(): AnalyticsFlightsAndAppSettings {
    return {
        disabledDatapoints: [],
        throttledDatapoints: [],
        disableIsCoreDatapoints: [],
        enableHighCountEventThrottle: true,
        highCountEventThrottleWindow: 15,
        highCountEventThrottleThreshold: 500,
        capturePerfProfiles: [],

        // Feature Flights
        shouldCaptureAssets: true,
        skipFlightControls: false,
        isFullVerboseLoggingEnabled: false,
        enablePrivacyGuard: false,
        turnOffCustomData: false,
        devToolsEnabled: false,
        trackEventFrequency: false,
        isDataWorkerEnabled: false,
        enableOfflineCaching: false,
        isDiagDatapointTracingPanelEnabled: false,
    };
}

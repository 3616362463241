import { getBposNavBarData, getActiveExperiences } from 'owa-bpos-store';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Checks to see if the user has the flag in the activeExperiences
 */
export default function doesActiveExperiencesInclude(
    flag: string,
    mailboxInfo?: MailboxInfo
): boolean {
    const bposData = getBposNavBarData(mailboxInfo);
    const clientData = JSON.parse(bposData?.ClientData || '{}');
    const activeExperiences = clientData?.ActiveExperiences || getActiveExperiences(mailboxInfo);
    if (activeExperiences) {
        return activeExperiences.split(',').includes(flag);
    }

    return false;
}

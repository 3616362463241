import { isUserPersonalizationAllowed, isTenantThemeDataAvailable } from 'owa-theme-common';
import { isEdu } from 'owa-session-store';
import { ThemeConstants } from 'owa-theme-shared';
import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import { type ThemeCategory } from '../types';

interface ThemeIdsAndCondition {
    themes: string[];
    isAvailable: () => boolean;
}

// These are in sorted order, which is why some conditionals may be repeated
export function themeIdsAndConditions(
    mailboxInfo: MailboxInfo,
    category: ThemeCategory
): ThemeIdsAndCondition[] {
    switch (category) {
        case 'Classic':
            return [
                {
                    themes: [ThemeConstants.BASE_THEME_ID],
                    isAvailable: () => true,
                },
                {
                    themes: [ThemeConstants.BASE_OFFICE_THEME_ID],
                    isAvailable: () =>
                        isTenantThemeDataAvailable() && isUserPersonalizationAllowed(),
                },
                {
                    themes: [ThemeConstants.CONTRAST_THEME_ID],
                    isAvailable: () => true,
                },
                {
                    // Pride themes
                    themes: ['rainbow', 'ribbon', 'unicorn'],
                    isAvailable: isUserPersonalizationAllowed,
                },
                {
                    // EDU themes
                    themes: ['supplies', 'backpack'],
                    isAvailable: () => {
                        return !!isEdu(mailboxInfo) && isUserPersonalizationAllowed();
                    },
                },
                {
                    themes: [
                        'mountain',
                        'beach',
                        'circuit',
                        'blueprint',
                        'far',
                        'whale',
                        'super',
                        'jelly',
                        'wrld',
                        'angular',
                        'balloons',
                        'black',
                        'blueberry',
                        'bricks',
                        'cats',
                        'chevron',
                        'comic',
                        'cordovan',
                        'crayon',
                        'cubes',
                        'cubism',
                        'darkcordovan',
                        'darkorange',
                        'diamonds',
                        'grape',
                        'lightblue',
                        'lightgreen',
                        'lite',
                        'mediumdarkblue',
                        'minimal',
                        'modern',
                        'orange',
                        'paint',
                        'pink',
                        'pixel',
                        'polka',
                        'pomegranate',
                        'primary',
                        'raspberry',
                        'robot',
                        'simple',
                        'spectrum',
                        'strawberry',
                        'teagarden',
                        'teal',
                        'watermelon',
                        'whimsical',
                        'wntrlnd',
                    ],
                    isAvailable: isUserPersonalizationAllowed,
                },
            ];

        case 'ModernImage':
            return [
                {
                    themes: [
                        'alpineglow',
                        ThemeConstants.MODERN_ARCTIC_SOLITUDE_THEME_ID,
                        'natureundefined',
                        'readyconfetti',
                        'magneticmood',
                        'futureplus',
                        'ribbonrelease',
                        'rubyhills',
                        'marigoldhills',
                        'summersummit',
                    ],
                    isAvailable: () => isUserPersonalizationAllowed(),
                },
            ];

        case 'ModernColor':
            return [
                {
                    themes: [
                        ThemeConstants.MODERN_OFFICE_NEUTRAL_THEME_ID,
                        'neworange',
                        'red',
                        'green',
                        'purple',
                        'newpink',
                    ],
                    isAvailable: () => isUserPersonalizationAllowed(),
                },
            ];

        case 'Copilot':
            return [
                {
                    themes: [ThemeConstants.COPILOT_CUSTOM_THEME_ID],
                    isAvailable: () =>
                        isFeatureEnabled('fwk-copilot-themes') && isUserPersonalizationAllowed(),
                },
            ];

        default:
            return [];
    }
}

export default function getAllThemeIds(mailboxInfo: MailboxInfo, themeCategory: ThemeCategory) {
    return themeIdsAndConditions(mailboxInfo, themeCategory).reduce(
        (accumulatedThemeIds: string[], currentSet) =>
            accumulatedThemeIds.concat(currentSet.isAvailable() ? currentSet.themes : []),
        []
    );
}

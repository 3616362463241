import { getItem, setItem } from 'owa-local-storage';
import toggleLightRP from '../actions/toggleLightRP';

export function getIsLightRPOnFromStorage(): boolean {
    return JSON.parse(
        getItem(window, 'IS_LIGHT_RP_ON_SETTING') || JSON.stringify(false)
    ) as boolean;
}

export function saveIsLightRPOnToStorage(currentValue: boolean) {
    setItem(window, 'IS_LIGHT_RP_ON_SETTING', JSON.stringify(currentValue));
}

// Enable other sessions to update store if local storage changes
window.addEventListener('storage', (event: StorageEvent) => {
    if (event.key === 'olk-IS_LIGHT_RP_ON_SETTING') {
        toggleLightRP();
    }
});

import { getItem, type LocalStorageKeys, setItem } from 'owa-local-storage';
import { getStore } from '../store/zoomStore';
import { ZOOM_INCREMENTS, DEFAULT_ZOOM } from './constants';

const internalStorageKeys: Record<string, LocalStorageKeys> = {
    EMBIGGEN_ZOOM_LEVEL_SETTING: 'EMBIGGEN_ZOOM_LEVEL_SETTING',
};

/**
 * Removed list related operation
 */
export function getZoomIncrementFromStorage(): number {
    return JSON.parse(
        getItem(window, internalStorageKeys.EMBIGGEN_ZOOM_LEVEL_SETTING) ||
            JSON.stringify(DEFAULT_ZOOM)
    ) as number;
}

export function saveZoomIncrementToStorage(currentZoomIncrement: number) {
    setItem(
        window,
        internalStorageKeys.EMBIGGEN_ZOOM_LEVEL_SETTING,
        JSON.stringify(currentZoomIncrement)
    );
}

// Enable other sessions to update store if local storage changes
window.addEventListener('storage', (event: StorageEvent) => {
    if (event.key == internalStorageKeys.EMBIGGEN_ZOOM_LEVEL_SETTING) {
        const store = getStore();
        let zoomIncrementIndex = ZOOM_INCREMENTS.indexOf(getZoomIncrementFromStorage());
        // If the zoom level is not found because it was removed in code for any reason
        if (zoomIncrementIndex == -1) {
            zoomIncrementIndex = ZOOM_INCREMENTS.indexOf(DEFAULT_ZOOM);
        }
        store.zoomIncrementIndex = zoomIncrementIndex;
    }
});

import { upNextRoot } from './getExtraTopRibbonControlsAfterTabs.scss';
import type { IButtonStyles } from '@fluentui/react';
import { UpNextV2 } from 'owa-upnext-v2';
import React from 'react';
import { MonarchWin32Toggle, lazyIsToggleEnabled } from 'native-newoutlook-toggle';
import { observer } from 'owa-mobx-react';
import { owaComputedFn } from 'owa-computed-fn';
import { isSafeModeSession } from 'owa-config/lib/isSafeModeSession';
import { isRecoveryModeSession } from 'owa-config/lib/isRecoveryModeSession';
import { SafeMode, RecoveryMode } from './getModeContainerComponents';

const upNextStyles: IButtonStyles = {
    root: upNextRoot,
};

const ExtraTopRibbonControlsAfterTabs = observer(function ExtraTopRibbonControlsAfterTabs() {
    const [toggleEnabled, setToggleEnabled] = React.useState(false);
    React.useEffect(() => {
        const loadToggleEnabled = async () => {
            setToggleEnabled(await lazyIsToggleEnabled.importAndExecute());
        };
        loadToggleEnabled();
    }, []);

    const safeMode = isSafeModeSession();
    const recoveryMode = isRecoveryModeSession();

    return (
        <>
            {safeMode ? <SafeMode /> : recoveryMode ? <RecoveryMode /> : null}
            <UpNextV2
                containerStyle={upNextStyles}
                key="UpNextV2_ExtraTopRibbonControlsAfterTabs"
            />
            {toggleEnabled && <MonarchWin32Toggle />}
        </>
    );
}, 'ExtraTopRibbonControlsAfterTabs');

export const getExtraTopRibbonControlsAfterTabs = owaComputedFn(
    function getExtraTopRibbonControlsAfterTabs() {
        return <ExtraTopRibbonControlsAfterTabs />;
    }
);

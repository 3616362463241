import getValidFolderPaneWidth from './getValidFolderPaneWidth';
import { setShowFolderPane } from '../actions/setShowFolderPane';
import logFolderPaneState from '../helpers/logFolderPaneState';
import { default as setFolderPaneWidth } from '../legacyActions/setFolderPaneWidth';
import initializeListViewColumnWidths from '../mutators/initializeListViewColumnWidths';
import {
    getIsBitSet,
    type ListViewBitFlagsMasks,
} from 'owa-bit-flags/lib/utils/listViewBitFlagsUtil';
import { initializeDynamicLayout, LayoutChangeSource } from 'owa-layout';
import { areDisplayAdsShown } from 'owa-mail-ads-checker';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import initializeSingleLineViewWithRightReadingPane from '../mutators/initializeSingleLineWithRightReadingPane';
import { initializeAnimations } from './initializeAnimations';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { lazyInitializeMailOrganizationSettings } from 'owa-mail-organization-settings';
import { lazyInitializeMailOrganizationViewTypes } from 'owa-mail-organization-viewtypes-settings';
import { isFeatureEnabled } from 'owa-feature-flags';

export function initializeMailLayout() {
    /**
     * On init try to validate the folder pane width (NavigationBarWidth)
     */
    const storedFolderPaneWidth = getValidFolderPaneWidth(
        getUserConfiguration().UserOptions?.NavigationBarWidth,
        LayoutChangeSource.Init
    );

    // Set folder pane width
    setFolderPaneWidth(storedFolderPaneWidth);

    const showFolderPane = !getIsBitSet(4, getGlobalSettingsAccountMailboxInfo());
    setShowFolderPane(showFolderPane);
    logFolderPaneState(LayoutChangeSource.Init, showFolderPane, showFolderPane);

    initializeListViewColumnWidths();
    initializeSingleLineViewWithRightReadingPane();
    initializeAnimations();

    initializeDynamicLayout(areDisplayAdsShown());

    lazyInitializeMailOrganizationSettings.importAndExecute();

    if (isFeatureEnabled('rp-momocoSettings')) {
        lazyInitializeMailOrganizationViewTypes.importAndExecute();
    }
}

import { observer } from 'owa-mobx-react';
import React from 'react';
import type { MailListItemWrapperProps } from 'owa-mail-list-item-shared';
import { getStore as getListViewStore, getListViewState } from 'owa-mail-list-store';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import { getIsChecked, useBackgroundColorClass } from 'owa-mail-list-item-shared';
import { MailListItem } from 'owa-mail-list-item';
import { mailListItemTouchBehavior } from 'owa-mail-list-swipe';
import { getBigHoverAction } from 'owa-surface-actions/lib/utils/getBigHoverAction';
import {
    getHoverSurfaceAction,
    getStore as getHoverSurfaceActionStore,
} from 'owa-surface-actions-option';
import getUnreadItemClass from '../selectors/getUnreadItemClass';
import { BigHoverAction } from '../lazyFunctions';
import { shouldShowBigHoverAction } from './BigHoverAction/utils/shouldShowBigHoverAction';
import { getDensityModeString } from 'owa-fabric-theme';
import classnames from 'owa-classnames';

import {
    backgroundChangeOnHover,
    monarchUnselectedHover,
} from 'owa-mail-listitem-styles/lib/scss/MailListItem.scss';
import {
    showDeleteOnHover,
    mailListItem,
    unreadMailListItem,
    unreadMailListItemDarker,
    mailListItemRightPadding,
} from 'owa-mail-listitem-styles/lib/scss/ListViewItemCommonStyles.scss';
import { isFeatureEnabled } from 'owa-feature-flags';

const MailListItemWrapper = observer(function MailListItemWrapper(props: MailListItemWrapperProps) {
    const {
        mailListItemDataProps,
        mailListTableProps,
        isFirstLevelExpanded,
        isSecondLevelExpanded,
        isLoadingSecondLevelExpansion,
        styleSelectorAsPerUserSettings,
    } = props;

    const { tableViewId, isSingleLine } = mailListTableProps;

    const {
        canDelete,
        isFlagged,
        isInArchiveFolder,
        isInOutboxFolder,
        isPinned,
        isSelected,
        lastDeliveryTimestamp,
        rowKey,
        unreadCount,
    } = mailListItemDataProps;

    const tableView = getListViewStore().tableViews.get(tableViewId);

    const isRowExpanded = isFirstLevelExpanded || isSecondLevelExpanded;
    const selectedNodeCount = useComputedValue(
        () => getListViewState().expandedConversationViewState?.selectedNodeIds?.length,
        []
    );

    const isChecked = useComputedValue(
        () => getIsChecked(tableView, rowKey, lastDeliveryTimestamp),
        [tableViewId, rowKey, lastDeliveryTimestamp]
    );

    const backgroundColorClass = useBackgroundColorClass(
        isSelected,
        isFlagged,
        isPinned,
        isChecked,
        mailListTableProps.supportsFlagging,
        mailListTableProps.supportsPinning,
        isRowExpanded,
        selectedNodeCount
    );

    const unreadItemClass = getUnreadItemClass(
        mailListItemDataProps,
        mailListTableProps,
        isChecked,
        unreadMailListItem,
        unreadMailListItemDarker
    );

    const shouldRelocateHoverIcons = isFeatureEnabled('tri-mlRelocateHoverActions') && isSingleLine;

    const shouldBackgroundChangeOnHover =
        (!isSelected && !isChecked) || tableView?.isInVirtualSelectAllMode;

    const bigHoverAction = getBigHoverAction(
        getHoverSurfaceActionStore().hoverSurfaceActions ?? getHoverSurfaceAction()
    );

    const showBigHoverAction =
        !shouldRelocateHoverIcons &&
        bigHoverAction &&
        shouldShowBigHoverAction(bigHoverAction, canDelete, isInArchiveFolder, isInOutboxFolder);

    // Outer container CSS classes that are shared between 3 column and single line views
    const mailListItemOuterContainerClasses = classnames(
        mailListItem,
        shouldRelocateHoverIcons && mailListItemRightPadding,
        showBigHoverAction && showDeleteOnHover,
        backgroundColorClass,
        shouldBackgroundChangeOnHover && backgroundChangeOnHover,
        unreadCount > 0 && unreadItemClass,
        showBigHoverAction && !isSelected && monarchUnselectedHover
    );

    return (
        <div className={mailListItemOuterContainerClasses} tabIndex={-1}>
            <MailListItem
                mailListTableProps={mailListTableProps}
                mailListItemDataProps={mailListItemDataProps}
                styleSelectorAsPerUserSettings={styleSelectorAsPerUserSettings}
                isFirstLevelExpanded={isFirstLevelExpanded}
                isSecondLevelExpanded={isSecondLevelExpanded}
                isLoadingSecondLevelExpansion={isLoadingSecondLevelExpansion}
            />
            {showBigHoverAction && (
                <BigHoverAction
                    rowKey={rowKey}
                    tableViewId={tableViewId}
                    isSingleLine={isSingleLine}
                    isItemPart={false}
                    densityModeString={getDensityModeString()}
                />
            )}
        </div>
    );
}, 'MailListItemWrapper');

export const MailListItemWithTouchBehavior = mailListItemTouchBehavior(MailListItemWrapper);

import React from 'react';
import PrimaryMailFolderTreeContainer from './PrimaryMailFolderTreeContainer';
import { ArchiveMailFolderTreeContainer, SharedFolderTreeParentContainer } from '../lazyFunctions';
import { isCapabilityEnabled } from 'owa-capabilities';
import { archiveAndSharedCapability } from 'owa-capabilities-definitions/lib/archiveAndSharedCapability';
import { observer } from 'owa-mobx-react';
import { LazyFolderPermissionsDialog } from 'owa-mail-folder-permissions';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import { FolderHierarchyConfigurationQueryDocument } from '../graphql/__generated__/FolderHierarchyConfigurationQuery.interface';
import { useManagedQuery } from 'owa-apollo-hooks/lib/useManagedQuery';
import type { MailboxInfo } from 'owa-client-types';
import { GroupListTree, GroupsSwitchOrGotoCTA } from 'owa-group-left-nav-mail';
import { isGroupsEnabled } from 'owa-account-capabilities/lib/isGroupsEnabled';
import { getSharedFolderUserEmails } from 'owa-shared-folder/lib/selectors/getSharedFolderUserEmails';
import { getArchiveFolderTreeRootFolder } from 'owa-folders';
import { getDensityMode } from 'owa-fabric-theme';
import {
    BootState,
    getMailAccountSources,
    accountSourceDataTypeChecker,
    getGlobalSettingsAccountMailboxInfo,
} from 'owa-account-source-list-store';
import { lazyGetAccountInformation } from 'owa-storage-store';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { loadMailFolders } from 'owa-mail-folder-store';
import { loadFavorites } from 'owa-favorites';
import { Spinner } from '@fluentui/react-components';
import { isMonarchMultipleAccountsEnabled } from 'owa-account-source-list/lib/flights';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import { isFeatureEnabled } from 'owa-feature-flags';
import { LazyNewFolderDialog } from 'owa-mail-newfolder-dialog';
import { LazyMoveToFolderDialog } from 'owa-mail-movefolder-dialog';

export interface MailFolderTreesParentContainerProps {
    favoritesNodeCount: number;
    setSize?: number;
    accountMailboxInfo: MailboxInfo;
    isExpanded?: boolean;
    shouldHideRootNode?: boolean;
    accountBootState: BootState;
    ellipsesOnHover?: boolean;
    rootElementRef?: React.RefObject<HTMLDivElement>;
    shouldHideShared?: boolean;
}

enum densitySpacing {
    Compact = '12px',
    Full = '12px',
    Simple = '8px',
}

/**
 * Parent container component hosting primary, archive and shared folder tree containers
 */
export default observer(function MailFolderTreesParentContainer(
    props: MailFolderTreesParentContainerProps
) {
    const {
        isExpanded,
        favoritesNodeCount,
        accountMailboxInfo,
        setSize,
        shouldHideRootNode,
        accountBootState,
        ellipsesOnHover,
        shouldHideShared,
    } = props;
    const globalSettingsAccountMailboxInfo = getGlobalSettingsAccountMailboxInfo();
    const isPrimaryAcount =
        getIndexerValueForMailboxInfo(accountMailboxInfo) ===
        getIndexerValueForMailboxInfo(globalSettingsAccountMailboxInfo);
    const isPreRenderComplete = accountBootState === BootState.StartupComplete;
    const densityMode = useComputedValue(() => {
        return getDensityMode();
    }, []);
    const monarchMultiAccountEnabled = isMonarchMultipleAccountsEnabled();

    const accountPaddingStyle = React.useMemo(
        () =>
            !monarchMultiAccountEnabled
                ? { paddingBottom: densitySpacing[densityMode] }
                : undefined,
        [densityMode, monarchMultiAccountEnabled]
    );

    React.useEffect(() => {
        (async () => {
            // For primary accounts, this happens on boot
            // For non-primary accounts we have to load it
            if (!isPrimaryAcount && isPreRenderComplete) {
                await loadMailFolders(accountMailboxInfo);

                loadFavorites(accountMailboxInfo);

                lazyGetAccountInformation.importAndExecute(accountMailboxInfo);
            }
        })();
    }, [accountMailboxInfo, isPreRenderComplete]);

    const { loading, data } = useManagedQuery(FolderHierarchyConfigurationQueryDocument, {
        variables: {
            mailboxInfo: accountMailboxInfo,
        },
    });
    //Special case: display spinner if prerender is not completed
    if (loading || !data || !isPreRenderComplete) {
        return <Spinner size="tiny" />;
    }

    // Primary root node should be shown in case of any one of the Favorites or Groups or Archive mailbox or Shared Folders is shown
    const shouldShowPrimaryRootNode =
        !shouldHideRootNode && getIsPrimaryRootShown(favoritesNodeCount > 0);
    let positionInSet = favoritesNodeCount + 1;

    const userConfig = data?.userConfiguration;
    /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
     * Adding IsShadowMailbox to restricted properties/methods.
     *	> 'IsShadowMailbox' is restricted from being used. IsCloudCache/IsShadowMailbox should be resolved in ECS as a filter in a feature flight if possible. */
    const isShadowMailbox = !!userConfig?.SessionSettings?.IsShadowMailbox;
    const archiveRootDisplayName = userConfig?.SessionSettings?.ArchiveDisplayName;
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain -- (https://aka.ms/OWALintWiki)
     * Baseline. DO NOT COPY AND PASTE!
     *	> Optional chain expressions can return undefined by design - using a non-null assertion is unsafe and wrong. */
    const archiveMailboxGuid = userConfig?.SessionSettings?.ArchiveMailboxGuid!;
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain -- (https://aka.ms/OWALintWiki)
     * Baseline. DO NOT COPY AND PASTE!
     *	> Optional chain expressions can return undefined by design - using a non-null assertion is unsafe and wrong. */
    const organizationDomain = userConfig?.SessionSettings?.OrganizationDomain!;
    const hasArchive = userConfig?.SessionSettings?.HasArchive;
    const showOnlineArchiveEnabled = userConfig?.PolicySettings?.ShowOnlineArchiveEnabled;
    const shouldShowArchiveTree = hasArchive && showOnlineArchiveEnabled && archiveRootDisplayName;
    const isFolderTreeMemoEnabled = isFeatureEnabled('fp-memo-folderTree');

    const getNextPositionInSet = (increment: number): number | undefined => {
        if (accountMailboxInfo) {
            return undefined;
        }

        positionInSet = positionInSet + increment;
        return positionInSet;
    };

    return (
        <>
            <PrimaryMailFolderTreeContainer
                showRootNode={shouldShowPrimaryRootNode}
                setSize={setSize}
                positionInSet={getNextPositionInSet(0)}
                userConfiguration={isFolderTreeMemoEnabled ? undefined : userConfig}
                isMailRootFolderTreeCollapsed={
                    isFolderTreeMemoEnabled
                        ? userConfig?.UserOptions?.IsMailRootFolderTreeCollapsed
                        : undefined
                }
                isExplicitLogon={
                    isFolderTreeMemoEnabled
                        ? userConfig?.SessionSettings?.IsExplicitLogon
                        : undefined
                }
                userEmailAddress={
                    isFolderTreeMemoEnabled
                        ? userConfig?.SessionSettings?.UserEmailAddress
                        : undefined
                }
                isShadowMailbox={isShadowMailbox}
                mailboxInfo={accountMailboxInfo}
                isExpanded={isExpanded}
                style={accountPaddingStyle}
                ellipsesOnHover={ellipsesOnHover}
                rootElementRef={props.rootElementRef}
            />
            {isCapabilityEnabled(archiveAndSharedCapability, accountMailboxInfo) && (
                <>
                    {shouldShowArchiveTree && (
                        <ArchiveMailFolderTreeContainer
                            setSize={setSize}
                            positionInSet={getNextPositionInSet(1)}
                            archiveRootDisplayName={archiveRootDisplayName}
                            archiveMailboxGuid={archiveMailboxGuid}
                            organizationDomain={organizationDomain}
                            isShadowMailbox={isShadowMailbox}
                            primaryMailboxInfo={accountMailboxInfo}
                            userEmailAddress={accountMailboxInfo.mailboxSmtpAddress}
                            style={accountPaddingStyle}
                            ellipsesOnHover={ellipsesOnHover}
                        />
                    )}

                    {!shouldHideShared && (
                        <SharedFolderTreeParentContainer
                            shouldRenderRootNode={monarchMultiAccountEnabled}
                            setSize={setSize}
                            positionInSet={getNextPositionInSet(1)}
                            accountMailboxInfo={accountMailboxInfo}
                            isShadowMailbox={isShadowMailbox}
                            primaryMailboxInfo={accountMailboxInfo}
                            style={accountPaddingStyle}
                            ellipsesOnHover={ellipsesOnHover}
                        />
                    )}

                    <LazyFolderPermissionsDialog />
                </>
            )}
            <LazyNewFolderDialog />
            <LazyMoveToFolderDialog />
            {isGroupsEnabled(accountMailboxInfo) &&
                (isFeatureEnabled('grp-WeSpaceDeprecation') ? (
                    <GroupsSwitchOrGotoCTA mailboxInfo={accountMailboxInfo} />
                ) : (
                    <GroupListTree
                        mailboxInfo={accountMailboxInfo}
                        setSize={setSize}
                        style={accountPaddingStyle}
                        positionInSet={getNextPositionInSet(
                            getSharedFolderUserEmails(accountMailboxInfo)?.length
                        )}
                        ellipsesOnHover={ellipsesOnHover}
                    />
                ))}
        </>
    );
},
'MailFolderTreesParentContainer');

function getIsPrimaryRootShown(isFavoriteShown: boolean): boolean {
    return isFavoriteShown || isGroupsEnabled() || !isConsumer();
}

/**
 * Gets the amount of folders that will be displayed by retrieving which folder trees are shown.
 * @param favoritesNodeCount favorites node count
 * @return The total amount of folder trees shown for the aria-size
 */
export function getSetSize(isAccountListShown: boolean, favoritesNodeCount: number): number {
    let setSize = favoritesNodeCount;
    if (isAccountListShown) {
        setSize =
            setSize +
            getMailAccountSources().filter(accountSource =>
                accountSourceDataTypeChecker.isM365Mailbox(accountSource)
            ).length;
    } else {
        getIsPrimaryRootShown(favoritesNodeCount > 0) && setSize++;
        const mailboxInfo = getGlobalSettingsAccountMailboxInfo();
        if (!isConsumer(undefined, mailboxInfo)) {
            // Check if the shared folder tree will be displayed for SharedFolder Tree
            if (getSharedFolderUserEmails(mailboxInfo)?.length) {
                setSize = setSize + getSharedFolderUserEmails(mailboxInfo)?.length;
            }

            getArchiveFolderTreeRootFolder(mailboxInfo) != null && setSize++; // For ArchiveFolder Tree
        }

        isGroupsEnabled() && setSize++;
    }

    return setSize;
}

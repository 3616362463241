import { observer } from 'owa-mobx-react';
import { ContextualMenu } from '@fluentui/react';
import React from 'react';
import { createPortal } from 'owa-react-dom';
import { onNativeContextMenuDismiss } from '../utils/nativeContextMenuEventHandlers';
import getNativeContextMenuStateStore from '../store/store';

// Native Context Menu is only enabled on Monarch
function NativeContextMenu() {
    const store = getNativeContextMenuStateStore();

    // When the native context menu is re-opended on a different window (e.g. a popup) is not getting rendered properly sometimes,
    // so we need to unmount it so that it gets the correct target window every time is displayed.
    if (!store.showContextMenu) {
        return null;
    }

    return createPortal(
        <ContextualMenu
            items={store.contextualMenuItems}
            hidden={!store.showContextMenu}
            target={store.point}
            onDismiss={onNativeContextMenuDismiss}
            doNotLayer={true}
        />,
        document.body
    );
}

export default observer(NativeContextMenu, 'NativeContextMenu');

import { isCapabilityEnabled } from 'owa-capabilities';
import { copilotThemeCapability } from 'owa-capabilities-definitions/lib/copilotThemeCapability';
import { lazyFetchCopilotThemeImage } from 'owa-copilot-themes';
import { getBackgroundImageData, getIsCurrentThemeModernImage } from 'owa-theme';
import { fetchThemeBackgroundImage } from 'owa-theme-shared';
import { addImageUrlToLocalStorage, removeImageUrlFromLocalStorage } from './localStorageHelper';

export default async function fetchBackgroundImage(
    themeId: string,
    isDarkTheme: boolean
): Promise<string | null | undefined> {
    const isCopilotThemeEnabled = isCapabilityEnabled(copilotThemeCapability);
    const isModernImageTheme = getIsCurrentThemeModernImage(themeId);
    const imageUrl = isModernImageTheme ? getBackgroundImageData(themeId, isDarkTheme) : undefined;

    if (isModernImageTheme && imageUrl) {
        addImageUrlToLocalStorage(imageUrl);
    } else if (isCopilotThemeEnabled) {
        removeImageUrlFromLocalStorage();
    }

    return isModernImageTheme && imageUrl
        ? fetchThemeBackgroundImage(imageUrl)
        : isCopilotThemeEnabled
        ? lazyFetchCopilotThemeImage.importAndExecute()
        : undefined;
}

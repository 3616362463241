import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import getInstrumentationContextsFromTableView from 'owa-mail-list-store/lib/utils/getInstrumentationContextsFromTableView';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import shouldShowUnstackedReadingPane from 'owa-mail-store/lib/utils/shouldShowUnstackedReadingPane';
import { translateEwsIdsToEwsImmutableIds, ConvertIdSource } from 'owa-immutable-id';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import type TableView from 'owa-mail-list-store/lib/store/schema/TableView';
import isValidConversationCache from 'owa-mail-store/lib/selectors/isValidConversationCache';
import type { InstrumentationContext } from 'owa-search-types/lib/types/InstrumentationContext';
import { isDumpsterSearchTable } from 'owa-mail-list-search';
import {
    isDumpsterTable,
    MailRowDataPropertyGetter,
    updateShouldShowSelectedRowInRp,
} from 'owa-mail-list-store';
import SubstrateSearchScenario from 'owa-search-service/lib/data/schema/SubstrateSearchScenario';
import { lazyLogSearchEntityActions } from 'owa-search-instrumentation';
import { lazyLoadConversation } from 'owa-mail-store-actions';
import { logUsage } from 'owa-analytics';
import { lazyLoadItemReadingPaneForSingleMailItemSelected } from 'owa-mail-reading-pane-store';
import { loadConversationReadingPaneForSingleMailItemSelected } from 'owa-mail-reading-pane-store-conversation';
import { SearchScenarioId } from 'owa-search-store/lib/store/schema/SearchScenarioId';
import { getAggregationBucket } from 'owa-analytics-actions';
import { setShowListPane } from 'owa-mail-layout/lib/actions/setShowListPane';
import { setShowReadingPane } from 'owa-mail-layout/lib/actions/setShowReadingPane';
import {
    isReadingPanePositionBottom,
    isReadingPanePositionRight,
    isReadingPanePositionOff,
} from 'owa-mail-layout/lib/selectors/readingPanePosition';
import { getActiveContentTab, lazyActivateTab, primaryTab } from 'owa-tab-store';
import type { LoadConversationItemActionSource } from 'owa-mail-store';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getComposeViewState } from 'owa-mail-reading-pane-store-conversation/lib/utils/getComposeViewState';
import { isImmutableId } from 'owa-immutable-id-store';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { getFolderIdForSelectedNode } from 'owa-mail-folder-forest-store';
import { getSelectMailItemDatapoint } from 'owa-mail-logging/lib/utils/selectMailItemDatapointGetterAndSetter';
import doesFolderSupportConversationGrouping from '../utils/doesFolderSupportConversationGrouping';
import { isServiceRequestSupportedForMailbox } from 'owa-service/lib/utils/isServiceRequestSupportedForMailbox';
import endSelectMailItemDatapoint from 'owa-mail-logging/lib/utils/endSelectMailItemDatapoint';

/**
 * Handles no item selection in list view
 */
function handleListViewNoItemSelectedChange(isSingleLineView: boolean) {
    setShowListPane(true /* showListPane */);

    if (isSingleLineView) {
        setShowReadingPane(false /* showReadingPane */);
    } else {
        setShowReadingPane(true /* showReadingPane */);
    }
}

/**
 * Handles single item selection in list view
 */
function handleListViewSingleItemSelectedChangedWhenReadingPaneOff(
    rowKey: string,
    isUserNavigation: boolean,
    mailListItemSelectionSource: MailListItemSelectionSource,
    instrumentationContext: InstrumentationContext,
    itemToScrollTo: string,
    tableView: TableView
): Promise<void> {
    let promiseToReturn = Promise.resolve();
    let showReadingPane = false;
    let showListPane = true;

    switch (mailListItemSelectionSource) {
        case MailListItemSelectionSource.MailListItemBody:
        case MailListItemSelectionSource.CommandBarArrows: // Only shown in immersive reading pane
        case MailListItemSelectionSource.KeyboardEnter:
        case MailListItemSelectionSource.MailListItemExpansion:
        case MailListItemSelectionSource.MailListItemRichPreview:
        case MailListItemSelectionSource.SearchSuggestionClick:
        case MailListItemSelectionSource.RouteHandler:
            showReadingPane = true;
            showListPane = false;
            break;
        case MailListItemSelectionSource.MailListItemTwisty:
            promiseToReturn = loadConversationItems(
                rowKey,
                tableView,
                'LoadConvReadingPaneLVTwisty'
            );
            showReadingPane = false;
            showListPane = true;
            break;
        case MailListItemSelectionSource.ImmersiveReadingPaneDelete:
            if (getUserConfiguration().UserOptions?.NextSelection === 'ReturnToView') {
                showReadingPane = false;
                showListPane = true;
            } else {
                showReadingPane = true;
                showListPane = false;
            }
            break;
        case MailListItemSelectionSource.KeyboardUpDown:
        case MailListItemSelectionSource.MailListItemCheckbox:
        case MailListItemSelectionSource.KeyboardCtrlSpace:
        case MailListItemSelectionSource.MailListItemContextMenu:
        case MailListItemSelectionSource.NewConversation:
        case MailListItemSelectionSource.Reset:
        case MailListItemSelectionSource.RowRemoval:
        default:
            showReadingPane = false;
            showListPane = true;
            break;
    }

    if (showReadingPane) {
        instrumentationContext?.dp?.addCheckpoint?.('OFF_LRP');
        promiseToReturn = loadReadingPane(
            rowKey,
            tableView,
            isUserNavigation,
            instrumentationContext,
            itemToScrollTo,
            true /*isSingleLineView */,
            mailListItemSelectionSource
        );
    }

    setShowReadingPane(showReadingPane);
    setShowListPane(showListPane);

    return promiseToReturn;
}

let propagateLoadReadingPaneTask: ReturnType<typeof setInterval> | null;
let lastKeyboardSelectionTime: number = 0;

/**
 * Handles single item selection in list view
 */
function handleListViewSingleItemSelectedChangedWhenReadingPaneOn(
    rowKey: string,
    isUserNavigation: boolean,
    mailListItemSelectionSource: MailListItemSelectionSource,
    instrumentationContext: InstrumentationContext,
    itemToScrollTo: string,
    tableView: TableView,
    eventTimestamp?: number
): Promise<void> {
    if (propagateLoadReadingPaneTask) {
        clearTimeout(propagateLoadReadingPaneTask);
        propagateLoadReadingPaneTask = null;
    }

    // In unstackedReading pane, we need to call GCI to populate itemparts if
    // Conevrsation is not in mailstore yet AND
    // Folder supports conversations. Eg. junk / drafts folders do not support conversations and always contain individual items.
    if (shouldShowUnstackedReadingPane() && doesFolderSupportConversationGrouping()) {
        loadConversationItems(rowKey, tableView, 'LoadConvReadingPaneUnstacked');
    }

    if (mailListItemSelectionSource !== MailListItemSelectionSource.MailListItemContextMenu) {
        updateShouldShowSelectedRowInRp(true);
        setShowReadingPane(true /* showReadingPane */);
    } else {
        updateShouldShowSelectedRowInRp(false);
        endSelectMailItemDatapoint('' /* contentType */, true /* shouldInvalidate */);
    }

    setShowListPane(true /* showListPane */);

    // [Perf optimization] If user is moving through the listview via keyboard up/down arrows, add a slight delay
    // to avoid us having to load every conversation/item if user is just trying to scroll (each stroke is within a small window).
    if (mailListItemSelectionSource === MailListItemSelectionSource.KeyboardUpDown) {
        const currentKeyboardSelectionTime = Date.now();
        const selectionTimeDiff = currentKeyboardSelectionTime - lastKeyboardSelectionTime;
        lastKeyboardSelectionTime = currentKeyboardSelectionTime;

        if (
            selectionTimeDiff <= 500 // milliseconds
        ) {
            return new Promise<void>((resolve, reject) => {
                instrumentationContext?.dp?.addCheckpoint?.('ON_LRP_TO');
                propagateLoadReadingPaneTask = setTimeout(() => {
                    propagateLoadReadingPaneTask = null;
                    loadReadingPane(
                        rowKey,
                        tableView,
                        isUserNavigation,
                        instrumentationContext,
                        itemToScrollTo,
                        false /*isSingleLineView */,
                        mailListItemSelectionSource,
                        eventTimestamp
                    )
                        .then(resolve)
                        .catch(reject);
                }, 250);
            });
        }
    }

    instrumentationContext?.dp?.addCheckpoint?.('ON_LRP');
    return loadReadingPane(
        rowKey,
        tableView,
        isUserNavigation,
        instrumentationContext,
        itemToScrollTo,
        false /*isSingleLineView */,
        mailListItemSelectionSource,
        eventTimestamp
    );
}

function loadReadingPane(
    rowKey: string,
    tableView: TableView,
    isUserNavigation: boolean,
    instrumentationContext: InstrumentationContext,
    itemToScrollTo: string | undefined,
    isSingleLineView: boolean,
    mailListItemSelectionSource: MailListItemSelectionSource,
    eventTimestamp?: number
) {
    const rowId = MailRowDataPropertyGetter.getRowClientItemId(rowKey, tableView);
    const isDraft = MailRowDataPropertyGetter.getIsDraft(rowKey, tableView);
    const isSubmitted = MailRowDataPropertyGetter.getIsSubmitted(rowKey, tableView);

    // load RP for outbox item since it should be read-only by default unless user clicks pencil button to edit
    let isFromOutbox: boolean = false;
    const folderId = getFolderIdForSelectedNode();
    if (folderId && folderIdToName(folderId) === 'outbox') {
        isFromOutbox = true;
    }

    const shouldLoadCompose = isDraft && !isSubmitted && !isFromOutbox;
    const shouldNotFocusInCompose =
        shouldLoadCompose &&
        mailListItemSelectionSource == MailListItemSelectionSource.KeyboardUpDown;
    if (shouldLoadCompose) {
        // SMI tracks e2e from selection in ML to loading RP. Invalidate the dp for compose as we are not rendering RP components
        endSelectMailItemDatapoint('' /* contentType */, true /* shouldInvalidate */);
    }
    const readingPaneActionSource = 'ListViewSelectionChange';

    switch (tableView.tableQuery.listViewType) {
        case 0:
            // Unstacked view is a win32 parity setting where reading pane show a single item instead of itemparts stacked one below the other.
            if (shouldShowUnstackedReadingPane()) {
                return lazyLoadItemReadingPaneForSingleMailItemSelected.importAndExecute(
                    MailRowDataPropertyGetter.getRowIdToShowInReadingPane(rowKey, tableView),
                    readingPaneActionSource,
                    isUserNavigation,
                    instrumentationContext,
                    shouldLoadCompose,
                    shouldNotFocusInCompose,
                    'UnstackedConversationView',
                    isSingleLineView,
                    rowId?.Id // we need to pass in the conversationId to load attachments in SHV3
                );
            }
            let maxNumberOfItemsToReturn: number | undefined;

            return loadConversationReadingPaneForSingleMailItemSelected(
                rowId,
                readingPaneActionSource,
                isUserNavigation,
                instrumentationContext,
                MailRowDataPropertyGetter.getSubject(rowKey, tableView),
                MailRowDataPropertyGetter.getCategories(rowKey, tableView),
                MailRowDataPropertyGetter.getCopilotInboxScore(rowKey, tableView),
                mailListItemSelectionSource,
                itemToScrollTo,
                isSingleLineView,
                maxNumberOfItemsToReturn
            );

        case 1:
            return lazyLoadItemReadingPaneForSingleMailItemSelected.importAndExecute(
                rowId,
                readingPaneActionSource,
                isUserNavigation,
                instrumentationContext,
                shouldLoadCompose,
                shouldNotFocusInCompose,
                'ItemView',
                isSingleLineView,
                undefined /*conversationId*/,
                undefined /*state*/,
                eventTimestamp
            );

        default:
            return Promise.resolve();
    }
}

/**
 * Called when selection in list view changes to orchestrate what happens next
 * @param tableView The tableview
 * @param isUserNavigation Did user initiate selection
 * @param mailListItemSelectionSource The source of selection on mail item
 */
export default async function onListViewSelectionChange(
    tableView: TableView,
    isUserNavigation: boolean,
    mailListItemSelectionSource: MailListItemSelectionSource,
    eventTimestamp?: number
): Promise<void> {
    if (mailListItemSelectionSource == MailListItemSelectionSource.MessageAd) {
        // return when MailListItemSelectionSource is MessageAd as selectedRowsCount is 0 at this time
        // We should not enter the handleListViewNoItemSelectedChange block to change the reading pane hide/show status
        return;
    }
    let promiseToReturn = Promise.resolve();
    const selectedRowsCount = tableView.selectedRowKeys.size;
    const originalActiveTab = getActiveContentTab();
    const isSingleLineView = isReadingPanePositionOff();
    if (selectedRowsCount == 0) {
        handleListViewNoItemSelectedChange(isSingleLineView);
    } else if (
        // If only one row is selected and we are not in SLV checked mode, go into one selected row logic,
        // otherwise go into muliselect case.
        selectedRowsCount == 1 &&
        !(isSingleLineView && tableView.isInCheckedMode)
    ) {
        let itemToScrollTo = null;
        const rowKey = [...tableView.selectedRowKeys.keys()][0];
        const tableQuery = tableView.tableQuery;
        const shouldAlwaysShowMultiselectRP =
            isDumpsterTable(tableQuery) || isDumpsterSearchTable(tableQuery);

        const instrumentationContext = getInstrumentationContextsFromTableView(
            [rowKey],
            tableView
        )[0];

        const selectMailItemDatapoint = getSelectMailItemDatapoint();
        if (!shouldAlwaysShowMultiselectRP && instrumentationContext && selectMailItemDatapoint) {
            instrumentationContext.dp = selectMailItemDatapoint;
        }

        if (
            getUserConfiguration().SessionSettings?.IsSubstrateSearchServiceAvailable &&
            instrumentationContext
        ) {
            const referenceKey =
                instrumentationContext.referenceKey ||
                (instrumentationContext.index + 1).toString();
            lazyLogSearchEntityActions.importAndExecute(
                SubstrateSearchScenario.Mail,
                '',
                '',
                instrumentationContext.logicalId || '',
                undefined /* traceId */,
                referenceKey,
                'EntityClicked'
            );
            // 3S returns the overloads the itemIds property and stores the most relevant item in a conversation
            // as the first element of that list.  This property is mapped to the MailRow itemIds property
            // in this function - convertSearchResultConversationToConversationType
            const itemIds = MailRowDataPropertyGetter.getItemIds(rowKey, tableView);
            itemToScrollTo = itemIds ? itemIds[0] : null;
            if (
                isFeatureEnabled('fwk-immutable-ids') &&
                itemToScrollTo &&
                !isImmutableId(itemToScrollTo)
            ) {
                const mailboxInfo = getModuleContextMailboxInfo();
                if (
                    mailboxInfo?.type != 'GroupMailbox' &&
                    mailboxInfo?.type != 'ArchiveMailbox' &&
                    isServiceRequestSupportedForMailbox(mailboxInfo)
                ) {
                    itemToScrollTo = (
                        await translateEwsIdsToEwsImmutableIds(
                            [itemToScrollTo],
                            ConvertIdSource.Search,
                            undefined,
                            mailboxInfo
                        )
                    )[0];
                }
            }

            logSearchResultSelectedDatapoint(rowKey, tableView);
        }
        if (!shouldAlwaysShowMultiselectRP) {
            if (isReadingPanePositionRight() || isReadingPanePositionBottom()) {
                promiseToReturn = handleListViewSingleItemSelectedChangedWhenReadingPaneOn(
                    rowKey,
                    isUserNavigation,
                    mailListItemSelectionSource,
                    instrumentationContext,
                    itemToScrollTo ?? '',
                    tableView,
                    eventTimestamp
                );
            } else if (isSingleLineView) {
                promiseToReturn = handleListViewSingleItemSelectedChangedWhenReadingPaneOff(
                    rowKey,
                    isUserNavigation,
                    mailListItemSelectionSource,
                    instrumentationContext,
                    itemToScrollTo ?? '',
                    tableView
                );
            }
        }
    } else {
        // Multi-select case
        if (isSingleLineView) {
            setShowReadingPane(false /* showReadingPane */);
            setShowListPane(true /* showListPane */);
        }
    }
    // We should activate the primary tab and switch from the current tab if
    // the active tab is not the primary
    // the original tab is still in view (active tab has not been changed during this function)
    // and one of the following is true:
    // - it is user navigation
    // - user is in SLV
    // - or the action source is not reset
    // - there is no active compose when removing a row
    const activeTab = getActiveContentTab();
    if (
        activeTab != primaryTab &&
        originalActiveTab == activeTab &&
        (isUserNavigation ||
            isSingleLineView ||
            mailListItemSelectionSource !== MailListItemSelectionSource.Reset) &&
        !(
            mailListItemSelectionSource == MailListItemSelectionSource.RowRemoval &&
            getComposeViewState()
        )
    ) {
        lazyActivateTab.importAndExecute(primaryTab);
    }
    return promiseToReturn;
}

function loadConversationItems(
    rowKey: string,
    tableView: TableView,
    actionSource: LoadConversationItemActionSource
): Promise<void> {
    const rowId = MailRowDataPropertyGetter.getRowClientItemId(rowKey, tableView);
    // If has valid conversation cache, no need to load item parts
    if (isValidConversationCache(rowId.Id)) {
        return Promise.resolve();
    }
    return lazyLoadConversation.importAndExecute(rowId, actionSource);
}

const logSearchResultSelectedDatapoint = (rowKey: string, tableView: TableView) => {
    const parentFolderId = MailRowDataPropertyGetter.getParentFolderId(rowKey, tableView);

    /**
     * Check if we know the parent folder ID of the selected item,
     * because it's necessary for data logging. In the case of selecting
     * an email suggestion from search results, we DO NOT have this
     * information, so we can't log the search result selection.
     */
    if (!parentFolderId) {
        return;
    }

    /**
     * Log datapoint that tells us about the search result that
     * was clicked.
     */
    const buckets = [10, 25, 75, 150, 250];
    logUsage('SearchResultSelected', [
        MailRowDataPropertyGetter.getExecuteSearchSortOrder(rowKey, tableView) === 'Relevance',
        folderIdToName(parentFolderId),
        getAggregationBucket({
            value: tableView.rowKeys.indexOf(rowKey),
            buckets,
        }),
        getAggregationBucket({
            value: tableView.rowKeys.length,
            buckets,
        }),
        SearchScenarioId.Mail,
    ]);
};

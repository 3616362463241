import type ReadingPaneInfoBarId from './ReadingPaneInfoBarId';
import { isFeatureEnabled } from 'owa-feature-flags';

export default function initializeInfoBarIdsForItem(): ReadingPaneInfoBarId[] {
    const infoBarItems: ReadingPaneInfoBarId[] = [
        'unsubscribeInfoBar',
        'safetyBar',
        'infoImportance',
        'infoSensitivity',
        'infoFlagStatus',
        'irmInfoBar',
        'infoMessageSubmitted',
        'agendaMailFeedback',
        'unauthenticatedSender',
        'infoDelegate',
        'infoMeetingAttendeeDoNotForward',
        'infoSMIME',
        'infoSmimeDecodeError',
        'infoSmimeSigned',
        'infoItemPartLoadFailure',
        'encryptedMessageInfo',
        'infoLastUserAction',
        'infoReadReceiptRequested',
        'translateInfoBar',
        'infoResend',
        'infoPolicyTag',
        'infoMessageApprovalAndVoting',
        'infoMessageClassification',
        'infoCLPLabel',
        'infoRevocation',
        'externalMessage',
        'latestRecallAttempt',
    ];
    isFeatureEnabled('cmp-schedule-send-improvement') && infoBarItems.push('scheduleSendMessage');
    return infoBarItems;
}

import { LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailOrganizationViewTypesSettings"*/ './lazyIndex')
);

export const lazyInitializeMailOrganizationViewTypes = new LazyAction(
    lazyModule,
    m => m.initializeMailOrganizationViewTypes
);

import { getStore } from '../store/Store';
import { updateClipboardWritable } from '../mutator/updateClipboardWritable';
import {
    checkClipboardPermissions,
    checkSafariAndFirefoxClipboardPermissions,
} from './checkClipboardPermissions';
import { logGreyError } from 'owa-analytics';

const isClipboardWritable = () => {
    if (
        checkSafariAndFirefoxClipboardPermissions(updateClipboardWritable) &&
        getStore().clipboardWritable === false
    ) {
        // VSO 324666
        // Ideally, this logic exists in checkClipboardPermissions but due to when checkClipboardPermissions is called,
        // the flight check happens too early. So, we are pulling that logic out and doing this check when the user takes a copy/paste action.
        // This is a temporary solution, and we should move this logic back to checkClipboardPermissions once the flight is enabled for all users.

        // Firefox and Safari do not support the clipboard-read or clipboard-write permissions,
        // but access to the functionality is allowed with transient activation
        return true;
    }
    return getStore().clipboardWritable;
};

(() => {
    checkClipboardPermissions('clipboard-write' as PermissionName, updateClipboardWritable, e =>
        logGreyError('CnexErrorIsClipboardWritable', e)
    );
})();

export default isClipboardWritable;

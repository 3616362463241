import { hasUndoableAction } from 'owa-mail-undo';

import {
    ARCHIVE_DELETED_ITEMS_DISTINGUISHED_ID,
    ARCHIVE_DUMPSTER_DISTINGUISHED_ID,
    PRIMARY_DELETED_ITEMS_DISTINGUISHED_ID,
    PRIMARY_DUMPSTER_DISTINGUISHED_ID,
} from 'owa-folders-constants';
import {
    type ItemPartSelectionRestrictionType,
    type ListRestrictionType,
    type ListViewTypeRestrictionType,
    type ReadingPaneRestrictionType,
    type SelectionRestrictionType,
} from '../components/Behaviors.types';
import {
    MailRowDataPropertyGetter,
    MailSortHelper,
    type TableQueryType,
    getSelectedItemParts,
    getSelectedTableView,
    isItemPartOperation,
} from 'owa-mail-list-store';
import folderStore, { isFolderInMailboxType } from 'owa-folders';
import {
    getStore as getNotesStore,
    lazyHasEmailId as selectedNoteHasEmailId,
} from 'owa-notes-store';
import {
    isGroupTableQuery,
    lazyGetRetentionPolicyTagListForGroupTableQuery,
} from 'owa-group-utils';
import mailStore from 'owa-mail-store/lib/store/Store';
import {
    getUserPermissionForFolderId as mailStoreGetUserPermissionForFolderId,
    getUserPermissionForFolderIdWithErr as mailStoreGetUserPermissionForFolderIdWithErr,
} from 'owa-mail-store/lib/utils/getUserPermissionForFolderId';
import type FocusedViewFilter from 'owa-service/lib/contract/FocusedViewFilter';
import type { MailFolderTableQuery } from 'owa-mail-list-store';
import type { MailboxInfo } from 'owa-client-types';
import { type MenuItemType } from 'owa-filterable-menu/lib/components/MenuItemType';
import type Message from 'owa-service/lib/contract/Message';
import type { Permission } from 'owa-graph-schema';
import { SearchScopeKind } from 'owa-search-service/lib/data/schema/SearchScope';
import type { ShouldShowBehavior } from 'owa-filterable-menu';
import checkItemReplyForwardDisabled from 'owa-mail-reading-pane-item-actions/lib/utils/checkItemReplyForwardDisabled';
import { customRestriction } from '../components/behaviors/customRestriction';
import { focusedViewFilterRestriction } from '../components/behaviors/focusedViewFilterRestriction';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import folderNameToId from 'owa-session-store/lib/utils/folderNameToId';
import { folderRestriction } from '../components/behaviors/folderRestriction';
import { getAccountScopeUserSettings } from 'owa-session-store';
import getFolderData from 'owa-mail-actions/lib/getFolderData';
import getFolderIdFromTableView from 'owa-mail-folder-common/lib/utils/getFolderIdFromTableView';
import getItemForMailList from 'owa-mail-store/lib/selectors/getItemForMailList';
import getMailMenuItemShouldDisable from './getMailMenuItemShouldDisable';
import { getMailMenuItemShouldHideForMailbox } from './getMailMenuItemShouldHideForMailbox';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';
import getTableConversationRelation from 'owa-mail-list-store/lib/utils/getTableConversationRelation';
import { isBulkActionRunning } from 'owa-bulk-action-store';
import { isCapabilitySupported, isCapabilityEnabled } from 'owa-capabilities';
import { isFeatureEnabled } from 'owa-feature-flags';
import isFindRelatedConversationEnabled from '../utils/isFindRelatedConversationEnabled';
import { isFirstLevelExpanded } from 'owa-mail-list-store/lib/selectors/isConversationExpanded';
import { isNativeReportingEnabled } from 'owa-reporting';
import { isTimePanelWithToDoFeaturesAvailable } from 'owa-time-panel-bootstrap';
import isTranslatorEnabled from 'owa-addins-boot/lib/utils/isTranslatorEnabled';
import { itemPartSelectionRestrictionType } from '../components/behaviors/itemPartSelectionRestrictionType';
import { lazyIsMeetNowEnabled } from 'owa-meet-now';
import { learningToolsCapability } from 'owa-capabilities-definitions/lib/learningToolsCapability';
import { listRestrictionType } from '../components/behaviors/listRestrictionType';
import { listViewTypeRestrictionType } from '../components/behaviors/listViewTypeRestrictionType';
import { mailScenarioRestriction } from '../components/behaviors/mailScenarioRestriction';
import { readingPaneRestrictionType } from '../components/behaviors/readingPaneRestrictionType';
import { searchScopeRestriction } from '../components/behaviors/searchScopeRestriction';
import { default as selectionRestriction } from '../components/behaviors/selectionRestriction';
import shouldAllowDelete from '../utils/shouldAllowDelete';
import {
    shouldShowReadingPane,
    isReadingPanePositionOff,
    shouldShowListView,
} from 'owa-mail-layout';
import { shouldShowMenuItem } from '../components/Behaviors';
import shouldShowUnstackedReadingPane from 'owa-mail-store/lib/utils/shouldShowUnstackedReadingPane';
import { sortByRestriction } from '../components/behaviors/sortByRestriction';
import { staticFolderSearchScopeRestriction } from '../components/behaviors/staticFolderSearchScopeRestriction';
import { tableQueryTypeRestriction } from '../components/behaviors/tableQueryTypeRestriction';
import { translatorCapability } from 'owa-capabilities-definitions/lib/translatorCapability';
import isSMIMEItem from 'owa-smime/lib/utils/isSMIMEItem';
import isMessageRecallEnabled from 'owa-cloud-message-recall/lib/utils/isMessageRecallEnabled';
import type DistinguishedFolderIdName from 'owa-service/lib/contract/DistinguishedFolderIdName';
import { isCopilotFeatureEnabled } from 'owa-copilot-settings-utils';
import { owaComputedFn } from 'owa-computed-fn';
import isFileActivation from 'owa-mail-file-support/lib/utils/isFileActivation';
import { getComposeViewState } from 'owa-mail-reading-pane-store-conversation/lib/utils/getComposeViewState';
import isSenderSelf from 'owa-mail-store/lib/selectors/isSenderSelf';
import getCombinedAccessIssue from 'owa-attachment-policy-access-issue-checker/lib/utils/getCombinedAccessIssue';
import type AccessIssue from 'owa-attachment-policy-access-issue-checker/lib/schema/AccessIssue';
import { inboxRulesCapability } from 'owa-capabilities-definitions/lib/inboxRulesCapability';
import { viewMessageDetailsCapability } from 'owa-capabilities-definitions/lib/viewMessageDetailsCapability';
import {
    getReadHostItemIndex,
    getMultiSelectHostItemIndex,
} from 'owa-addins-store/lib/utils/hostItemIndexUtils';
import { getAdapter } from 'owa-addins-core';
import getExtensibilityState from 'owa-addins-store/lib/store/getExtensibilityState';
import { hasUserSelectedThirdPartyMeetingProvider } from 'owa-thirdparty-im-integration/lib/utils/hasUserSelectedThirdPartyMeetingProvider';

let shouldShowBehaviorMap: {
    [id: number]: ShouldShowBehavior;
};
const MEETING_ITEM_CLASS = 'IPM.Schedule.Meeting';

/**
 * Returns a mail filterable menu item to its ShouldShowBehavior
 */
export default function getMailMenuItemShouldShow(
    item: MenuItemType,
    mailboxInfo: MailboxInfo
): boolean {
    if (!shouldShowBehaviorMap) {
        initializeMap();
    }

    return getComputedMailMenuItemShouldShow(item, mailboxInfo);
}

const getComputedMailMenuItemShouldShow = owaComputedFn(function getComputedMailMenuItemShouldShow(
    item: MenuItemType,
    mailboxInfo: MailboxInfo
): boolean {
    return (
        !getMailMenuItemShouldHideForMailbox(item, mailboxInfo) &&
        (!shouldShowBehaviorMap[item] || shouldShowBehaviorMap[item]())
    ); // if menu item isn't defined in map, default it to always show
});

/**
 * Initialize the ShouldShowBehaviors for mail menu items
 */
function initializeMap() {
    const inboxFolderId = 'inbox';
    const dumpsterFolderIds: string[] = [
        PRIMARY_DUMPSTER_DISTINGUISHED_ID,
        ARCHIVE_DUMPSTER_DISTINGUISHED_ID,
    ];
    const deletedFolderIds: string[] = [
        PRIMARY_DELETED_ITEMS_DISTINGUISHED_ID,
        ARCHIVE_DELETED_ITEMS_DISTINGUISHED_ID,
    ];
    const sentItemsFolderId = 'sentitems';
    const draftsFolderId = 'drafts';
    const deletedItemsFolderId = 'deleteditems';
    const junkFolderId = 'junkemail';
    const scheduledFolderId = 'scheduled';
    const clutterFolderId = 'clutter';
    const archiveFolderId = 'archive';
    const notesFolderId = 'notes';
    const outboxFolderId: DistinguishedFolderIdName = 'outbox';

    // Folders where pin is not supported
    const restrictedFoldersForPin = [
        draftsFolderId,
        clutterFolderId,
        junkFolderId,
        deletedItemsFolderId,
        archiveFolderId,
        notesFolderId,
        ...dumpsterFolderIds,
        outboxFolderId,
    ];

    shouldShowBehaviorMap = {
        // If the item is explicitly known, never disable
        [-1]: shouldShowMenuItem([
            customRestriction(() => {
                return true;
            }),
        ]),

        // Show the menu item if list view has single selection
        [69]: shouldShowMenuItem([selectionRestriction([2])]),

        // Show the menu item if list view has ANY selection
        [2]: shouldShowMenuItem([selectionRestriction([0])]),

        // Show overflow divider if
        // - in single selection
        // - not in recoverable items view
        [38]: shouldShowMenuItem([
            folderRestriction([outboxFolderId, notesFolderId, ...dumpsterFolderIds], true),
            itemPartSelectionRestrictionType(4),
            selectionRestriction([2]),
            staticFolderSearchScopeRestriction([notesFolderId, ...dumpsterFolderIds], true),
        ]),

        // Show reply / reply all / forward in command bar if
        // - not in recoverable items view or drafts or junk folder
        // - there's a single selection
        // - it's not a group
        // - in message view
        [43]: shouldShowMenuItem([
            folderRestriction(
                [outboxFolderId, draftsFolderId, junkFolderId, notesFolderId, ...dumpsterFolderIds],
                true
            ),
            listViewTypeRestrictionType(2),
            customRestriction(() => !isMultipleItemPartSelection() && selectionRestriction([2])()),
            staticFolderSearchScopeRestriction([notesFolderId, ...dumpsterFolderIds], true),
        ]),

        // Show reply if
        // - not in recoverable items view
        // - there's a single selection
        // - it's not a group
        // - in message view
        // - allowed by IRMRestrictions, S/MIME etc. - checkItemReplyForwardDisabled
        [41]: shouldShowMenuItem([
            folderRestriction(
                [outboxFolderId, draftsFolderId, notesFolderId, ...dumpsterFolderIds],
                true
            ),
            customRestriction(() => !isMultipleItemPartSelection() && selectionRestriction([2])()),
            staticFolderSearchScopeRestriction([notesFolderId, ...dumpsterFolderIds], true),
            listViewTypeRestrictionType(2),
            customRestriction(() => {
                return (
                    listViewTypeRestrictionType(2)() &&
                    checkItemReplyReplyAllForwardRestrictions(false /* isConversationItemPart */)[0]
                    /* first value in returned array is isReplyAllowed */
                );
            }),
        ]),

        // Enable reply with meeting if
        // - not in recoverable items view
        // - there's a single selection
        // - it's not a group
        // - it's not a draft item
        // Note: IRM does not block 'reply with meeting'
        [44]: shouldShowMenuItem([
            folderRestriction(
                [outboxFolderId, draftsFolderId, notesFolderId, junkFolderId, ...dumpsterFolderIds],
                true
            ),
            customRestriction(
                () =>
                    !isMultipleItemPartSelection() && selectionRestriction([2])() && !isDraftItem()
            ),
            staticFolderSearchScopeRestriction([notesFolderId, ...dumpsterFolderIds], true),
        ]),

        // Enable copilot reply with meeting if
        // - not in recoverable items view
        // - there's a single selection
        // - it's not a draft item
        // Note: IRM does not block 'reply with meeting'
        // For Copilot schedule from mail, we will disable the button if the email is SMIME or has IRM restrictions
        // This will be consistent with the behavior of the message actions in the reading pane
        [87]: shouldShowMenuItem([
            folderRestriction(
                [outboxFolderId, draftsFolderId, notesFolderId, junkFolderId, ...dumpsterFolderIds],
                true
            ),
            customRestriction(
                () =>
                    !isMultipleItemPartSelection() &&
                    selectionRestriction([2])() &&
                    !isSMIMEOrIRM() &&
                    !isDraftItem() &&
                    !isMeetingItem() &&
                    !!isCopilotFeatureEnabled(
                        'ReplyByMeeting',
                        getMailboxInfo(getSelectedTableView())
                    )
            ),
            staticFolderSearchScopeRestriction([notesFolderId, ...dumpsterFolderIds], true),
        ]),

        [95]: shouldShowMenuItem([
            selectionRestriction([2]),
            searchScopeRestriction(
                [SearchScopeKind.ArchiveMailbox, SearchScopeKind.SharedFolders],
                true
            ),
            customRestriction(() => {
                const mailboxInfo = getMailboxInfo(getSelectedTableView());
                return (
                    !!isCopilotFeatureEnabled('Inbox', mailboxInfo, true /* skipLanguageCheck */) &&
                    isFeatureEnabled('mon-copilot-prioritize-context-menu', mailboxInfo)
                );
            }),
        ]),

        // Enable ribbon reply if
        // - not in recoverable items view
        // - there's a single selection
        // - it's not a group
        // - not under compose mode
        // - allowed by IRMRestrictions, S/MIME etc. - checkItemReplyForwardDisabled
        [60]: shouldShowMenuItem([
            folderRestriction(
                [outboxFolderId, draftsFolderId, notesFolderId, ...dumpsterFolderIds],
                true
            ),
            customRestriction(baseReplyReplyAllForwardRestrictions),
            staticFolderSearchScopeRestriction([notesFolderId, ...dumpsterFolderIds], true),
            customRestriction(() => {
                return checkItemReplyReplyAllForwardRestrictions(
                    listViewTypeRestrictionType(1)() /* isConversationItemPart */
                )[0];
                /* first value in returned array is isReplyAllowed */
            }),
        ]),

        // Show reply all if
        // - not in recoverable items view
        // - there's a single selection
        // - in message view
        // - allowed by IRMRestrictions, S/MIME etc. - checkItemReplyForwardDisabled
        [42]: shouldShowMenuItem([
            folderRestriction(
                [outboxFolderId, draftsFolderId, notesFolderId, ...dumpsterFolderIds],
                true
            ),
            customRestriction(() => !isMultipleItemPartSelection() && selectionRestriction([2])()),
            staticFolderSearchScopeRestriction([notesFolderId, ...dumpsterFolderIds], true),
            listViewTypeRestrictionType(2),
            customRestriction(() => {
                return (
                    listViewTypeRestrictionType(2)() &&
                    checkItemReplyReplyAllForwardRestrictions(false /* isConversationItemPart */)[1]
                    /* second value in returned array is isReplyAllAllowed */
                );
            }),
        ]),

        // Enable ribbon reply all if
        // - not in recoverable items view
        // - there's a single selection
        // - not under compose mode
        // - allowed by IRMRestrictions, S/MIME etc. - checkItemReplyForwardDisabled
        [61]: shouldShowMenuItem([
            folderRestriction(
                [outboxFolderId, draftsFolderId, notesFolderId, ...dumpsterFolderIds],
                true
            ),
            customRestriction(baseReplyReplyAllForwardRestrictions),
            staticFolderSearchScopeRestriction([notesFolderId, ...dumpsterFolderIds], true),
            customRestriction(() => {
                return checkItemReplyReplyAllForwardRestrictions(
                    listViewTypeRestrictionType(1)() /* isConversationItemPart */
                )[1];
                /* second value in returned array is isReplyAllAllowed */
            }),
        ]),

        // Show forward if
        // - not in recoverable items view
        // - there's a single selection
        // - in message view
        // - allowed by IRMRestrictions, S/MIME etc. - checkItemReplyForwardDisabled
        [19]: shouldShowMenuItem([
            folderRestriction([outboxFolderId, notesFolderId, ...dumpsterFolderIds], true),
            customRestriction(() => !isMultipleItemPartSelection() && selectionRestriction([2])()),
            staticFolderSearchScopeRestriction([notesFolderId, ...dumpsterFolderIds], true),
            listViewTypeRestrictionType(2),
            customRestriction(() => {
                return (
                    listViewTypeRestrictionType(2)() &&
                    checkItemReplyReplyAllForwardRestrictions(false /* isConversationItemPart */)[2]
                    /* third value in returned array is isForwardAllowed */
                );
            }),
        ]),

        // Enable ribbon forward if
        // - not in recoverable items view
        // - there's a single selection
        // - not under compose mode
        // - allowed by IRMRestrictions, S/MIME etc. - checkItemReplyForwardDisabled
        [52]: shouldShowMenuItem([
            folderRestriction([outboxFolderId, notesFolderId, ...dumpsterFolderIds], true),
            customRestriction(baseReplyReplyAllForwardRestrictions),
            staticFolderSearchScopeRestriction([notesFolderId, ...dumpsterFolderIds], true),
            customRestriction(() => {
                return checkItemReplyReplyAllForwardRestrictions(
                    listViewTypeRestrictionType(1)() /* isConversationItemPart */
                )[2];
                /* third value in returned array is isForwardAllowed */
            }),
        ]),

        // Enable ribbon share (to Teams, OneNote, etc.) if
        // - not in recoverable items view
        // - there's a single selection
        // - it's not a group
        // - forward is allowed by IRMRestrictions, S/MIME etc. - checkItemReplyForwardDisabled
        [62]: shouldShowMenuItem([
            folderRestriction([outboxFolderId, notesFolderId, ...dumpsterFolderIds], true),
            customRestriction(() => !isMultipleItemPartSelection() && selectionRestriction([2])()),
            staticFolderSearchScopeRestriction([notesFolderId, ...dumpsterFolderIds], true),
            customRestriction(() => {
                return checkItemReplyReplyAllForwardRestrictions(
                    listViewTypeRestrictionType(1)() /* isConversationItemPart */
                )[2];
                /* third value in returned array is isForwardAllowed */
            }),
        ]),

        // Show schedule menu item if
        // - user is in inbox or scheduled
        // - user is not in archive mbx, group mbx, shadow mbx, shared folders scenarios
        // - there's a mail list item selection
        [66]: shouldShowMenuItem([
            customRestriction(() => {
                const customCondition =
                    selectionRestriction([1])() &&
                    (itemPartSelectionRestrictionType(4)() || shouldShowUnstackedReadingPane());

                if (getSelectedTableView().tableQuery.type === 1) {
                    return (
                        isSingleSelectedRowSnoozable([inboxFolderId, scheduledFolderId]) &&
                        customCondition
                    );
                } else {
                    return (
                        folderRestriction([inboxFolderId, scheduledFolderId])() &&
                        staticFolderSearchScopeRestriction([])() &&
                        customCondition
                    );
                }
            }),
        ]),

        // Show new message in right pane command bar or in mail view
        // - user is not in dumpster folder (or search)
        // - when folder pane is collapsed
        // - when in condensed commandbar view
        [33]: shouldShowMenuItem([
            folderRestriction([...dumpsterFolderIds, notesFolderId], true),
            staticFolderSearchScopeRestriction(dumpsterFolderIds, true),
        ]),

        // Show Ribbon's New Message is similar to the Toolbar, except we need to explicitly
        // call for getMailMenuItemShouldDisable() and only show if we aren't disabled.
        [56]: shouldShowMenuItem([
            folderRestriction([...dumpsterFolderIds, notesFolderId], true),
            staticFolderSearchScopeRestriction(dumpsterFolderIds, true),
            customRestriction(() => {
                return !getMailMenuItemShouldDisable(33);
            }),
        ]),

        // Flight check for this is in MailCommandingBar
        [35]: shouldShowMenuItem([
            customRestriction(() => {
                return true;
            }),
        ]),

        // Show new note in right pane command bar
        // - when folder pane is collapsed and we're in the notes folder
        // or if the closer command bar is enabled (with densities and collapsible folder pane)
        [34]: shouldShowMenuItem([folderRestriction([notesFolderId])]),

        // Show New Note Hero Button in Ribbon
        // - when we're in the notes folder
        // - and feature 'notes-folder-view' is enabled
        [57]: shouldShowMenuItem([
            folderRestriction([notesFolderId]),
            customRestriction(() => {
                return isFeatureEnabled('notes-folder-view');
            }),
        ]),

        // Show note actions (delete, change color) in right pane command bar
        // - We're in the notes folder
        // - there is a selected note
        [36]: shouldShowMenuItem([
            folderRestriction([notesFolderId]),
            customRestriction(() => {
                return !!getNotesStore().selectedNoteId;
            }),
        ]),

        // Show view email from note in right pane command bar
        // - We're in the notes folder
        // - there is a selected note
        [77]: shouldShowMenuItem([
            folderRestriction([notesFolderId]),
            customRestriction(() => {
                return !!selectedNoteHasEmailId.tryImportForRender()?.('NotesFolder');
            }),
        ]),

        // Show mark as read if
        // - not in recoverable items view
        // - list view has multiple selections
        // - or list view has single selection and selected row is unread
        [26]: shouldShowMenuItem([
            folderRestriction(
                [outboxFolderId, notesFolderId, ...dumpsterFolderIds, draftsFolderId],
                true
            ),
            staticFolderSearchScopeRestriction([notesFolderId, ...dumpsterFolderIds], true),
            customRestriction(() => {
                return (
                    // Show mark as read if
                    // 1) Any of the selected itemparts is unread
                    // 2) List view has single selection AND row is unread AND we are not acting on item parts
                    // 3) At least one selected item part is unread AND we are acting on item parts
                    isAnySelectedItemPartUnread() ||
                    (selectionRestriction([2])() && !isSingleSelectedRowRead()) ||
                    selectionRestriction([3])()
                );
            }),
        ]),

        // Show mark as unread if not in recoverable items and list view has multiple selections
        // or list view has single selection and selected row is read
        [27]: shouldShowMenuItem([
            folderRestriction(
                [outboxFolderId, notesFolderId, ...dumpsterFolderIds, draftsFolderId],
                true
            ),
            staticFolderSearchScopeRestriction([notesFolderId, ...dumpsterFolderIds], true),
            customRestriction(() => {
                return (
                    // Show mark as unread if
                    // 1) Any of the selected itemparts is read
                    // 2) List view has single selection AND row is fully read (or some of its itemparts are read)
                    // AND we are not acting on item parts
                    // 3) At least one selected item part is read AND we are acting on item parts
                    isAnySelectedItemPartRead() ||
                    (selectionRestriction([2])() && !isSingleSelectedRowFullyUnread()) ||
                    selectionRestriction([3])()
                );
            }),
        ]),

        // Enable the ribbon Read/Unread toggle if:
        // - not in recoverable items view
        // - list view has any items
        // - Bulk Action is not running for this folder id
        [59]: shouldShowMenuItem([
            folderRestriction([outboxFolderId, notesFolderId, ...dumpsterFolderIds], true),
            staticFolderSearchScopeRestriction([notesFolderId, ...dumpsterFolderIds], true),
            listRestrictionType(1),
            customRestriction(() => {
                return !isBulkActionRunning(getSelectedTableView().serverFolderId);
            }),
        ]),

        // Show meet now in right pane command bar
        // - user is not in dumpster folder (or search)
        // - when folder pane is collapsed
        [28]: shouldShowMenuItem([
            folderRestriction(dumpsterFolderIds, true),
            staticFolderSearchScopeRestriction(dumpsterFolderIds, true),
            customRestriction(() => {
                return !!lazyIsMeetNowEnabled.tryImportForRender()?.();
            }),
        ]),

        // Show flag if
        // 1) List view has single selection AND row is un-flagged AND we are not acting on item parts
        // 2) List view has multiple selection
        // 3) At least one selected item part is un-flagged AND we are acting on item parts
        // 4) It is shared folder and user has edit permission for shared folder
        // Hide if
        // 1) Group mbx
        // 2) Notes folder and dumpster folder
        [18]: shouldShowMenuItem([
            folderRestriction([outboxFolderId, notesFolderId, ...dumpsterFolderIds], true),
            staticFolderSearchScopeRestriction([notesFolderId, ...dumpsterFolderIds], true),
            customRestriction(() => {
                return (
                    (isAnySelectedItemPartUnflagged() ||
                        (selectionRestriction([2])() && !isSingleSelectedRowFlagged()) ||
                        selectionRestriction([4])()) &&
                    doesUserHaveSharedFolderPermissionFor(18)
                );
            }),
        ]),

        // Show unflag if
        // - not in recoverable items view
        // - list view has multiple selections
        // - or list view has single selection and selected row is flagged
        [74]: shouldShowMenuItem([
            folderRestriction([outboxFolderId, notesFolderId, ...dumpsterFolderIds], true),
            staticFolderSearchScopeRestriction([notesFolderId, ...dumpsterFolderIds], true),
            customRestriction(() => {
                return (
                    // Show unflag if
                    // 1) List view has single selection AND row is flagged AND we are not acting on item parts
                    // 2) At least one selected item part is flagged AND we are acting on item parts
                    // 3) It is a shared folder and user has edit all permission
                    (isAnySelectedItemPartFlagged() ||
                        (selectionRestriction([2])() && isSingleSelectedRowFlagged()) ||
                        selectionRestriction([4])()) &&
                    doesUserHaveSharedFolderPermissionFor(74)
                );
            }),
        ]),

        // Show Ribbon's FlagUnflag if
        // 1) List view has multiple selection
        // 2) It is shared folder and user has edit permission for shared folder
        // Hide if
        // 1) Group mbx
        // 2) Notes folder and dumpster folder
        [51]: shouldShowMenuItem([
            selectionRestriction([0]),
            folderRestriction([outboxFolderId, notesFolderId, ...dumpsterFolderIds], true),
            staticFolderSearchScopeRestriction([notesFolderId, ...dumpsterFolderIds], true),
        ]),

        // Show categories if
        // - not in recoverable items view
        // - list view has selection
        // - not in groups
        // - user is in a shared folder and has edit all permission
        [6]: shouldShowMenuItem([
            folderRestriction([outboxFolderId, notesFolderId, ...dumpsterFolderIds], true),
            customRestriction(
                () => shouldShowUnstackedReadingPane() || itemPartSelectionRestrictionType(4)()
            ),
            selectionRestriction([1]),
            staticFolderSearchScopeRestriction([notesFolderId, ...dumpsterFolderIds], true),
            customRestriction(() => {
                return doesUserHaveSharedFolderPermissionFor(6);
            }),
        ]),

        // Show pin menu items
        // - in all folders except the ones in the restriction list
        // - and its not a archive mbx, group mbx
        // - (and list view has single selection and it's unpinned
        //- and when not in search
        // ----- or list view has multiple selections)
        [39]: shouldShowMenuItem([
            folderRestriction(restrictedFoldersForPin, true),
            customRestriction(
                () => shouldShowUnstackedReadingPane() || itemPartSelectionRestrictionType(4)()
            ),
            sortByRestriction([MailSortHelper.getSortBySupportingPin()]),
            searchScopeRestriction(
                [SearchScopeKind.ArchiveMailbox, SearchScopeKind.SharedFolders],
                true
            ),
            staticFolderSearchScopeRestriction([notesFolderId, ...dumpsterFolderIds], true),
            customRestriction(() => {
                // Shared condition for search and non-search scenario
                const customCondition =
                    selectionRestriction([4])() ||
                    (selectionRestriction([2])() && !isSingleSelectedRowPinned());

                /**
                 * If selected table is search, combine the custom condition
                 * with check to see if item belongs to a folder where pin
                 * is supported.
                 */
                if (getSelectedTableView().tableQuery.type === 1) {
                    return customCondition && isSingleSelectedRowPinnable(restrictedFoldersForPin);
                }

                return customCondition;
            }),
        ]),

        // Show unpin menu items
        // - in all folders except the ones in the restriction list
        // - and not in archive mbx or group mbx
        // - and list view has single selection and it's pinned
        // - and when not in search
        // ----- or list view has multiple selections
        [75]: shouldShowMenuItem([
            folderRestriction(restrictedFoldersForPin, true),
            customRestriction(
                () => shouldShowUnstackedReadingPane() || itemPartSelectionRestrictionType(4)()
            ),
            sortByRestriction([MailSortHelper.getSortBySupportingPin()]),
            searchScopeRestriction(
                [SearchScopeKind.ArchiveMailbox, SearchScopeKind.SharedFolders],
                true
            ),
            staticFolderSearchScopeRestriction([notesFolderId, ...dumpsterFolderIds], true),
            customRestriction(() => {
                // Shared condition for search and non-search scenario
                const customCondition =
                    selectionRestriction([4])() ||
                    (selectionRestriction([2])() && isSingleSelectedRowPinned());

                /**
                 * If selected table is search, combine the custom condition
                 * with check to see if item belongs to a folder where pin
                 * is supported.
                 */
                if (getSelectedTableView().tableQuery.type === 1) {
                    return customCondition && isSingleSelectedRowPinnable(restrictedFoldersForPin);
                }

                return customCondition;
            }),
        ]),

        // Enable ribbon pin/unpin toggle:
        // - in all folders except the ones in the restriction list
        // - and not in archive mbx or group mbx
        // - and when not in search
        [58]: shouldShowMenuItem([
            folderRestriction(restrictedFoldersForPin, true),
            customRestriction(
                () => shouldShowUnstackedReadingPane() || itemPartSelectionRestrictionType(4)()
            ),
            sortByRestriction([MailSortHelper.getSortBySupportingPin()]),
            searchScopeRestriction(
                [SearchScopeKind.ArchiveMailbox, SearchScopeKind.SharedFolders],
                true
            ),
            staticFolderSearchScopeRestriction([notesFolderId, ...dumpsterFolderIds], true),
            selectionRestriction([0]),
        ]),

        // Show CreateTask menu item if:
        // 1) List view has single selection
        // 2) We are not acting on item parts
        // 4) Time panel flights enabled
        // 5) Not Group mbx
        // 6) Not in Notes folder and dumpster folder
        [11]: shouldShowMenuItem([
            selectionRestriction([2]),
            itemPartSelectionRestrictionType(4),
            customRestriction(() => {
                const mailboxInfo = getMailboxInfo(getSelectedTableView());
                return isTimePanelWithToDoFeaturesAvailable(mailboxInfo);
            }),
            folderRestriction([outboxFolderId, notesFolderId, ...dumpsterFolderIds], true),
        ]),

        // Show delete
        // - when list view has selection
        // - If it is shared folder and folder has delete all permission
        [12]: shouldShowMenuItem([
            folderRestriction(['notes'], true),
            selectionRestriction([0]),
            customRestriction(() => {
                return shouldAllowDelete(getSelectedTableView());
            }),
        ]),

        // The Ribbon's delete show state is nearly the same as OWA Toolbar,
        // except that we show only if getMailMenuItemShouldDisable() is false.
        [50]: shouldShowMenuItem([shouldShowRibbon_Delete]),

        // Show archive
        // - in folders under msgfolderroot except archive, deleted items, drafts, junk folder, and recoverable items
        // - and when list view has selection
        // - or if list view has selection in search
        [3]: shouldShowMenuItem([
            folderRestriction(
                [
                    archiveFolderId,
                    deletedItemsFolderId,
                    draftsFolderId,
                    junkFolderId,
                    notesFolderId,
                    ...dumpsterFolderIds,
                    outboxFolderId,
                ],
                true
            ),
            tableQueryTypeRestriction([0, 1]),
            selectionRestriction([1]),
            searchScopeRestriction(
                [SearchScopeKind.ArchiveMailbox, SearchScopeKind.SharedFolders],
                true
            ),
            staticFolderSearchScopeRestriction([notesFolderId, ...dumpsterFolderIds], true),
        ]),

        // Show mark as junk
        // - when list view has selection
        // - and in folders under msgfolderroot except sent items, junk email, drafts, clutter, scheduled, and recoverable items
        // - or when list view has selection in search and the search refiner is not junkemail
        [24]: shouldShowMenuItem([
            folderRestriction(
                [
                    draftsFolderId,
                    sentItemsFolderId,
                    junkFolderId,
                    clutterFolderId,
                    scheduledFolderId,
                    notesFolderId,
                    ...dumpsterFolderIds,
                    outboxFolderId,
                ],
                true
            ),
            mailScenarioRestriction(['persona', 'category'], true),
            selectionRestriction([1]),
            searchScopeRestriction(
                [SearchScopeKind.ArchiveMailbox, SearchScopeKind.SharedFolders],
                true
            ),
            staticFolderSearchScopeRestriction([notesFolderId, ...dumpsterFolderIds], true),
            customRestriction(() => {
                const mailboxInfo: MailboxInfo = getMailboxInfo(getSelectedTableView());
                return (
                    !isSenderSelfInSelectedRow(mailboxInfo) &&
                    isNativeReportingEnabled(mailboxInfo) &&
                    !isDraftItem()
                );
            }),
        ]),

        // The Ribbon combines Junk and Not Junk. We show if either
        // MenuItemType.MarkAsJunk or MenuItemType.JunkEmailSafetyAction would show.
        [55]: shouldShowMenuItem([shouldShowRibbon_JunkNotJunk]),

        // Show mark as phishing
        // - when list view has selection
        // - and in folders under msgfolderroot except sent items, drafts, clutter, scheduled, and recoverable items
        [25]: shouldShowMenuItem([
            folderRestriction(
                [
                    draftsFolderId,
                    sentItemsFolderId,
                    clutterFolderId,
                    scheduledFolderId,
                    notesFolderId,
                    ...dumpsterFolderIds,
                    outboxFolderId,
                ],
                true
            ),
            mailScenarioRestriction(['persona', 'category'], true),
            selectionRestriction([1]),
            staticFolderSearchScopeRestriction([notesFolderId, ...dumpsterFolderIds], true),
            customRestriction(() => {
                const mailboxInfo: MailboxInfo = getMailboxInfo(getSelectedTableView());
                return (
                    !isSenderSelfInSelectedRow(mailboxInfo) &&
                    isNativeReportingEnabled(mailboxInfo) &&
                    !isDraftItem()
                );
            }),
        ]),

        // Show mark as not junk and other junk email safety actions
        // - in junk folder
        // - and when list view has selection
        // - or when list view has selection in search and the search refiner is junkemail
        // (But ALWAYS hide when in a Group/Shadow/Archive Mailbox)
        [22]: shouldShowMenuItem([
            folderRestriction([junkFolderId]),
            selectionRestriction([1]),
            searchScopeRestriction(
                [SearchScopeKind.ArchiveMailbox, SearchScopeKind.SharedFolders],
                true
            ),
            staticFolderSearchScopeRestriction([junkFolderId]),
            customRestriction(() => {
                return isNativeReportingEnabled(getMailboxInfo(getSelectedTableView()));
            }),
        ]),

        // Show ignore
        // - when list view has selection
        // - and not in Sent Items, drafts, deleted items, junk, or dumpster
        // (But ALWAYS hide when in a ShadowMailbox, Group mbx, Archive mbx)
        [20]: shouldShowMenuItem([
            folderRestriction(
                [
                    sentItemsFolderId,
                    draftsFolderId,
                    deletedItemsFolderId,
                    junkFolderId,
                    notesFolderId,
                    ...dumpsterFolderIds,
                    outboxFolderId,
                ],
                true
            ),
            tableQueryTypeRestriction([0, 1]),
            customRestriction(
                () => shouldShowUnstackedReadingPane() || itemPartSelectionRestrictionType(4)()
            ),
            searchScopeRestriction(
                [
                    SearchScopeKind.ArchiveMailbox,
                    SearchScopeKind.SharedFolders,
                    SearchScopeKind.Group,
                ],
                true
            ),
            selectionRestriction([1]),
            staticFolderSearchScopeRestriction(
                [
                    sentItemsFolderId,
                    draftsFolderId,
                    deletedItemsFolderId,
                    junkFolderId,
                    notesFolderId,
                    ...dumpsterFolderIds,
                ],
                true
            ),
        ]),

        // The Ribbon combines Ignore and Stop Ignore. We show if either
        // MenuItemType.Ignore or MenuItemType.StopIgnore would show.
        [54]: () => shouldShowRibbon_IgnoreStopIgnore(),

        // Show report abuse
        // - when list view has single selection
        [45]: shouldShowMenuItem([
            folderRestriction([outboxFolderId, notesFolderId, ...dumpsterFolderIds], true),
            selectionRestriction([2]),
            customRestriction(() => {
                return shouldShowReportAbuseForSender();
            }),
        ]),

        // Show stop ignoring
        // - when list view has selection
        // - only in deleted items
        // (But ALWAYS hide when in a ShadowMailbox, Group mbx, Archive mbx)
        [70]: shouldShowMenuItem([
            folderRestriction([deletedItemsFolderId]),
            tableQueryTypeRestriction([1], true),
            itemPartSelectionRestrictionType(4),
            selectionRestriction([1]),
        ]),

        // Show block sender
        // - not in search(because search doesn't return lastSender property)
        // - when list view has selection with a LastSender property on the item
        // - and in folders under msgfolderroot except sent items, junk email, drafts, clutter, scheduled and recoverable items
        // - and when list view has single selection and it has valid smtp address
        [5]: shouldShowMenuItem([
            folderRestriction(
                [
                    draftsFolderId,
                    sentItemsFolderId,
                    clutterFolderId,
                    scheduledFolderId,
                    notesFolderId,
                    ...dumpsterFolderIds,
                    outboxFolderId,
                ],
                true
            ),
            itemPartSelectionRestrictionType(4),
            customRestriction(() => {
                return (
                    (selectionRestriction([2])() && doesSingleSelectionHasSmtp()) ||
                    selectionRestriction([4])()
                );
            }),
        ]),

        // Show sweep
        // - in folders except sent, drafts, deleteditems, junk email, and recoverable items
        // - and the mbx is not archive, group, or shadow
        // - and when user is not in search mode
        // - and when user is not in a category folder
        // - and when list view has
        // ------ multiple selections,
        // ------ OR single selection and it has smtp address
        [71]: shouldShowMenuItem([
            folderRestriction(
                [
                    sentItemsFolderId,
                    draftsFolderId,
                    deletedItemsFolderId,
                    junkFolderId,
                    notesFolderId,
                    ...dumpsterFolderIds,
                    outboxFolderId,
                ],
                true
            ),
            itemPartSelectionRestrictionType(4),
            tableQueryTypeRestriction([1], true),
            mailScenarioRestriction(['category'], true),
            customRestriction(() => {
                return (
                    (selectionRestriction([2])() && doesSingleSelectionHasSmtp()) ||
                    selectionRestriction([4])()
                );
            }),
        ]),

        // The Ribbon's sweep show state is nearly the same as OWA Toolbar,
        // except that we show only if getMailMenuItemShouldDisable() is false.
        [63]: shouldShowMenuItem([
            shouldShowRibbon_Sweep,
            customRestriction(() => {
                const mailboxInfo = getMailboxInfo(getSelectedTableView());
                return !isSenderSelfInSelectedRow(mailboxInfo);
            }),
        ]),

        // Show empty folder menu item if
        // - list view has items and has no selection
        // - not in groups or shared
        // - if in search or favorite node where selected folder id is null
        // - not in notes folder
        // - OR
        // - when in Inbox and FocusedViewFilter is Other
        [13]: shouldShowMenuItem([
            [
                folderRestriction([inboxFolderId, notesFolderId], true),
                tableQueryTypeRestriction([0, 1]),
                listRestrictionType(1),
                selectionRestriction([5]),
                customRestriction((selectedFolderDistinguishedId?: string) => {
                    // This custom restriction is only valid for the folder table types
                    // In this particular case we check for the distinguished folder id.
                    const isDumpsterOrNotArchiveShadowMailboxFolder =
                        isMailFolderTableShown() &&
                        selectedFolderDistinguishedId &&
                        (dumpsterFolderIds.indexOf(selectedFolderDistinguishedId) > -1 ||
                            isNotArchiveInShadowMailbox(selectedFolderDistinguishedId));
                    return !!(
                        isDumpsterOrNotArchiveShadowMailboxFolder ||
                        doesUserHaveSharedFolderPermissionFor(12)
                    );
                }),
            ],
            [
                folderRestriction([inboxFolderId], false),
                focusedViewFilterRestriction([1]),
                listRestrictionType(1),
                selectionRestriction([5]),
            ],
        ]),

        // Show mark all as read menu item
        // - not in recoverable items view
        // - and list view has unread item
        // - and list view has no selection
        [23]: shouldShowMenuItem([
            folderRestriction([outboxFolderId, notesFolderId, ...dumpsterFolderIds], true),
            listRestrictionType(1),
            selectionRestriction([5]),
            staticFolderSearchScopeRestriction([notesFolderId, ...dumpsterFolderIds], true),
        ]),

        // Show restore if
        // - list view has selections
        // - and in deleteditems or dumpster folder
        // - and in search when the search scope is deleteditems for dumpster folder
        [46]: shouldShowMenuItem([
            selectionRestriction([1]),
            folderRestriction([...deletedFolderIds, ...dumpsterFolderIds]),
            itemPartSelectionRestrictionType(4),
            staticFolderSearchScopeRestriction([...dumpsterFolderIds, ...deletedFolderIds]),
            customRestriction(() => {
                const { tableQuery } = getSelectedTableView();
                return isGroupTableQuery(tableQuery) ? isFeatureEnabled('grp-loadFolders') : true;
            }),
        ]),

        // Show restore all if
        // - list view has all selected
        // - and in deleteditems or dumpster folder
        // - and in search when the search scope is deleteditems for dumpster folder
        [47]: shouldShowMenuItem([
            selectionRestriction([6]),
            folderRestriction([...deletedFolderIds, ...dumpsterFolderIds]),
            listRestrictionType(1),
            itemPartSelectionRestrictionType(4),
            staticFolderSearchScopeRestriction([...dumpsterFolderIds, ...deletedFolderIds]),
        ]),

        // Show restore buttons in the ribbon
        // - and in deleteditems or dumpster folder
        // - and in search when the search scope is deleteditems for dumpster folder
        [81]: shouldShowMenuItem([
            folderRestriction([...deletedFolderIds, ...dumpsterFolderIds]),
            staticFolderSearchScopeRestriction([...dumpsterFolderIds, ...deletedFolderIds]),
        ]),

        // Show undo if
        // - not in recoverable items view
        // - not in archive mbx, group mbx
        // - In folder list view
        // - When list pane is showing
        // - list view has items OR undo stack is not empty
        [73]: shouldShowMenuItem([
            folderRestriction([outboxFolderId, ...dumpsterFolderIds, notesFolderId], true),
            tableQueryTypeRestriction([0]),
            customRestriction(() => {
                return shouldShowListView() && (hasUndoableAction() || listRestrictionType(1)());
            }),
        ]),

        // Enable ribbon undo if
        // - not in recoverable items view
        // - not in archive mbx, group mbx
        // - In folder list view
        // - undo stack is not empty
        [64]: shouldShowMenuItem([
            folderRestriction([outboxFolderId, ...dumpsterFolderIds, notesFolderId], true),
            tableQueryTypeRestriction([0]),
            customRestriction(() => {
                return hasUndoableAction();
            }),
        ]),

        // Show move to if
        // - Not in draft folder or recoverable items view
        // - Not in groups mbx and grp-loadFolders is disabled
        // - list view has selection
        // - Shared folder has delete all permission
        [29]: shouldShowMenuItem([
            folderRestriction(
                [outboxFolderId, draftsFolderId, notesFolderId, ...dumpsterFolderIds],
                true
            ),
            selectionRestriction([1]),
            staticFolderSearchScopeRestriction([notesFolderId, ...dumpsterFolderIds], true),
            customRestriction(() => {
                const { tableQuery } = getSelectedTableView();
                const isGroup = isGroupTableQuery(tableQuery);

                if (isGroup) {
                    return (
                        isFeatureEnabled('grp-loadFolders') &&
                        doesUserHaveSharedFolderPermissionFor(29)
                    );
                } else {
                    return doesUserHaveSharedFolderPermissionFor(29);
                }
            }),
        ]),

        // MenuItemType.MoveMessage is introduced so that move message action functionality
        // can be disabled when MenuItemType.Move option is disabled in the UI
        [30]: shouldShowMenuItem([shouldAllowMoveMessage]),

        // Show copy to if
        // - Not in draft folder or recoverable items view
        // - Not in groups mbx and grp-loadFolders is disabled
        // - list view has selection
        // - Shared folder has delete all permission
        [9]: shouldShowMenuItem([
            folderRestriction(
                [outboxFolderId, draftsFolderId, notesFolderId, ...dumpsterFolderIds],
                true
            ),
            selectionRestriction([1]),
            staticFolderSearchScopeRestriction([notesFolderId, ...dumpsterFolderIds], true),
            customRestriction(() => {
                const { tableQuery } = getSelectedTableView();
                const isGroup = isGroupTableQuery(tableQuery);

                if (isGroup) {
                    return (
                        isFeatureEnabled('grp-loadFolders') &&
                        doesUserHaveSharedFolderPermissionFor(9)
                    );
                } else {
                    return doesUserHaveSharedFolderPermissionFor(9);
                }
            }),
        ]),

        // Show inbox rule if
        // - not in sent items or drafts folders (until 'sent to' rule field is supported) or recoverable items view, and not a group
        // - and list view has single selection
        [21]: shouldShowMenuItem([
            folderRestriction(
                [
                    outboxFolderId,
                    sentItemsFolderId,
                    draftsFolderId,
                    notesFolderId,
                    ...dumpsterFolderIds,
                ],
                true
            ),
            itemPartSelectionRestrictionType(4),
            searchScopeRestriction(
                [SearchScopeKind.ArchiveMailbox, SearchScopeKind.SharedFolders],
                true
            ),
            selectionRestriction([2]),
            staticFolderSearchScopeRestriction(
                [sentItemsFolderId, notesFolderId, draftsFolderId],
                true
            ),
            customRestriction(() => {
                const mailboxInfo = getMailboxInfo(getSelectedTableView());
                return (
                    isCapabilityEnabled(inboxRulesCapability, mailboxInfo) &&
                    doesSingleSelectionHasSmtp()
                );
            }),
        ]),

        // Show move to Focused inbox menu item
        // - when is in Other inbox
        // - and list view has items
        // - and list view has selection
        // - and when not in search
        [31]: shouldShowMenuItem([
            folderRestriction([inboxFolderId]),
            focusedViewFilterRestriction([1]),
            listRestrictionType(1),
            itemPartSelectionRestrictionType(4),
            tableQueryTypeRestriction([1], true),
            selectionRestriction([1]),
        ]),

        // Show move to Other inbox menu item
        // - when in Focused inbox
        // - and list view has items
        // - and list view has selection
        // - and when not in search
        [32]: shouldShowMenuItem([
            folderRestriction([inboxFolderId]),
            focusedViewFilterRestriction([0]),
            itemPartSelectionRestrictionType(4),
            listRestrictionType(1),
            tableQueryTypeRestriction([1], true),
            selectionRestriction([1]),
        ]),

        // Show always move to Focused inbox menu item
        // - when not in search
        // - list view has items
        // - and list view has single selection
        // - is in Other inbox
        [0]: shouldShowMenuItem([
            folderRestriction([inboxFolderId]),
            focusedViewFilterRestriction([1]),
            itemPartSelectionRestrictionType(4),
            listRestrictionType(1),
            tableQueryTypeRestriction([1], true),
            selectionRestriction([2]),
        ]),

        // Show always move to Other inbox menu item
        // - is in Focused inbox
        // - and list view has items
        // - and list view has single selection
        // - and when not in search
        [1]: shouldShowMenuItem([
            folderRestriction([inboxFolderId]),
            focusedViewFilterRestriction([0]),
            itemPartSelectionRestrictionType(4),
            listRestrictionType(1),
            tableQueryTypeRestriction([1], true),
            selectionRestriction([2]),
        ]),

        // Show Print menu
        // - in all folders except draft folder and recoverable items view
        // - and list view has single selection
        // - in 'Notes' folder unless 'notes-folder-view' flight is enabled
        [40]: shouldShowMenuItem([
            itemPartSelectionRestrictionType(4),
            folderRestriction(
                [outboxFolderId, draftsFolderId, ...dumpsterFolderIds, notesFolderId],
                true
            ),
            selectionRestriction([2]),
            staticFolderSearchScopeRestriction(dumpsterFolderIds, true),
        ]),

        // Show "Translate" menu
        // - in all folders except draft folder and recoverable items view
        // - and list view has single selection
        [72]: shouldShowMenuItem([
            folderRestriction(
                [outboxFolderId, draftsFolderId, notesFolderId, ...dumpsterFolderIds],
                true
            ),
            itemPartSelectionRestrictionType(4),
            selectionRestriction([2]),
            readingPaneRestrictionType(2),
            staticFolderSearchScopeRestriction([notesFolderId], true),
            customRestriction(() => {
                const mailboxInfo = getMailboxInfo(getSelectedTableView());
                return (
                    isCapabilitySupported(translatorCapability, mailboxInfo) &&
                    isFeatureEnabled('gsx-translator-translateMessage') &&
                    isTranslatorEnabled(mailboxInfo)
                );
            }),
        ]),

        // Show "Show in immersive reader" menu
        // - list view has single selection
        // - not in recoverable items view
        [68]: shouldShowMenuItem([
            folderRestriction([outboxFolderId, notesFolderId, ...dumpsterFolderIds], true),
            itemPartSelectionRestrictionType(4),
            selectionRestriction([2]),
            staticFolderSearchScopeRestriction([notesFolderId, ...dumpsterFolderIds], true),
            customRestriction(() => {
                const mailboxInfo = getMailboxInfo(getSelectedTableView());
                return isCapabilitySupported(learningToolsCapability, mailboxInfo);
            }),
        ]),

        // Show "Open in new tab" menu
        // - list view has single or multiple real selection
        // - not in recoverable items view
        [37]: shouldShowMenuItem([
            folderRestriction([outboxFolderId, ...dumpsterFolderIds], true),
            itemPartSelectionRestrictionType(4),
            selectionRestriction([2, 4]),
            staticFolderSearchScopeRestriction(dumpsterFolderIds, true),
        ]),

        // Show assign policy if
        // - not in recoverable items view
        // - list view has a only a single selection
        [4]: shouldShowMenuItem([
            folderRestriction([outboxFolderId, notesFolderId, ...dumpsterFolderIds], true),
            customRestriction(
                () =>
                    !isMultipleItemPartSelection() &&
                    !selectionRestriction([6])() &&
                    selectionRestriction([0])()
            ),
            staticFolderSearchScopeRestriction([notesFolderId, ...dumpsterFolderIds], true),
            customRestriction(() => {
                const tableView = getSelectedTableView();
                const mailboxInfo = getMailboxInfo(tableView);
                if (tableView && isGroupTableQuery(tableView.tableQuery)) {
                    const getRetentionPolicyTagListForGroupTableQuery =
                        lazyGetRetentionPolicyTagListForGroupTableQuery.tryImportForRender();
                    const retentionTagsList = getRetentionPolicyTagListForGroupTableQuery
                        ? getRetentionPolicyTagListForGroupTableQuery(
                              tableView.tableQuery,
                              mailboxInfo
                          )
                        : [];

                    return !!(retentionTagsList && retentionTagsList.length > 0);
                }
                return true;
            }),
        ]),

        // Enable ribbon assign policy if
        // - not in recoverable items view
        // - has a selection
        [49]: shouldShowMenuItem([
            folderRestriction([outboxFolderId, notesFolderId, ...dumpsterFolderIds], true),
            selectionRestriction([0]),
            staticFolderSearchScopeRestriction([notesFolderId, ...dumpsterFolderIds], true),
            customRestriction(() => {
                const tableView = getSelectedTableView();
                const mailboxInfo = getMailboxInfo(tableView);
                if (tableView && isGroupTableQuery(tableView.tableQuery)) {
                    const getRetentionPolicyTagListForGroupTableQuery =
                        lazyGetRetentionPolicyTagListForGroupTableQuery.tryImportForRender();
                    const retentionTagsList = getRetentionPolicyTagListForGroupTableQuery
                        ? getRetentionPolicyTagListForGroupTableQuery(
                              tableView.tableQuery,
                              mailboxInfo
                          )
                        : [];

                    return !!(retentionTagsList?.length > 0);
                }
                return true;
            }),
        ]),

        // Show "Find related" with submenu items for FindEmailFromSender and FindEmailFromConversation if
        // - isFindRelatedConversationEnabled is true (otherwise find related from sender is a standalone context menu item)
        // - list view has a single selection
        // - not in a shared folder
        [17]: shouldShowMenuItem([
            folderRestriction([outboxFolderId, ...dumpsterFolderIds, draftsFolderId], true),
            customRestriction(() => {
                return (
                    isFindRelatedConversationEnabled(getMailboxInfo(getSelectedTableView())) &&
                    !isMultipleItemPartSelection() &&
                    selectionRestriction([2])()
                );
            }),
        ]),

        // Show "Find email messages from sender" item if
        // - list view has a single selection
        // - the selected item has an SMTP value
        // - not in a shared folder
        // - not in a persona favorite folder
        [16]: shouldShowMenuItem([
            folderRestriction([outboxFolderId, ...dumpsterFolderIds, draftsFolderId], true),
            mailScenarioRestriction(['persona'], true),
            customRestriction(() => {
                return (
                    !isMultipleItemPartSelection() &&
                    selectionRestriction([2])() &&
                    doesSingleSelectionHasSmtp() &&
                    doesUserHaveSharedFolderPermissionFor(16)
                );
            }),
        ]),

        // Show "Find email messages from conversation" item if
        // - isFindRelatedConversationEnabled is true
        // - list view has a single selection
        // - not in a shared folder
        [15]: shouldShowMenuItem([
            folderRestriction([outboxFolderId, ...dumpsterFolderIds, draftsFolderId], true),
            customRestriction(() => {
                return (
                    isFindRelatedConversationEnabled(getMailboxInfo(getSelectedTableView())) &&
                    !isMultipleItemPartSelection() &&
                    selectionRestriction([2])()
                );
            }),
        ]),

        // Show "View Message Details" menu
        // - we are in an items view
        // - or in conversations menu but with one item
        // - not in sent folders or drafts folder as there is nothing to show there.
        [78]: shouldShowMenuItem([
            folderRestriction([outboxFolderId, sentItemsFolderId, draftsFolderId], true),
            selectionRestriction([2]),
            customRestriction(() => {
                const tableView = getSelectedTableView();
                const rowKeys = [...tableView.selectedRowKeys.keys()];
                const rowId = MailRowDataPropertyGetter.getItemIds(rowKeys[0], tableView);
                const mailboxInfo = getMailboxInfo(tableView);
                return (
                    isCapabilityEnabled(viewMessageDetailsCapability, mailboxInfo) &&
                    ((listViewTypeRestrictionType(1)() && rowId?.length === 1) ||
                        listViewTypeRestrictionType(2)())
                );
            }),
        ]),

        // Show "Message Recall" item
        // - is in Sent folder
        // - when list view has selection
        // - there's a single selection
        // - Message recall is enabled
        [83]: shouldShowMenuItem([
            folderRestriction([sentItemsFolderId]),
            itemPartSelectionRestrictionType(4),
            selectionRestriction([2]),
            customRestriction(() => {
                const mailboxInfo = getMailboxInfo(getSelectedTableView());
                return isMessageRecallEnabled(mailboxInfo) && checkNotMeetingRow(sentItemsFolderId);
            }),
        ]),

        // Show expand/collapse conversation button if:
        // - we are in conversation view
        // - one row in mailing list is selected
        // - SHV3 flight is enabled
        [84]: shouldShowMenuItem([selectionRestriction([2]), listViewTypeRestrictionType(1)]),

        // Show Zoom button if:
        // - one row in mailing list is selected
        // - SHV3 flight is enabled
        [85]: shouldShowMenuItem([selectionRestriction([2])]),

        // Show Light RP button if:
        // - rp-pandaMode is enabled
        // - not under compose mode
        [97]: shouldShowMenuItem([
            selectionRestriction([2]),
            customRestriction(() => {
                const mailboxInfo = getMailboxInfo(getSelectedTableView());
                return (
                    isFeatureEnabled('rp-pandaMode', mailboxInfo) &&
                    lightReadingPaneCustomRestrictions()
                );
            }),
        ]),

        // Show spam-integration add-in in read tab if:
        // - there's a single selection
        // - addin-enableIntegratedSpam is enabled
        [48]: shouldShowMenuItem([
            selectionRestriction([2]),
            customRestriction(() => {
                return (
                    isFeatureEnabled('addin-enableIntegratedSpam') && spamAddinCustomRestrictions()
                );
            }),
        ]),

        // Show Track Read Receipts if:
        // - is in Sent folder
        // - when list view has selection
        // - there's a single selection
        // - TRR is enabled
        [86]: shouldShowMenuItem([
            folderRestriction([sentItemsFolderId]),
            itemPartSelectionRestrictionType(4),
            selectionRestriction([2]),
            customRestriction(() => {
                return checkNotMeetingRow(sentItemsFolderId);
            }),
        ]),

        // Show Save as if:
        // - there's a single selection
        // - there is no access issue (read only, read only plus attachments blocked)
        [65]: shouldShowMenuItem([
            selectionRestriction([2]),
            folderRestriction([outboxFolderId], true),
            customRestriction(() => {
                const mailboxInfo = getMailboxInfo(getSelectedTableView());
                const accessIssue = getCombinedAccessIssue(mailboxInfo);
                return accessIssue == 2;
            }),
        ]),

        // Show Teams Chat Split button if:
        // - there's a single selection
        [88]: shouldShowMenuItem([
            folderRestriction([notesFolderId], true),
            customRestriction(() => {
                return (
                    isFeatureEnabled('mon-teams-ChatAroundEmail') &&
                    !isMultipleItemPartSelection() &&
                    selectionRestriction([2])()
                );
            }),
        ]),

        // Show Share To Teams button if:
        // - there's a single selection
        [91]: shouldShowMenuItem([
            folderRestriction([notesFolderId], true),
            customRestriction(() => {
                return checkItemReplyReplyAllForwardRestrictions(
                    listViewTypeRestrictionType(1)() /* isConversationItemPart */
                )[2];
                /* third value in returned array is isForwardAllowed */
            }),
            customRestriction(() => {
                return (
                    isFeatureEnabled('mon-teams-ShareToTeamsChat') &&
                    !isMultipleItemPartSelection() &&
                    selectionRestriction([2])()
                );
            }),
        ]),

        [96]: shouldShowMenuItem([
            customRestriction(() => {
                return (
                    isFeatureEnabled('thirdParty-chatIntegration') &&
                    hasUserSelectedThirdPartyMeetingProvider(getMailboxInfo(getSelectedTableView()))
                );
            }),
        ]),

        // Show MailContext Flag/Unflag if
        // 1) List view has single selection or has multiple selections
        // 2) It is shared folder and user has edit permission for shared folder
        // Hide if
        // 1) Group mbx
        // 2) Notes folder and dumpster folder
        [89]: shouldShowMenuItem([
            folderRestriction([outboxFolderId, notesFolderId, ...dumpsterFolderIds], true),
            staticFolderSearchScopeRestriction([notesFolderId, ...dumpsterFolderIds], true),
            customRestriction(() => {
                return (
                    (selectionRestriction([2])() || selectionRestriction([4])()) &&
                    doesUserHaveSharedFolderPermissionFor(18)
                );
            }),
        ]),

        [90]: shouldShowMenuItem([
            customRestriction(() => {
                return isFeatureEnabled('mon-file-eml-msg') && isFileActivation();
            }),
        ]),

        // Show "Resend" item if:
        // - is in Sent folder
        // - when list view has selection
        // - there's a single selection
        // - Message Resend is enabled
        [92]: shouldShowMenuItem([
            folderRestriction([sentItemsFolderId]),
            itemPartSelectionRestrictionType(4),
            selectionRestriction([2]),
            customRestriction(() => {
                return checkNotMeetingRow(sentItemsFolderId);
            }),
        ]),

        // Show message extensions flyout:
        // - the message extensions flyout is enabled
        // - there is an add-in adapter for the selected item
        // - not under compose mode
        [93]: shouldShowMenuItem([
            customRestriction(() => {
                return ribbonMessageExtensionsEnabled();
            }),
        ]),

        // Show pinned read runtime controls:
        // - the message extensions flyout is enabled
        // - there is an add-in adapter for the selected item
        // - not under compose mode
        [94]: shouldShowMenuItem([
            customRestriction(() => {
                return ribbonMessageExtensionsEnabled();
            }),
        ]),
    };
}

function shouldShowRibbon_IgnoreStopIgnore(): boolean {
    return shouldShowBehaviorMap[20]() || shouldShowBehaviorMap[70]();
}

function shouldShowRibbon_JunkNotJunk(): boolean {
    return shouldShowBehaviorMap[24]() || shouldShowBehaviorMap[22]();
}

function shouldShowRibbon_Sweep(): boolean {
    return shouldShowBehaviorMap[71]();
}

function shouldShowRibbon_Delete(): boolean {
    return (
        (shouldShowBehaviorMap[13]() || shouldShowBehaviorMap[12]()) &&
        !getMailMenuItemShouldDisable(12)
    );
}

function shouldAllowMoveMessage(): boolean {
    return shouldShowBehaviorMap[29]() && !getMailMenuItemShouldDisable(29);
}

/**
 * Returns true if we are currently showing Folder type table for mail scenario
 */
function isMailFolderTableShown(): boolean {
    const tableView = getSelectedTableView();
    return (
        tableView.tableQuery.type == 0 &&
        (tableView.tableQuery as MailFolderTableQuery).scenarioType == 'mail'
    );
}

/**
 * Returns true for reply, reply all, forward if they are enabled for selected row.
 * This function needs to work for both Message and Conversation view.
 * This function assumes only single selection.
 */
function checkItemReplyReplyAllForwardRestrictions(isConversationItemPart: boolean): boolean[] {
    let [isReplyDisabled, isReplyAllDisabled, isForwardDisabled] = [false, false, false];
    let item: Message | null | undefined;

    const rowKey = getSingleSelectedRowKey();
    if (!rowKey) {
        // Return if no selection or multi selection
        return [!isReplyDisabled, !isReplyAllDisabled, !isForwardDisabled];
    }

    if (isItemPartOperation()) {
        // Conversation view
        const itemId = getSelectedItemParts()[0]; // [0] for single selection
        item = getItemForMailList(itemId, isFirstLevelExpanded(rowKey));
    } else {
        // Mail list view
        item = MailRowDataPropertyGetter.getItem(rowKey, getSelectedTableView());
    }

    if (!item) {
        return [false, false, false];
    }

    [isReplyDisabled, isReplyAllDisabled, isForwardDisabled] = checkItemReplyForwardDisabled(
        item,
        isConversationItemPart
    );

    return [!isReplyDisabled, !isReplyAllDisabled, !isForwardDisabled];
}

const baseReplyReplyAllForwardRestrictions = (): boolean =>
    !isMultipleItemPartSelection() && selectionRestriction([2])() && !getComposeViewState();

/**
 * Returns true if selected row is an SMIME or IRM item.
 * This function assumes only single selection.
 */
function isSMIMEOrIRM(): boolean {
    let item: Message | null | undefined;

    const rowKey = getSingleSelectedRowKey();
    if (!rowKey) {
        // Return if no selection or multi selection
        return false;
    }

    if (isItemPartOperation()) {
        // Conversation view
        const itemId = getSelectedItemParts()[0]; // [0] for single selection
        item = getItemForMailList(itemId, isFirstLevelExpanded(rowKey));
    } else {
        // Mail list view
        item = MailRowDataPropertyGetter.getItem(rowKey, getSelectedTableView());
    }

    return isSMIMEItem(item) || item?.RightsManagementLicenseData?.ExtractAllowed === false;
}

/**
 * Returns a flag indicating whether the current selected folder is not a "Archive"
 * folder for a shadow mail box
 */
function isNotArchiveInShadowMailbox(selectedFolderDistinguishedId: string): boolean {
    const mailboxInfo = getMailboxInfo(getSelectedTableView());
    const selectedDistinguishedFolder = folderStore.folderTable.get(
        folderNameToId(selectedFolderDistinguishedId, mailboxInfo)
    );

    // Return true if the remoteDistinguishedFolderType is not of type Archive.
    if (selectedDistinguishedFolder?.remoteFolderInfo?.remoteDistinguishedFolderType) {
        return (
            selectedDistinguishedFolder.remoteFolderInfo.remoteDistinguishedFolderType !==
            'REMOTE_DISTINGUISHED_FOLDER_TYPE_ARCHIVE'
        );
    }

    // else return true
    return true;
}

/**
 * Returns true if list view has single selected row and it is read
 */
function isSingleSelectedRowRead() {
    const singleSelectedRowKey = getSingleSelectedRowKey();
    if (!singleSelectedRowKey) {
        // False when there is no selection or multiple selection
        return false;
    }

    const unreadCount = MailRowDataPropertyGetter.getUnreadCount(
        singleSelectedRowKey,
        getSelectedTableView()
    );

    return unreadCount === 0;
}

function isAnySelectedItemPartUnread(): boolean {
    if (!isItemPartOperation()) {
        return false;
    }

    const selectedItempartItemIds: string[] = getSelectedItemParts();
    for (const itemId of selectedItempartItemIds) {
        const item = getItemForMailList(itemId, isFirstLevelExpanded(getSingleSelectedRowKey()));
        if (!item?.IsRead) {
            return true;
        }
    }

    return false;
}

function isAnySelectedItemPartRead(): boolean {
    if (!isItemPartOperation()) {
        return false;
    }

    const selectedItempartItemIds: string[] = getSelectedItemParts();
    for (const itemId of selectedItempartItemIds) {
        const item = getItemForMailList(itemId, isFirstLevelExpanded(getSingleSelectedRowKey()));
        if (item?.IsRead) {
            return true;
        }
    }

    return false;
}

function isAnySelectedItemPartUnflagged(): boolean {
    if (!isItemPartOperation()) {
        return false;
    }

    const selectedItempartItemIds: string[] = getSelectedItemParts();
    for (const itemId of selectedItempartItemIds) {
        const item = getItemForMailList(itemId, isFirstLevelExpanded(getSingleSelectedRowKey()));
        if (item?.Flag?.FlagStatus !== 'Flagged') {
            return true;
        }
    }

    return false;
}

function isAnySelectedItemPartFlagged(): boolean {
    if (!isItemPartOperation()) {
        return false;
    }

    const selectedItempartItemIds: string[] = getSelectedItemParts();
    for (const itemId of selectedItempartItemIds) {
        const item = getItemForMailList(itemId, isFirstLevelExpanded(getSingleSelectedRowKey()));
        if (item?.Flag?.FlagStatus === 'Flagged') {
            return true;
        }
    }

    return false;
}

function isMultipleItemPartSelection(): boolean {
    return isItemPartOperation() && getSelectedItemParts().length > 1;
}
/**
 * Returns true if row in message view is unread or all itemparts in conversation view are unread
 */
function isSingleSelectedRowFullyUnread() {
    const singleSelectedRowKey = getSingleSelectedRowKey();
    if (!singleSelectedRowKey) {
        // False when there is no selection or multiple selection
        return false;
    }
    const tableConversationRelation = getTableConversationRelation(
        singleSelectedRowKey,
        getSelectedTableView()?.id
    );
    // In message view, tableConversationRelation is not defined or null.
    if (!tableConversationRelation) {
        const unreadCount = MailRowDataPropertyGetter.getUnreadCount(
            singleSelectedRowKey,
            getSelectedTableView()
        );
        return unreadCount != 0;
    }

    const localItemIds = tableConversationRelation.itemIds;
    const maxPossibleUnreadCount = localItemIds.length;
    return tableConversationRelation.unreadCount === maxPossibleUnreadCount;
}

/**
 * Returns true if list view has single selected row and it is flagged
 */
function isSingleSelectedRowFlagged() {
    const singleSelectedRowKey = getSingleSelectedRowKey();
    if (!singleSelectedRowKey) {
        // False when there is no selection or multiple selection
        return false;
    }

    const flagStatus = MailRowDataPropertyGetter.getFlagStatus(
        singleSelectedRowKey,
        getSelectedTableView()
    );

    return flagStatus == 'Flagged';
}

/**
 * Returns true if list view has single selected row and it is pinned
 */
function isSingleSelectedRowPinned(): boolean {
    const singleSelectedRowKey = getSingleSelectedRowKey();
    if (!singleSelectedRowKey) {
        // False when there is no selection or multiple selection
        return false;
    }

    return MailRowDataPropertyGetter.getIsPinned(singleSelectedRowKey, getSelectedTableView());
}

/**
 * Returns true if single selected row data has smtp address
 */
function doesSingleSelectionHasSmtp(): boolean {
    const singleSelectedRowKey = getSingleSelectedRowKey();
    if (!singleSelectedRowKey) {
        // Hide 'Block' when there is no selection or multiple selection
        return false;
    }

    return !!MailRowDataPropertyGetter.getLastSenderSMTP(
        singleSelectedRowKey,
        getSelectedTableView()
    );
}

/**
 * Returns true if single selected row belongs to a folder where Snooze/UnSnooze is
 * supported.
 *
 * @param allowedFolders List of folders where Snooze/UnSnooze is supported
 */
function isSingleSelectedRowSnoozable(allowedFolders: string[]): boolean {
    const singleSelectedRowKey = getSingleSelectedRowKey();
    if (!singleSelectedRowKey) {
        return false;
    }

    const parentFolderId = MailRowDataPropertyGetter.getParentFolderId(
        singleSelectedRowKey,
        getSelectedTableView()
    );

    for (const allowedFolderName of allowedFolders) {
        if (folderIdToName(parentFolderId) === allowedFolderName) {
            return true;
        }
    }

    return false;
}

/**
 * Returns true if single selected row belongs to a folder where pin/unpin is
 * supported.
 *
 * @param restrictedFolderIds List of folders where pin/unpin is not supported
 */
function isSingleSelectedRowPinnable(restrictedFolderIds: string[]): boolean {
    const singleSelectedRowKey = getSingleSelectedRowKey();
    if (!singleSelectedRowKey) {
        return false;
    }

    const parentFolderId = MailRowDataPropertyGetter.getParentFolderId(
        singleSelectedRowKey,
        getSelectedTableView()
    );

    for (const restrictedFolderName of restrictedFolderIds) {
        if (folderIdToName(parentFolderId) === restrictedFolderName) {
            return false;
        }
    }

    return true;
}

/**
 * Returns the single selected mail list row id in list view, null if otherwise
 */
function getSingleSelectedRowKey(): string {
    const selectedTableView = getSelectedTableView();
    if (!selectedTableView) {
        return '';
    }
    const selectedRowKeys = [...selectedTableView.selectedRowKeys.keys()];
    return selectedRowKeys.length == 1 ? selectedRowKeys[0] : '';
}

/**
 * Function return user assigned permission for the given folderId and user email address
 * If no permissions are present for user then return undefined
 * If there is no value in store for PermissionSet or Permissions then let the function throw error implicitly
 * @param folderId - folder for which the permissions will be calculated
 * @param userEmailAddress - user email for which permissions will be filtered
 */
function getUserPermissionForFolderIdWithErr(
    folderId: string,
    userEmailAddress: string
): Permission {
    return mailStoreGetUserPermissionForFolderIdWithErr(folderId, userEmailAddress);
}

/**
 * This function is wrapper over getUserPermissionForFolderIdWithErr function that can handle error.
 * In case of error this function will return null to denote that there are no permissions.
 * @param folderId - folder for which the permissions will be calculated
 * @param userEmailAddress - user email for which permissions will be filtered
 */
export function getUserPermissionForFolderId(
    folderId: string,
    userEmailAddress: string
): Permission {
    return mailStoreGetUserPermissionForFolderId(folderId, userEmailAddress);
}

/**
 * Function checks if the user is given shared folder permission to delete, move or flag items
 * If permissions are present but they are empty then assume user has all permissions
 * If Permissions are not present in store then throw custom error
 * @param actionType - Menu item type for which permission will be calculated
 * @param externalFolderId - Folder id provided externally to this function for which permissions will be checked
 */
export function doesUserHaveSharedFolderPermissionForWithError(
    actionType: MenuItemType,
    externalFolderId?: string,
    mailboxInfo?: MailboxInfo
): boolean {
    // If folder Id is not provided in argument then get the selected folder node
    const selectedTableView = getSelectedTableView();
    const folderId = externalFolderId || getFolderIdFromTableView(selectedTableView);
    const isSharedFolder = folderId ? isFolderInMailboxType(folderId, 'SharedMailbox') : false;

    // If not shared folder show all triage options
    if (!isSharedFolder || !folderId) {
        return true;
    }
    const userEmail = getAccountScopeUserSettings(mailboxInfo ?? getMailboxInfo(selectedTableView))
        .SessionSettings?.UserEmailAddress;

    if (!userEmail) {
        return false;
    }

    // Try getting permissions by calling getUserPermissionForFolderIdWithErr.
    // If there is error then call getFolderData to get this shared folder's permissions.
    // And throw error for the consumer of this function indicating that permissions are currently not present.
    try {
        const assignedFolderPermission: Permission = getUserPermissionForFolderIdWithErr(
            folderId,
            userEmail
        );
        return doesUserHaveActionPermission(actionType, assignedFolderPermission);
    } catch (e) {
        getFolderData(folderId);
        throw new Error(
            'Move-To Error: Permissions for destination folder have not been fetched yet'
        );
    }
}

/**
 * Function checks if the user has correct permissions to do certain triage actions
 * @param actionType - Menu item type for which permission will be calculated
 * @param assignedFolderPermission - the folder permission object
 */
export function doesUserHaveActionPermission(
    actionType: MenuItemType,
    assignedFolderPermission: Permission
): boolean {
    // If permission object is null it means all permissions assigned
    if (!assignedFolderPermission) {
        return true;
    }

    switch (actionType) {
        // Show Move to and Delete options if
        // - User has delete all permission
        case 29:
        case 9:
        case 12:
            return assignedFolderPermission.DeleteItems === 'All';

        // Show flag, categorize, and pin options if
        // - User has edit all permission
        case 18:
        case 74:
        case 6:
        case 89:
        case 39:
            return assignedFolderPermission.EditItems === 'All';
    }

    return false;
}

/**
 * This function is a wrapper over doesUserHaveSharedFolderPermissionForWithError
 * This function assumes that if permissions data is not fetched yet then automatically permission is provided
 * @param actionType - Menu item type for which permission will be calculated
 */
export function doesUserHaveSharedFolderPermissionFor(actionType: MenuItemType): boolean {
    try {
        return doesUserHaveSharedFolderPermissionForWithError(actionType, undefined /* folderId */);
    } catch (e) {
        return true;
    }
}

function checkNotMeetingRow(sentItemsFolderId: string) {
    const rowKey = getSingleSelectedRowKey();

    if (isItemPartOperation()) {
        // Conversation view
        const itemId = getSelectedItemParts()[0]; // [0] for single selection
        const item = getItemForMailList(itemId, isFirstLevelExpanded(rowKey));
        const folderName = folderIdToName(item?.ParentFolderId?.Id || '');

        return (
            item?.ItemClass?.indexOf(MEETING_ITEM_CLASS) == -1 && folderName == sentItemsFolderId
        );
    } else if (rowKey) {
        const rowId = MailRowDataPropertyGetter.getItemIds(rowKey, getSelectedTableView());
        if (rowId && rowId.length > 0) {
            const item = mailStore.items.get(rowId[0]);
            return item?.ItemClass?.indexOf(MEETING_ITEM_CLASS) == -1;
        }
    }
    return true;
}

// Should match list in updateMenuItem.tsx
const REPORT_ABUSE_BLOCK_LIST = ['postmaster@outlook.com'];

function shouldShowReportAbuseForSender(): boolean {
    const rowKey = getSingleSelectedRowKey();

    if (rowKey) {
        const lastSenderSmtp =
            MailRowDataPropertyGetter.getLastSenderSMTP(
                rowKey,
                getSelectedTableView()
            )?.toLowerCase() ?? '';
        return !REPORT_ABUSE_BLOCK_LIST.includes(lastSenderSmtp);
    }

    return true;
}

/**
 * Returns true if list view has single selected row and it is a draft item
 */
function isDraftItem() {
    let item: Message | null | undefined;
    const singleSelectedRowKey = getSingleSelectedRowKey();
    if (!singleSelectedRowKey) {
        // False when there is no selection or multiple selection
        return false;
    }

    if (isItemPartOperation()) {
        // Conversation view
        const itemId = getSelectedItemParts()[0]; // [0] for single selection
        item = getItemForMailList(itemId, isFirstLevelExpanded(singleSelectedRowKey));
    } else {
        // Mail list view
        item = MailRowDataPropertyGetter.getItem(singleSelectedRowKey, getSelectedTableView());
    }

    return !!item?.IsDraft;
}

function isSenderSelfInSelectedRow(mailboxInfo: MailboxInfo): boolean {
    const singleSelectedRowKey = getSingleSelectedRowKey();
    if (!singleSelectedRowKey) {
        // False when there is no selection or multiple selection
        return false;
    }

    const isSenderSelfForItem = (item: Message | null | undefined) => {
        const senderSmtp = item?.From?.Mailbox?.EmailAddress?.toLowerCase();
        return !!senderSmtp && isSenderSelf(senderSmtp, mailboxInfo);
    };

    if (isItemPartOperation()) {
        // Conversation view
        const selectedItemParts = getSelectedItemParts();
        // only return true if all selected items are from self
        return selectedItemParts.every(itemId => {
            const item = getItemForMailList(itemId, isFirstLevelExpanded(singleSelectedRowKey));
            return isSenderSelfForItem(item);
        });
    } else {
        // Mail list view
        const item = MailRowDataPropertyGetter.getItem(
            singleSelectedRowKey,
            getSelectedTableView()
        );
        return isSenderSelfForItem(item);
    }
}

/**
 * Returns true if list view has single selected row and it is a draft item
 */
function isMeetingItem() {
    let item: Message | null | undefined;
    const singleSelectedRowKey = getSingleSelectedRowKey();
    if (!singleSelectedRowKey) {
        // False when there is no selection or multiple selection
        return false;
    }
    if (isItemPartOperation()) {
        // Conversation view
        const itemId = getSelectedItemParts()[0]; // [0] for single selection
        item = getItemForMailList(itemId, isFirstLevelExpanded(singleSelectedRowKey));
    } else {
        // Mail list view
        item = MailRowDataPropertyGetter.getItem(singleSelectedRowKey, getSelectedTableView());
    }

    return item?.ItemClass?.indexOf(MEETING_ITEM_CLASS) != -1;
}

/**
 * Checks whether spam add-in should be shown in an enabled state or not
 * Enable spam add-in if:
 * - Reading pane is not hidden
 * - We are not in compose mode
 */
function spamAddinCustomRestrictions() {
    if (isReadingPanePositionOff() && !shouldShowReadingPane()) {
        return false;
    }

    return !isDraftItem() && !getComposeViewState();
}

/**
 * Checks whether light reading pane should be shown if:
 * - We are not in compose mode
 */
function lightReadingPaneCustomRestrictions() {
    return !isDraftItem() && !getComposeViewState();
}

function ribbonMessageExtensionsEnabled(): boolean {
    const isReadMode = !isDraftItem() && !getComposeViewState();
    if (!isReadMode) {
        return false;
    }

    const extensibilityState = getExtensibilityState(getMailboxInfo(getSelectedTableView()));
    const enabledAddinCommands = extensibilityState.EnabledAddinCommands;
    if (!enabledAddinCommands?.isInitialized) {
        return false;
    }

    let item: Message | null | undefined;
    const singleSelectedRowKey = getSingleSelectedRowKey();

    if (isItemPartOperation()) {
        // Conversation view
        const itemId = getSelectedItemParts()[0]; // [0] for single selection
        item = getItemForMailList(itemId, isFirstLevelExpanded(singleSelectedRowKey));
    } else {
        // Mail list view
        item = MailRowDataPropertyGetter.getItem(singleSelectedRowKey, getSelectedTableView());
    }

    const addInInitializedItems = extensibilityState.HostItems;

    const addInsInitializedForSelectedItem =
        addInInitializedItems.has(getReadHostItemIndex(item?.ItemId?.Id ?? '')) ||
        !!getAdapter(getMultiSelectHostItemIndex('0'));

    return addInsInitializedForSelectedItem;
}

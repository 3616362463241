import type { MailboxInfo } from 'owa-client-types';
import { validateMailboxInfoIsForStartedAccount } from './validateMailboxInfoIsForStartedAccount';
import { isValidationResultValidForStartedAccount } from './isValidationResultValidForStartedAccount';
import { logGreyErrorFromAccounts } from 'owa-account-analytics';

/**
 * Checks that the MailboxInfo is for a started account
 * @param mailboxInfo MailboxInfo to be checked
 */
export function checkThatMailboxInfoIsForStartedAccount(mailboxInfo: MailboxInfo) {
    const validationResult = validateMailboxInfoIsForStartedAccount(mailboxInfo);
    if (!isValidationResultValidForStartedAccount(validationResult)) {
        const error = new Error('MailboxInfo is not for a started account');
        logGreyErrorFromAccounts('MailboxInfoNotForStartedAccount', error, { ...validationResult });
    }
}

import type { MailboxInfo } from 'owa-client-types';
import { BootState } from 'owa-account-source-list-store/lib/store/schema/BootState';
import getCoprincipalAccountForAccountKey from 'owa-account-source-list-store/lib/utils/getCoprincipalAccountForAccountKey';
import { getAccountKeyForMailboxInfo, isDefaultAccountKey } from 'owa-client-types';
import { areApplicationSettingsInitialized } from 'owa-application-settings';
import { areFeatureFlagsInitialized } from 'owa-feature-flags';

/**
 * Result of the validation for the MailboxInfo, the data in this
 * inferface will be logged and so it is important that it does not
 * contain any end user identifiable information.
 */
export interface ValidationResult {
    hasAccountKey: boolean;
    hasCopricipalAccount: boolean;
    bootState: BootState;
    appSettingsInit: boolean;
    featuresInit: boolean;
}

/**
 * Performs various validation checks on the mailbox info to ensure it is for a started account
 * and the feature flags and application settings are initialized for the account.
 * @param mailboxInfo Specifies the mailbox info to validate
 * @returns Information about the validation result
 */
export function validateMailboxInfoIsForStartedAccount(mailboxInfo: MailboxInfo): ValidationResult {
    const accountKey = getAccountKeyForMailboxInfo(mailboxInfo);
    const hasAccountKey = !!accountKey && !isDefaultAccountKey(accountKey);
    const coprincipalAccount = getCoprincipalAccountForAccountKey(accountKey);
    const hasCopricipalAccount = !!coprincipalAccount;
    const bootState = coprincipalAccount?.bootState ?? BootState.Pending;
    const appSettingsInit = !!areApplicationSettingsInitialized(mailboxInfo);
    const featuresInit = areFeatureFlagsInitialized(mailboxInfo);

    return {
        hasAccountKey,
        hasCopricipalAccount,
        bootState,
        appSettingsInit,
        featuresInit,
    };
}

import { createLazyComponent, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "AccountsCallout" */ './lazyIndex'),
    { name: 'AccountsCallout' }
);

export const PendingAccountsCallout = createLazyComponent(
    lazyModule,
    m => m.PendingAccountsCallout
);

export const PendingAccountsWelcomeCallout = createLazyComponent(
    lazyModule,
    m => m.PendingAccountsWelcomeCallout
);

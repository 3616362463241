import type { ValidationResult } from './validateMailboxInfoIsForStartedAccount';
import { BootState } from 'owa-account-source-list-store/lib/store/schema/BootState';

/**
 * Determines if the validation result is valid for a started account.
 * @param validationResult Result of the validation for the MilboxInfo
 * @returns
 */
export function isValidationResultValidForStartedAccount(
    validationResult: ValidationResult
): boolean {
    return (
        validationResult.hasAccountKey &&
        validationResult.hasCopricipalAccount &&
        (validationResult.bootState === BootState.StartupComplete ||
            validationResult.bootState == BootState.StartupInitialized) &&
        validationResult.appSettingsInit &&
        validationResult.featuresInit
    );
}

const loggableTokenLength = 8;

export function getLoggableToken(token: string | undefined): string {
    if (!token) {
        return 'Token is empty';
    }

    if (typeof token !== 'string') {
        return `Token is of type ${typeof token}`;
    }

    if (token.length <= loggableTokenLength) {
        return token;
    } else {
        return token.substring(token.length - loggableTokenLength);
    }
}

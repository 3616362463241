/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Avoid deprecated APIs, use portals instead of rendering to a new root
 *	> 'render' import from 'owa-react-dom' is restricted. Use react portals instead of rendering into a new root */
import { render } from 'owa-react-dom';

import type { ShellControl } from '@1js/suiteux-shell-react';
import {
    AlertRegular,
    ChatMultipleRegular,
    EmojiRegular,
    FlagRegular,
    LightbulbRegular,
    PersonFeedbackRegular,
    PersonSupportRegular,
    PremiumRegular,
    QuestionRegular,
    SettingsRegular,
    VideoRegular,
    WrenchScrewdriverRegular,
} from '@fluentui/react-icons';
import {
    CalendarTodoRegular,
    OfficeOnenoteMono,
    OfficeSkypeMono,
    TeamsRegular,
} from '@fluentui/react-brand-icons';
import {
    OwaActivityFeedButtonID,
    OwaDiagFeedbackButtonID,
    OwaDiagnosticsButtonID,
    OwaFeedbackButtonID,
    OwaHelpButtonID,
    OwaMeetNowButtonID,
    OwaNoteFeedButtonID,
    OwaPremiumButtonID,
    OwaRolloutOverridesButtonID,
    OwaSettingsButtonID,
    OwaSupportButtonID,
    OwaTimePanelButtonID,
    OwaWhatsNewButtonID,
    OwaTipsButtonID,
    OwaChatCopilotButtonID,
} from '../constants';
import { SkypeCharmStub } from 'owa-header/lib/components/charmStubs';
import { meetNowHeaderText, timePanelHeaderText } from '../strings.locstring.json';
import { MeetNowSuiteHeaderButton } from 'owa-meet-now';
import { ChatCopilotSuiteHeaderButton } from 'owa-copilot-chat';
import React from 'react';
import { UpgradeMessage } from 'owa-locstrings/lib/strings/upgrademessage.locstring.json';
import { activityFeedTitle } from 'owa-locstrings/lib/strings/activityfeedtitle.locstring.json';
import { feedbackCharm_ariaLabel } from 'owa-locstrings/lib/strings/feedbackcharm_arialabel.locstring.json';
import { helpCharm_ariaLabel } from 'owa-locstrings/lib/strings/helpcharm_arialabel.locstring.json';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isTeamsMeetNowEnabled } from 'owa-teams-policies/lib/utils/isTeamsMeetNowEnabled';
import { lazyOpenPremiumDashboard } from 'owa-whats-new';
import loc from 'owa-localize';
import { logUsage } from 'owa-analytics';
import { noteFeedFlexPaneTitle } from 'owa-locstrings/lib/strings/notefeedflexpanetitle.locstring.json';
import { settingsCharm_ariaLabel } from 'owa-locstrings/lib/strings/settingscharm_arialabel.locstring.json';
import { skypeCharm_ariaLabel } from 'owa-locstrings/lib/strings/skypecharm_arialabel.locstring.json';
import { teamsCharm_ariaLabel } from 'owa-locstrings/lib/strings/teamscharm_arialabel.locstring.json';
import { skypeEnterpriseCharm_ariaLabel } from 'owa-locstrings/lib/strings/skypeenterprisecharm_arialabel.locstring.json';
import { supportCharm_ariaLabel } from 'owa-locstrings/lib/strings/supportcharm_arialabel.locstring.json';
import { whatsNew_FlexPane_Title } from 'owa-locstrings/lib/strings/whatsnew_flexpane_title.locstring.json';
import { tips_FlexPane_Title } from 'owa-locstrings/lib/strings/tips_flexpane_title.locstring.json';
import { lazyMountAndShowFullOptions } from 'owa-options-view';
import { buttonIcon, brandIcon } from './OwaSuiteHeader.scss';
import { TeamsPoliciesScenario } from 'owa-teams-policies-lazy/lib/store/schema';

export const owaIconFontFamily = 'controlIcons';

const rolloutOverridesLabel = 'Rollout Overrides'; // No need to localize as it is a DEV feature only
export const OwaRolloutOverridesButton = {
    id: 'owaRolloutOverridesBtn',
    headerButtonRenderData: {
        id: OwaRolloutOverridesButtonID,
        iconData: {
            element: <FlagRegular className={buttonIcon} />,
        },
        ariaLabel: rolloutOverridesLabel,
        affordanceMenuItemText: rolloutOverridesLabel,
    },
} as ShellControl;

const premiumLabel = () => loc(UpgradeMessage);
export const OwaPremiumButton = {
    id: 'owaPremiumBtn',
    headerButtonRenderData: {
        id: OwaPremiumButtonID,
        iconData: {
            element: <PremiumRegular className={buttonIcon} />,
        },
        ariaLabel: premiumLabel,
        affordanceMenuItemText: premiumLabel,
        onShow() {
            logUsage('HeaderPremiumDiamondClicked');
            lazyOpenPremiumDashboard.importAndExecute();
        },
        onHide() {},
    },
} as ShellControl;

const timePanelLabel = () => loc(timePanelHeaderText);
export const OwaTimePanelButton = {
    id: 'owaTimePanelBtn',
    headerButtonRenderData: {
        id: OwaTimePanelButtonID,
        iconData: {
            element: <CalendarTodoRegular className={brandIcon} />,
        },
        ariaLabel: timePanelLabel,
        affordanceMenuItemText: timePanelLabel,
    },
} as ShellControl;

const settingsLabel = () => loc(settingsCharm_ariaLabel);
export const OwaSettingsButton = {
    id: 'owaSettingsBtn',
    headerButtonRenderData: {
        id: OwaSettingsButtonID,
        iconData: {
            element: <SettingsRegular className={buttonIcon} />,
        },
        ariaLabel: settingsLabel,
        affordanceMenuItemText: settingsLabel,
        isStateless: true,
        onShow() {
            lazyMountAndShowFullOptions.importAndExecute();
        },
    },
} as ShellControl;

const feedbackLabel = () => loc(feedbackCharm_ariaLabel);
export const OwaFeedbackButton = {
    id: 'owaFeedbackBtn',
    headerButtonRenderData: {
        id: OwaFeedbackButtonID,
        iconData: {
            element: <EmojiRegular className={buttonIcon} />,
        },
        ariaLabel: feedbackLabel,
        affordanceMenuItemText: feedbackLabel,
    },
} as ShellControl;

export const OwaDiagFeedbackButton = {
    id: 'owaDiagFeedbackBtn',
    headerButtonRenderData: {
        id: OwaDiagFeedbackButtonID,
        iconData: {
            element: <PersonFeedbackRegular className={buttonIcon} />,
        },
        ariaLabel: feedbackLabel,
        affordanceMenuItemText: feedbackLabel,
    },
} as ShellControl;

const supportLabel = () => loc(supportCharm_ariaLabel);
export const OwaSupportButton = {
    id: 'owaSupportBtn',
    headerButtonRenderData: {
        id: OwaSupportButtonID,
        iconData: {
            element: <PersonSupportRegular className={buttonIcon} />,
        },
        ariaLabel: supportLabel,
        affordanceMenuItemText: supportLabel,
    },
} as ShellControl;

const whatsNewLabel = () => loc(whatsNew_FlexPane_Title);
export const OwaWhatsNewButton = {
    id: 'owaWhatsNewBtn',
    headerButtonRenderData: {
        id: OwaWhatsNewButtonID,
        iconData: {
            element: <LightbulbRegular className={buttonIcon} />,
        },
        ariaLabel: whatsNewLabel,
        affordanceMenuItemText: whatsNewLabel,
    },
} as ShellControl;

const tipsLabel = () => loc(tips_FlexPane_Title);
export const OwaTipsButton = {
    id: 'owaTipsBtn',
    headerButtonRenderData: {
        id: OwaTipsButtonID,
        iconData: {
            element: <LightbulbRegular className={buttonIcon} />,
        },
        ariaLabel: tipsLabel,
        affordanceMenuItemText: tipsLabel,
    },
} as ShellControl;

const chatKey = 'skype';
const businessChatLabel = () => loc(skypeEnterpriseCharm_ariaLabel);
export const OwaBusinessChatButton = createChildControlFromCharm(
    chatKey,
    <SkypeCharmStub key={chatKey} />,
    <ChatMultipleRegular className={buttonIcon} />,
    businessChatLabel,
    businessChatLabel
);

const consumerChatLabel = () => loc(skypeCharm_ariaLabel);
export const OwaConsumerChatButton = createChildControlFromCharm(
    chatKey,
    <SkypeCharmStub key={chatKey} />,
    <OfficeSkypeMono className={buttonIcon} />,
    consumerChatLabel,
    consumerChatLabel
);

const chatTeamsKey = 'teams';
const consumerTeamsChatLabel = () => loc(teamsCharm_ariaLabel);
export const OwaConsumerTeamsChatButton = createChildControlFromCharm(
    chatTeamsKey,
    <SkypeCharmStub key={chatTeamsKey} />,
    <TeamsRegular className={buttonIcon} />,
    consumerTeamsChatLabel,
    consumerTeamsChatLabel
);

const diagnosticsLabel = () => 'Diagnostics'; // No need to localize as it is a DEV feature only
export const OwaDiagnosticsButton = {
    id: OwaDiagnosticsButtonID,
    headerButtonRenderData: {
        id: OwaDiagnosticsButtonID,
        iconData: {
            element: <WrenchScrewdriverRegular className={buttonIcon} />,
        },
        ariaLabel: diagnosticsLabel,
        affordanceMenuItemText: diagnosticsLabel,
    },
} as ShellControl;

export const OwaShellHelpButton = {
    id: 'owaHelpButton',
    nativeControlID: 'HelpIcon',
};

const helpButtonLabel = () => loc(helpCharm_ariaLabel);
export const OwaCustomHelpButton = {
    id: OwaHelpButtonID,
    headerButtonRenderData: {
        id: OwaHelpButtonID,
        iconData: {
            element: <QuestionRegular className={buttonIcon} />,
        },
        ariaLabel: helpButtonLabel,
        affordanceMenuItemText: helpButtonLabel,
    },
};

const activityFeedLabel = () => loc(activityFeedTitle);
export const OwaActivityFeedButton = {
    id: OwaActivityFeedButtonID,
    headerButtonRenderData: {
        id: OwaActivityFeedButtonID,
        iconData: {
            element: <AlertRegular className={buttonIcon} />,
        },
        ariaLabel: activityFeedLabel,
        affordanceMenuItemText: activityFeedLabel,
    },
} as ShellControl;

const noteFeedLabel = () => loc(noteFeedFlexPaneTitle);

export const OwaNoteFeedButton = {
    id: OwaNoteFeedButtonID,
    headerButtonRenderData: {
        id: OwaNoteFeedButtonID,
        iconData: {
            element: <OfficeOnenoteMono className={brandIcon} />,
        },
        ariaLabel: noteFeedLabel,
        affordanceMenuItemText: noteFeedLabel,
    },
} as ShellControl;

export const OwaChatCopilotButton = {
    id: OwaChatCopilotButtonID,
    render: (container: HTMLDivElement) =>
        render(<div>{<ChatCopilotSuiteHeaderButton />}</div>, container),
    responsiveBehavior: {
        responsivePriority: 1,
        minimizeBehavior: 'none',
    },
} as ShellControl;

const meetNowLabel = () => loc(meetNowHeaderText);
export const OwaMeetNowButton = createChildControlFromCharm(
    OwaMeetNowButtonID,
    <MeetNowSuiteHeaderButton />,
    <VideoRegular className={buttonIcon} />,
    meetNowLabel,
    meetNowLabel,
    undefined,
    undefined,
    true,
    async (): Promise<boolean> => {
        if (isFeatureEnabled('fwk-teamsPolicies')) {
            return isTeamsMeetNowEnabled(TeamsPoliciesScenario.MailMeetNow);
        } else {
            return true; // If not use Teams pollicy, the visibility is controlled by pushOnCondition in OwaSuiteHeader.
        }
    }
);

function createChildControlFromCharm(
    key: React.Key,
    charm: JSX.Element,
    iconElement: JSX.Element,
    ariaLabel: () => string,
    affordanceMenuItemText: () => string,
    onShow?: (btnLocation: ClientRect) => void,
    onHide?: () => void,
    naturalWidth?: boolean,
    shouldEnable?: () => Promise<boolean>
): ShellControl {
    const headerButtonWidthInPixels = 48;
    const containerRef = React.createRef<HTMLDivElement>();

    return {
        id: key,
        flex: naturalWidth ? undefined : `0 0 ${headerButtonWidthInPixels}px`,
        render: (container: HTMLDivElement) =>
            render(<div ref={containerRef}>{charm}</div>, container),
        shouldEnable,
        enterSearchMode: () => containerRef.current?.classList?.add?.('inSearch'),
        exitSearchMode: () => containerRef.current?.classList?.remove?.('inSearch'),
        responsiveBehavior: {
            responsivePriority: 0,
            responsiveSteps: naturalWidth ? undefined : [headerButtonWidthInPixels],
            minimizeBehavior: 'overflow',
            affordanceBehavior: {
                customButtonRenderData: {
                    id: key,
                    iconData: {
                        element: iconElement,
                    },
                    onHide,
                    onShow,
                    ariaLabel,
                    affordanceMenuItemText,
                },
            },
        },
    } as ShellControl;
}

import type {
    AddTimeSelectionNovaEvent,
    AddTimeSelectionPayload,
} from './AddTimeSelectionNovaEvent';
import type {
    OpenEventDetailsPayload,
    OpenEventDetailsNovaEvent,
} from './OpenEventDetailsNovaEvent';
import type {
    RemoveTimeSelectionNovaEvent,
    RemoveTimeSelectionPayload,
} from './RemoveTimeSelectionNovaEvent';
import type {
    InvokeRemoteCommandNovaEvent,
    InvokeRemoteCommandPayload,
} from './InvokeRemoteCommandNovaEvent';
import type {
    RespondToMeetingNovaEvent,
    RespondToMeetingPayload,
} from './RespondToMeetingNovaEvent';

export const CalendarSidePanelEventTypes = {
    addTimeSelection: 'addTimeSelection',
    removeTimeSelection: 'removeTimeSelection',
    openEventDetails: 'openEventDetails',
    invokeRemoteCommand: 'invokeRemoteCommand',
    respondToMeeting: 'respondToMeeting',
} as const;

export type CalendarSidePanelEventUnion =
    | AddTimeSelectionNovaEvent
    | RemoveTimeSelectionNovaEvent
    | OpenEventDetailsNovaEvent
    | InvokeRemoteCommandNovaEvent
    | RespondToMeetingNovaEvent;

export const CalendarSidePanelEvents = {
    addTimeSelection: (
        originator: string,
        data: () => AddTimeSelectionPayload
    ): AddTimeSelectionNovaEvent => ({
        originator,
        type: CalendarSidePanelEventTypes.addTimeSelection,
        data,
    }),
    removeTimeSelection: (
        originator: string,
        data: () => RemoveTimeSelectionPayload
    ): RemoveTimeSelectionNovaEvent => ({
        originator,
        type: CalendarSidePanelEventTypes.removeTimeSelection,
        data,
    }),
    openEventDetails: (
        originator: string,
        data: () => OpenEventDetailsPayload
    ): OpenEventDetailsNovaEvent => ({
        originator,
        type: CalendarSidePanelEventTypes.openEventDetails,
        data,
    }),
    invokeRemoteCommand: (
        originator: string,
        data: () => InvokeRemoteCommandPayload
    ): InvokeRemoteCommandNovaEvent => ({
        originator,
        type: CalendarSidePanelEventTypes.invokeRemoteCommand,
        data,
    }),
    respondToMeeting: (
        originator: string,
        data: () => RespondToMeetingPayload
    ): RespondToMeetingNovaEvent => ({
        originator,
        type: CalendarSidePanelEventTypes.respondToMeeting,
        data,
    }),
};

export type CalendarSidePanelOwaNovaEventMap = {
    addTimeSelection: AddTimeSelectionNovaEvent;
    removeTimeSelection: RemoveTimeSelectionNovaEvent;
    openEventDetails: OpenEventDetailsNovaEvent;
    invokeRemoteCommand: InvokeRemoteCommandNovaEvent;
    respondToMeeting: RespondToMeetingNovaEvent;
};

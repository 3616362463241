import {
    AppTheme,
    AppThemeManager,
    setThemingIsEnabled,
} from '@1js/acui-theme/lib/components/AppThemeManager/AppThemeManager';
import { getIsDarkTheme } from 'owa-fabric-theme';
import { isFeatureEnabled } from 'owa-feature-flags';

/** Used when Fluent v8 is used */
let appThemeInitialized = false;

/** Used when Fluent v9 flight is enabled */
let currentAppTheme: AppTheme;

/**
 * Loads the `AppTheme` used by ACUI components in order to properly output the light or dark theme.
 *
 * This is intended to be called:
 * 1. Before the first render (so not inside of an `useEffect`)
 * 2. Inside a component function (so that it doesn't get called unless we actually render that component)
 */
export function ensureAppThemeInitialized() {
    if (isFeatureEnabled('mon-ribbon-fluent-v9-ribbon')) {
        const appThemeManager = AppThemeManager.getInstance();
        const isDarkTheme = getIsDarkTheme();
        const targetAppTheme: AppTheme = isDarkTheme ? AppTheme.Dark : AppTheme.Light;

        if (currentAppTheme !== targetAppTheme) {
            appThemeManager.loadTheme(targetAppTheme);
            currentAppTheme = targetAppTheme;
        }
    } else {
        if (!appThemeInitialized) {
            setThemingIsEnabled(true);
            appThemeInitialized = true;
        }
    }
}

import { addDatapointConfig } from 'owa-analytics-actions';
import { mutatorAction } from 'satcheljs';
import readingPaneStore from '../store/Store';
import { saveIsLightRPOnToStorage } from '../utils/localStorage';

export default mutatorAction('toggleLightRPState', () => {
    addDatapointConfig(
        {
            name: readingPaneStore.isLightRPOn ? 'ToggleLightRPOff' : 'ToggleLightRPOn',
        },
        { isLightRPOn: !readingPaneStore.isLightRPOn }
    );

    readingPaneStore.isLightRPOn = !readingPaneStore.isLightRPOn;
    saveIsLightRPOnToStorage(readingPaneStore.isLightRPOn);
});

import type { MailboxInfo } from 'owa-client-types';
import type { M365Acquisition } from 'owa-graph-schema';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { makePostRequest } from 'owa-ows-gateway';
import { hasLaunchPage } from 'owa-m365-acquisitions/lib/utils/hasStaticTab';
import { lazyGetM365Acquisitions } from 'owa-m365-acquisitions/lib/lazyFunction';

interface AppBarTile {
    appId: string;
    name: string;
    largeImageUrl: string;
    outlineImageUrl: string;
}

const ENDPOINT_URL = 'ows/beta/Acquisitions/AppBarTiles';

function appBarTileFilter(acquisition: M365Acquisition) {
    // we only want to persist launch page titles that come from MOS3
    return (
        acquisition.titleDefinition.ingestionSource !== 'Hardcoded' &&
        acquisition.titleDefinition.ingestionSource !== 'UserPinnedAcquisition' &&
        hasLaunchPage(acquisition)
    );
}

export async function updateAppBarTiles(mailboxInfo?: MailboxInfo): Promise<void> {
    const acquisitions = await lazyGetM365Acquisitions.importAndExecute(
        'cache-only',
        ['StaticTab'],
        mailboxInfo
    );

    const appBarTiles: AppBarTile[] = acquisitions.filter(appBarTileFilter).map(acquisition => ({
        appId: acquisition.appId ?? acquisition.titleId,
        name: acquisition.titleDefinition.name,
        largeImageUrl: acquisition.titleDefinition.iconLarge?.uri ?? '',
        outlineImageUrl: acquisition.titleDefinition.iconOutline?.uri ?? '',
    }));

    // Dedupe tiles by appId
    const seen = new Set<string>();
    const uniqueTiles = appBarTiles.filter(tile => {
        if (seen.has(tile.appId)) {
            return false;
        }
        seen.add(tile.appId);
        return true;
    });

    const requestMailboxInfo: MailboxInfo = mailboxInfo ?? getModuleContextMailboxInfo();

    if (uniqueTiles.length > 0) {
        void (await makePostRequest(
            ENDPOINT_URL,
            [...uniqueTiles],
            undefined /* correlationId */,
            true /* returnFullResponse */,
            null /* customHeaders */,
            false /* throwServiceError */,
            undefined,
            undefined,
            'updateAppBarTiles',
            undefined,
            requestMailboxInfo
        ));
    }
}

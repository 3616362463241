import saveConditionalFormattingRulesService from '../services/saveConditionalFormattingRulesService';
import type { MailboxInfo } from 'owa-client-types';
import type { ConditionalFormattingRule } from '../store/schema/ConditionalFormattingRule';
import clearRowToModificationMap from '../mutators/clearRowToModificationMap';
import setConditionalFormattingRules from '../mutators/setConditionalFormattingRules';
import { setItem } from 'owa-local-storage';
import { CONDITIONAL_FORMATTING_SETTING_NAME } from '../utils/constants';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { logUsage } from 'owa-analytics';
import { ConditionalFormattingConditionType } from '../store/schema/ConditionalFormattingConditionType';

export default function updateConditionalFormattingRules(
    mailboxInfo: MailboxInfo,
    conditionalFormattingRules: ConditionalFormattingRule[],
    isFetch?: boolean
) {
    if (conditionalFormattingRules) {
        if (conditionalFormattingRules.some(cfRule => !cfRule)) {
            logUsage('TnS_ConditionalFormatting_Error', {
                message: isFetch
                    ? 'Null values while fetching conditional formatting rules'
                    : 'Null values while saving conditional formatting rules',
            });
        }
        const cleanedRules = conditionalFormattingRules.filter(
            conditionalFormattingRule => conditionalFormattingRule
        );

        cleanedRules.forEach(cleanedRule => {
            cleanedRule.conditions.forEach(condition => {
                if (condition.type === ConditionalFormattingConditionType.From) {
                    if (!condition.value || (condition.value as string[]).length === 0) {
                        logUsage('TnS_ConditionalFormatting_Error', {
                            message: isFetch
                                ? 'fetching null sender array in conditional formatting rule.'
                                : 'saving null sender array in conditional formatting rule.',
                        });
                    } else {
                        const emails = condition.value as string[];
                        if (emails.some(email => !email || email === '' || email === 'null')) {
                            logUsage('TnS_ConditionalFormatting_Error', {
                                message: isFetch
                                    ? 'fetching null sender in conditional formatting rule.'
                                    : 'saving null sender in conditional formatting rule.',
                            });
                        }
                    }
                }
            });
        });

        setConditionalFormattingRules(mailboxInfo, cleanedRules);

        saveConditionalFormattingRulesService(mailboxInfo, JSON.stringify(cleanedRules));

        setItem(
            window,
            `${CONDITIONAL_FORMATTING_SETTING_NAME}${getIndexerValueForMailboxInfo(mailboxInfo)}`,
            JSON.stringify(cleanedRules)
        );
    }

    // Whenever we modify (add, delete, edit, move position) the conditional formatting rules, we want to invalidate the rowToModificationMap
    // so that we trigger a re-evaluation of the rules to apply the appropriate modifications for the rows in the message list.
    clearRowToModificationMap(mailboxInfo);
}

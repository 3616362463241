import { getIllustration, type IllustrationType } from 'owa-empty-state/lib/utils/getIllustration';
import {
    errorCannotCompleteRequestLineOne,
    errorCannotCompleteRequestLineTwo,
    safeToCloseString,
    nothingIsSelected,
    errorMessageNotFound,
    goToInboxString,
} from './EmptyStateReadingPane.locstring.json';
import { emptyStateMessageSelectAnItemToRead } from 'owa-locstrings/lib/strings/emptystatemessageselectanitemtoread.locstring.json';
import loc from 'owa-localize';
import React from 'react';
import isDeepLink from 'owa-url/lib/isDeepLink';
import getMailPath from 'owa-url/lib/getMailPath';
import { getOrigin } from 'owa-url/lib/getOrigin';
import { observer } from 'owa-mobx-react';
import { updateAddinOnNavigationToEmptyNullReadingPane } from 'owa-mail-addins';
import EmptyState from 'owa-empty-state/lib/components/EmptyState';
import getIsDarkTheme from 'owa-fabric-theme/lib/selectors/getIsDarkTheme';
import endSelectMailItemDatapoint from 'owa-mail-logging/lib/utils/endSelectMailItemDatapoint';
import { recoverableItemsFolderDescription } from './ReadingPane.locstring.json';
import { type MailListViewState } from 'owa-mail-store/lib/store/schema/MailListViewState';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isCapabilityEnabled } from 'owa-capabilities';
import { bleedThroughCapability } from 'owa-capabilities-definitions/lib/bleedThroughCapability';
import { emptyState, actionButton } from './EmptyStateReadingPane.scss';

let NO_SELECTION_SVG: string;
let EMPTY_ERROR_SVG: string;
let NOT_FOUND_SVG: string;

export interface EmptyStateReadingPaneProps {
    isError?: boolean;
    isDumpsterOrDumpsterSearchTable?: boolean;
    shouldShowBackgroundImage?: boolean;
    mailListViewState?: MailListViewState;
}

const emptyStateStyles = {
    display: 'flex',
};

const EmptyStateReadingPane = observer(function EmptyStateReadingPane(
    props: EmptyStateReadingPaneProps
) {
    let contentType: string = 'Empty';
    React.useEffect(() => {
        updateAddinOnNavigationToEmptyNullReadingPane();
        endSelectMailItemDatapoint(contentType);
    }, []);

    initializeSvgsForTheme();

    let emptyStateIcon: string | undefined = NO_SELECTION_SVG;
    let mainMessageText: string | null = loc(emptyStateMessageSelectAnItemToRead);
    let detailsMessageText: string | undefined = loc(nothingIsSelected);
    let ctaMessageText: string | undefined = undefined;
    let onCtaClickHandler = undefined;
    let actionButtonStyle: string | undefined = undefined;

    const emptyStateContainerClassNames = React.useMemo(
        () => ({
            container: emptyState,
            button: actionButtonStyle,
        }),
        [actionButtonStyle]
    );

    if (props.isError && isDeepLink()) {
        emptyStateIcon = NOT_FOUND_SVG;
        mainMessageText = loc(errorMessageNotFound);
        detailsMessageText = undefined;
        contentType = 'Error_Deeplink';
    } else if (props.isError) {
        emptyStateIcon = EMPTY_ERROR_SVG;
        mainMessageText = loc(errorCannotCompleteRequestLineOne);
        detailsMessageText = loc(errorCannotCompleteRequestLineTwo);
        contentType = 'Error';
    } else if (isDeepLink()) {
        mainMessageText = loc(safeToCloseString);
        detailsMessageText = undefined;
        if (isFeatureEnabled('fwk-mailtoProtocolHandler')) {
            ctaMessageText = loc(goToInboxString);
            /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
             * Baseline, please do not copy and paste this justification
             *	> JSX attribute values should not contain functions created in the same scope */
            onCtaClickHandler = () => {
                window?.open(`${getOrigin()}${getMailPath()}inbox`, '_self');
            };
            actionButtonStyle = actionButton;
        }
    } else if (props.mailListViewState === 'Empty') {
        emptyStateIcon = undefined;
        mainMessageText = null;
        detailsMessageText = undefined;
    } else if (props.isDumpsterOrDumpsterSearchTable) {
        mainMessageText = loc(recoverableItemsFolderDescription);
        detailsMessageText = undefined;
    }

    return isCapabilityEnabled(bleedThroughCapability) && !isDeepLink() ? null : (
        <EmptyState
            icon={emptyStateIcon}
            mainMessage={mainMessageText}
            detailMessage={detailsMessageText}
            ctaMessage={ctaMessageText}
            onCtaClick={onCtaClickHandler}
            classNames={emptyStateContainerClassNames}
            styles={emptyStateStyles}
        />
    );
},
'EmptyStateReadingPane');

function initializeSvgsForTheme() {
    const isDarkTheme = getIsDarkTheme();

    NO_SELECTION_SVG = getIllustration('NoMailSelected', isDarkTheme);
    EMPTY_ERROR_SVG = getIllustration('DisconnectedError', isDarkTheme);
    NOT_FOUND_SVG = getIllustration('EmailNotFound', isDarkTheme);
}

export default EmptyStateReadingPane;

import React from 'react';
import loc from 'owa-localize';
import {
    safeModeLabel,
    recoveryModeLabel,
} from './getExtraTopRibbonControlsAfterTabs.locstring.json';
import { modeContainer } from './getExtraTopRibbonControlsAfterTabs.scss';

export const SafeMode: React.FC = () => (
    <div className={modeContainer}>
        <label>{loc(safeModeLabel)}</label>
    </div>
);

export const RecoveryMode: React.FC = () => (
    <div className={modeContainer}>
        <label>{loc(recoveryModeLabel)}</label>
    </div>
);

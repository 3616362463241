import parseOverrideString from './parseOverrideString';
import type FeatureOverride from '../../store/schema/FeatureOverride';
import { getItem, type LocalStorageKeys, removeItem, setItem } from 'owa-local-storage';
import { getDefaultFlags } from '../defaultFlags';
import type { MailboxInfo } from 'owa-client-types';
import { getSuffixForLocalStorage } from './getSuffixForLocalStorage';
import { isSafeModeSession } from 'owa-config/lib/isSafeModeSession';
import { isRecoveryModeSession } from 'owa-config/lib/isRecoveryModeSession';

export function getLocalStorageOverrides(mailboxInfo?: MailboxInfo) {
    // Skip local storage overrides if we are in safe mode.
    if (isSafeModeSession() || isRecoveryModeSession()) {
        return {};
    }
    const localStorageOverrides = getItem(self, getLocalStorageKey(mailboxInfo));

    if (localStorageOverrides) {
        return parseOverrideString(localStorageOverrides);
    }

    return {};
}

export function clearLocalStorageOverrides(mailboxInfo?: MailboxInfo) {
    removeItem(self, getLocalStorageKey(mailboxInfo));
}

export function updateLocalStorageOverrides(
    overrides: FeatureOverride[],
    mailboxInfo?: MailboxInfo
) {
    // Get overrides from local storage (or empty array if it doesn't exist yet).
    const localStorageOverrides = getItem(self, getLocalStorageKey(mailboxInfo));
    let allOverrides = localStorageOverrides ? localStorageOverrides.split(',') : [];

    for (const override of overrides) {
        // Construct key to set in local storage value for this feature override.
        const normalizedFeatureName = override.name.toLowerCase();
        const overrideKey = override.isEnabled
            ? normalizedFeatureName
            : `-${normalizedFeatureName}`;

        /**
         * Determine if feature is enabled by default for user and compare that
         * value to the value of the update. If they are the same, it doesn't
         * need to be written to localStorage.
         *
         * "isEnabledByDefault" can be undefined which is they the condition is
         * constructed as-is.
         */
        const isEnabledByDefault = getDefaultFlags(mailboxInfo)[normalizedFeatureName];
        const shouldBeStored = override.isEnabled === !isEnabledByDefault;

        // Remove current override from list of overrides (if it exists).
        const featureRegex = new RegExp(`^-?${normalizedFeatureName}$`, 'i');
        allOverrides = allOverrides.filter(feature => {
            return !featureRegex.test(feature);
        });

        // Add current override to list of overrides (if value differs from default).
        if (shouldBeStored) {
            allOverrides.push(overrideKey);
        }
    }

    setItem(self, getLocalStorageKey(mailboxInfo), allOverrides.join(','));
}

/**
 * Return the local storage key specific to the provided account.  The global account key is just 'featureOverrides'.
 * Other accounts will be of the form 'featureOverrides:<indexer>'
 *
 * @param mailboxInfo the mailbox account
 * @returns the local storage key for feature overrides for the account
 */
function getLocalStorageKey(mailboxInfo?: MailboxInfo): LocalStorageKeys {
    const FEATURE_OVERRIDE_LOCAL_STORAGE_KEY = 'featureOverrides';
    const suffix = getSuffixForLocalStorage(mailboxInfo);
    return `${FEATURE_OVERRIDE_LOCAL_STORAGE_KEY}${suffix}`;
}

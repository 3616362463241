// extracted by mini-css-extract-plugin
export var columnHeader = "h2KSz";
export var columnHeaderCompact = "aspKN";
export var columnHeaderCozy = "s6Xyn";
export var columnHeaderHovered = "OQICU";
export var columnHeaderPadding = "Q_TnT";
export var columnHeaderRoomy = "DDtA5";
export var columnHeadersContainer = "s8wta";
export var emptyFirstColumn = "ddb09";
export var full = "_6d8zz";
export var resizeHandle = "RfETL";
export var resizeHandleMargin = "CRRBn";
export var sortIconCompact = "ZLobH";
export var sortIconRoomyOrCozy = "dGAfa";
export const teams_ChatAroundEmailButton = "aqvkt";
export const teams_ChatAroundEmailAllButton = "pbjhO";
export const teams_ChatAroundEmailSplitButton = "_Vi$kb";
export const teams_ShareToTeamsChatButton = "TJ1aSd";
export const copyToButton = "oEzwRb";
export const myDayButton = "O3kuid";
export const myDayMenuShow = "yURwte";
export const myDayMenuHide = "BbFiue";
export const folderSettingsButton = "D5ezCb";
export const favoriteSettingsButton = "Hy39K";
export const customSortButton = "n_4eBe";
export const lightRPOnButton = "BVoWLb";
export const lightRPOffButton = "ltagUd";
export default {teams_ChatAroundEmailButton,teams_ChatAroundEmailAllButton,teams_ChatAroundEmailSplitButton,teams_ShareToTeamsChatButton,copyToButton,myDayButton,myDayMenuShow,myDayMenuHide,folderSettingsButton,favoriteSettingsButton,customSortButton,lightRPOnButton,lightRPOffButton};
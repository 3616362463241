import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';
import getIsCurrentThemeFullBleed from 'owa-theme/lib/selectors/getIsCurrentThemeFullBleed';
import { calendarTeamsBleedThroughCapability } from './calendarTeamsBleedThroughCapability';

/**
 * NOTE: Please see the README for more information on how the
 * capability functions must be implemented and used.
 */
export const bleedThroughCapability: Capability = {
    isEnabled: (_mailboxInfo?: MailboxInfo) => {
        return (
            getIsCurrentThemeFullBleed() ||
            (calendarTeamsBleedThroughCapability.isEnabled(_mailboxInfo) &&
                calendarTeamsBleedThroughCapability.isSupported(_mailboxInfo))
        );
    },
    isSupported: (_mailboxInfo?: MailboxInfo) => {
        return true; // This capability is supported by default
    },
};

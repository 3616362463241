import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';
import getIsCurrentThemeCalendarTeamsTheme from 'owa-theme/lib/selectors/getIsCurrentThemeCalendarTeamsTheme';
import { isFeatureEnabled } from 'owa-feature-flags';

export const calendarTeamsBleedThroughCapability: Capability = {
    isEnabled: (_mailboxInfo?: MailboxInfo) => {
        return getIsCurrentThemeCalendarTeamsTheme();
    },
    isSupported: (_mailboxInfo?: MailboxInfo) => {
        return isFeatureEnabled('teams-cal-mica');
    },
};

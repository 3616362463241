import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import { logStartUsage } from 'owa-analytics-start';
import { getGuid } from 'owa-guid';
import { getAuthTokenResponse } from './utils/getAuthTokenResponse';
import { ensureTrailingSlash } from 'owa-url/lib/joinPath';
import { getOrigin } from 'owa-url/lib/getOrigin';
import { getCachedTokenIfAvailable } from './utils/getCachedTokenIfAvailable';
import { type AuthTokenResponse } from './schema/AuthTokenResponse';
import { getToken } from './getToken';
import { findMetatag } from 'owa-metatags';
import { isUrlPresent } from 'owa-config';
import getModuleContextMailboxInfo from 'owa-module-context-mailboxinfo/lib/selectors/getModuleContextMailboxInfo';
import isBusiness from 'owa-session-store/lib/utils/isBusiness';
import { isServiceRequestSupportedForMailbox } from 'owa-service/lib/utils/isServiceRequestSupportedForMailbox';
import { debugErrorThatWillShowErrorPopupOnly } from 'owa-trace';
import { getScenarioType } from './utils/getScenarioType';
import { isBusinessDomain } from 'owa-msaljs/lib/isMsalEnabledForBusiness';

export async function getAccessTokenforResource(
    resource: string,
    apiName?: string,
    requestId?: string,
    targetTenantId?: string,
    wwwAuthenticateHeader?: string,
    preferIdpToken?: boolean,
    mailboxInfo?: MailboxInfo,
    scope?: string,
    sendClientCapabilityClaim?: boolean
): Promise<string | undefined> {
    requestId = requestId || getGuid();

    if (isFeatureEnabled('auth-disableIdpTokenForConsumer') && !isBusiness(mailboxInfo)) {
        preferIdpToken = false;
    }

    let [token, tokenPromise] = getAccessTokenforResourceAsLazy(
        resource,
        apiName,
        requestId,
        targetTenantId,
        wwwAuthenticateHeader,
        false /*showFullTokenResponse*/,
        preferIdpToken,
        mailboxInfo,
        scope,
        sendClientCapabilityClaim
    );

    if (!token) {
        token = (await tokenPromise) as string;
    }

    return token as string;
}

/**
 * Fetches the access token and returns the token immediately if it's cached, or if it's not cached, return the promise
 * that will return the access token
 * @param resource - the resource string
 * @param apiName - this can be used in case multiple apis are called from the same resource
 * @param requestId - the request correlation Id
 * @param targetTenantId - the tenantid for which the token is requested
 * @param wwwAuthenticateHeader - the WWW Authenticate Header
 * @param showFullTokenResponse - whether full token response with error should be returned
 * @param preferIdpToken - request the auth service to send an AAD token
 * @param mailboxInfo - The mailboxInfo of the account to get the token
 * @param sendClientCapabilityClaim - Whether to send cp1 claim
 *  @returns [0] - The AcccessToken Response or access token if it can be returned synchronously, null otherwise
 * @returns [1] - The promise that will return the AcccessToken Response or access token if it needs to be fetched async, null if token is returned synchronously.
 */
export function getAccessTokenforResourceAsLazy(
    resource: string,
    apiName?: string,
    requestId?: string,
    targetTenantId?: string,
    wwwAuthenticateHeader?: string,
    showFullTokenResponse?: boolean,
    preferIdpToken?: boolean,
    mailboxInfo?: MailboxInfo,
    scope?: string,
    sendClientCapabilityClaim?: boolean
): [
    string | AuthTokenResponse | undefined,
    Promise<string | AuthTokenResponse | undefined> | undefined
] {
    if (isFeatureEnabled('auth-disableIdpTokenForConsumer') && !isBusiness(mailboxInfo)) {
        preferIdpToken = false;
    }

    if (isBusinessDomain() && (resource == getOrigin() || resource == getOrigin() + '/')) {
        const publicUrl = (findMetatag('publicUrl') || '').toLowerCase();
        resource = isUrlPresent(publicUrl) ? publicUrl : resource;
        resource = ensureTrailingSlash(resource);
    }

    mailboxInfo = mailboxInfo ?? getModuleContextMailboxInfo();
    if (!isServiceRequestSupportedForMailbox(mailboxInfo)) {
        const msg = 'Cannot get access token for a mailbox that does not support service requests';
        debugErrorThatWillShowErrorPopupOnly(msg);
        return [undefined, Promise.reject(new Error(msg))];
    }

    const params = {
        requestId: requestId || getGuid(),
        scenarioType: getScenarioType(mailboxInfo),
        mailboxInfo,
        apiName,
        resource,
        scope,
        showFullTokenResponse,
        wwwAuthenticateHeader,
        targetTenantId,
        preferIdpToken,
        sendClientCapabilityClaim,
    };

    let tokenResponse: string | AuthTokenResponse | undefined = undefined;
    if (!wwwAuthenticateHeader) {
        const cachedToken = getCachedTokenIfAvailable(params);
        if (cachedToken) {
            tokenResponse = showFullTokenResponse
                ? getAuthTokenResponse(cachedToken)
                : cachedToken.AccessToken;
        }
    }

    return [
        tokenResponse,
        new Promise<string | AuthTokenResponse | undefined>(resolve => {
            getToken(params)
                .then(tokenResponseValue => {
                    resolve(
                        showFullTokenResponse ? tokenResponseValue : tokenResponseValue?.accessToken
                    );
                })
                .catch((err: Error) => {
                    resolve(undefined);
                    logStartUsage('FailedToRetrieveTokenForEnterprise', {
                        stack: err?.stack,
                        message: err?.message,
                    });
                });
        }),
    ];
}

import { isConsumer } from 'owa-session-store';

let socOriginUrl: string | undefined;
export function getSocOriginUrl(): string {
    if (!socOriginUrl) {
        socOriginUrl = isConsumer()
            ? 'https://support.office.live.com'
            : 'https://support.office.com';
    }
    return socOriginUrl;
}

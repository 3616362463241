import doesActiveExperiencesInclude from './doesActiveExperiencesInclude';
import { getQueryStringParameter } from 'owa-querystring';
import type { MailboxInfo } from 'owa-client-types';

export default function isEdu(mailboxInfo?: MailboxInfo): boolean {
    const IS_EDUUSER_QUERY_OVERRIDE = 'isEdu';
    if (getQueryStringParameter(IS_EDUUSER_QUERY_OVERRIDE) === '1') {
        return true;
    }
    return (
        doesActiveExperiencesInclude('Edu', mailboxInfo) ||
        doesActiveExperiencesInclude('EduStudent', mailboxInfo) ||
        doesActiveExperiencesInclude('EduFaculty', mailboxInfo)
    );
}

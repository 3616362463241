import { isFeatureEnabled } from 'owa-feature-flags';
import { getIsSensitivityLabelsEnabled } from '../selectors/unifiedGroupsStoreSelectors';
import { isConsumer } from 'owa-session-store';
import type { MailboxInfo } from 'owa-client-types';

export function IsShareableCRUDEnabled(mailboxInfo: MailboxInfo): boolean {
    return (
        (isFeatureEnabled('grp-shareable-crud') || !!getIsSensitivityLabelsEnabled(mailboxInfo)) &&
        !isConsumer(undefined, mailboxInfo)
    );
}

export function IsGroupSmtpEditEnabled(mailboxInfo: MailboxInfo): boolean {
    return !isConsumer(undefined, mailboxInfo);
}

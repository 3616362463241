/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * With support for additional (shared/delegate) mailboxes as accounts userIdentity can no longer be counted on to uniquely identify an account. Replace userIdentity usage with AccountKey usage.
 *	> 'owa-account-source-list/lib/utils/getSourceIdForUserIdentity' import is restricted from being used. UserIdentity does not uniquely identify a mailbox. Use getCoprincipalAccountForAccountKey instead. */
import getSourceIdForUserIdentity from 'owa-account-source-list/lib/utils/getSourceIdForUserIdentity';
import {
    accountSourceDataTypeChecker,
    getAccountBySourceId,
    getGlobalSettingsAccount,
    isAccountSourceListStoreInitialized,
    removeCoprincipalAccountById,
} from 'owa-account-source-list-store';
import { logStartUsage } from 'owa-analytics-start';

// Removes the connected account identified by the userIdentit from the source list
export function removeConnectedAccount(userIdentity: string) {
    const sourceId = getSourceIdForUserIdentity(userIdentity);
    const account = getAccountBySourceId(sourceId);
    const isConnectedAccount = accountSourceDataTypeChecker.isM365ConnectedMailbox(account);
    const doRemove = sourceId && isConnectedAccount;
    if (doRemove) {
        removeCoprincipalAccountById(sourceId);
    }

    const isInitialzied = isAccountSourceListStoreInitialized();
    const defaultSettings = isInitialzied ? getGlobalSettingsAccount() : undefined;
    logStartUsage('AcctRemove-ConnectedAccount', {
        si: typeof sourceId,
        sil: sourceId?.length,
        ac: typeof account,
        connected: isConnectedAccount,
        remove: doRemove,
        uit: typeof userIdentity,
        uil: userIdentity?.length,
        defuit: typeof defaultSettings?.mailboxInfo?.userIdentity,
        isdefcon: accountSourceDataTypeChecker.isM365ConnectedMailbox(defaultSettings),
        defuitl: defaultSettings?.mailboxInfo?.userIdentity?.length,
        equal: userIdentity === defaultSettings?.mailboxInfo?.userIdentity,
        isInit: isInitialzied,
    });
}

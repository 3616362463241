let safeMode: boolean = false;

// Set the Safe Mode session for Monarch. This will force an online boot that will fetch the latest version of the app and flights and avoid local storage.
export function setSafeModeSession(): void {
    safeMode = true;
}

// Check if this is a Safe Mode session for Monarch. This will force an online boot that will fetch the latest version of the app and flights and avoid local storage.
// This is a command line option provided by the Native Host. (olk.exe --safe)
export function isSafeModeSession(): boolean {
    return safeMode;
}

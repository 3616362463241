import { LazyModule, LazyAction } from 'owa-bundling-light';
const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "OwaM365AcquisitionsDatabase" */ './lazyIndex'),
    { name: 'OwaM365AcquisitionsDatabase' }
);

export const lazyUpdateDatabaseCachedAcquisitions = new LazyAction(
    lazyModule,
    m => m.updateDatabaseCachedAcquisitions
);

export const lazyWriteToAcquisitionsCacheFromIndexedDb = new LazyAction(
    lazyModule,
    m => m.writeToAcquisitionsCacheFromIndexedDb
);

import React from 'react';
import {
    RibbonBottomBar,
    getRibbonBottomBarAriaControlsId,
    getRibbonBottomBarId,
} from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components';
import { menuTextChevronClass } from '@1js/acui-button/lib/components/AppFlyoutAnchor';
import { getRibbonBottomBarStylingConstants } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/ribbonStylingConstants';
import type {
    ControlInMenuRendererFunction,
    ControlRendererFunction,
    RibbonBottomBarClassNames,
    RibbonBottomBarStylingConstants,
    RibbonModeToggleProps,
    RibbonPaddleProps,
    RibbonTabDefinitionMLR,
    RibbonTabDefinitionSLR,
} from '@1js/acui-ribbon-like';
import { appIcons, getAppStrings } from 'owa-acui-theme';
import {
    bottomBarV8,
    bottomBarContainerV8,
    chevronContainerV8,
    rootV8,
    tabContentContainerV8,
} from './CommandRibbonBottomBar.scss';
import type { AppPaneUnderlayViewState } from 'owa-application/lib/store/store';
import { type CommandingViewMode } from 'owa-outlook-service-option-store/lib/store/schema/options/CommandingOptions';
import { FluentIconStyleProvider } from 'owa-fluent-icons-svg';
import { RibbonModeMenuContainer } from './RibbonModeMenuContainer';
import { onViewModeChanged } from 'owa-command-ribbon-store';
import isAppPaneUnderlayExpanded from 'owa-application/lib/utils/isAppPaneUnderlayExpanded';
import { isFlexPaneExpanded } from 'owa-suite-header-store';
import { observer } from 'owa-mobx-react';
import { useControlInMenuRendererFunction } from '../util/useControlInMenuRendererFunction';
import { isSingleLineRibbon } from 'owa-command-ribbon-store/lib/selectors/isSingleLineRibbon';
import ChevronDown from 'owa-fluent-icons-svg/lib/icons/ChevronDownRegular';
import { isFeatureEnabled } from 'owa-feature-flags';
import { tokens } from '@fluentui/react-theme';
import { makeStyles } from '@fluentui/react-components';
import { getIsDarkTheme } from 'owa-fabric-theme';
import classnames from 'owa-classnames';

const iconStyles = {
    rootStyle: {
        height: '100%',
        textAlign: 'center',
        verticalAlign: 'top',
        width: '100%',
    },
};

// Overwriting styles that `FluentIconStyleProvider` applies
const iconStylesFluentV9 = {
    iconStyle: {
        contain: 'inherit',
        height: 'inherit',
        verticalAlign: 'inherit',
        width: 'inherit',
    },
    rootStyle: {
        contain: 'inherit',
        display: 'inherit',
        height: 'inherit',
        verticalAlign: 'inherit',
        width: 'inherit',
    },
};

const customStylingConstants: RibbonBottomBarStylingConstants = {
    ...getRibbonBottomBarStylingConstants('Multiline'),
    bottomBarHeight: 92,
    groupDividerPadding: {
        ...getRibbonBottomBarStylingConstants('Multiline').groupDividerPadding,
        bottom: 4,
        top: 4,
    },
};

const ribbonModeToggleProps: RibbonModeToggleProps = {
    isRibbonModeToggleDisabled: false,
    onRibbonModeToggle: () => {
        onViewModeChanged(isSingleLineRibbon() ? 2 : 1);
    },
    ribbonModeToggleIcon: ChevronDown,
};

const customStylesV8: Partial<RibbonBottomBarClassNames> = {
    chevronContainer: chevronContainerV8,
    root: rootV8,
    tabContentContainer: tabContentContainerV8,
};

const useStyles = makeStyles({
    bottomBar: {
        display: 'flex',
    },
    bottomBarContainer: {
        flexGrow: '1',
        minWidth: '0',

        // Overwriting `menuTextChevronClass` styles defined in 1JS here:
        // https://office.visualstudio.com/Office/_git/1JS?path=/ooui/packages/acui-ribbon-like/src/UISurfaces/Ribbon/Controls/next/commonRibbonButtonStyles.styles.ts&version=GBmain&line=349&lineEnd=358&lineStartColumn=1&lineEndColumn=1&lineStyle=plain&_a=contents
        [`& .${menuTextChevronClass}`]: {
            paddingLeft: 0,
            width: 'auto',
        },
    },
    bottomBarContainerGroupDivider: {
        '& .ribbon-bottom-bar-divider': {
            backgroundColor: tokens.colorNeutralStroke1,
        },
    },
    outerContainer: {
        padding: '0',
    },
});

const getCustomStylesV9 = (outerContainer: string): Partial<RibbonBottomBarClassNames> => ({
    outerContainer,
});

export const CommandRibbonBottomBar = observer(function CommandRibbonBottomBar({
    disableAnimation,
    isProjectionPopout,
    projectionUnderlay,
    selectedTab,
    controlRendererFunction,
    copilotCommandCenter,
}: {
    disableAnimation?: boolean;
    isProjectionPopout: boolean;
    projectionUnderlay?: AppPaneUnderlayViewState;
    selectedTab: RibbonTabDefinitionSLR | RibbonTabDefinitionMLR;
    controlRendererFunction: (c: ControlInMenuRendererFunction) => ControlRendererFunction;
    copilotCommandCenter?: React.ReactNode;
}) {
    const { controlGroups, id, initialScalingSteps, scalingSteps } = selectedTab;
    const controlInMenuRendererFunction = useControlInMenuRendererFunction(isProjectionPopout);

    const { bottomBar, bottomBarContainer, bottomBarContainerGroupDivider, outerContainer } =
        useStyles();

    const isFluentV9Enabled = isFeatureEnabled('mon-ribbon-fluent-v9-ribbon');

    const isAppPaneExpanded = isAppPaneUnderlayExpanded(projectionUnderlay) || false;
    const flexPaneExpanded = isFlexPaneExpanded();

    // A bit of a hack since `paddleProps` and `isAppPaneExpanded` are not related, but:
    // 1. We need `RibbonBottombar` to re-render when a pane opens, closes, or expands.
    // 2. `RibbonBottombar` will only re-render when its props change.
    //
    // Using `useMemo`, we redefine `paddleProps` only when `isAppPaneExpanded` changes
    // (resulting in `RibbonBottombar` re-rendering).
    const paddleProps: RibbonPaddleProps = React.useMemo(
        () => ({
            disableModeTogglePadding: !isFluentV9Enabled,
            ribbonPaddlesIcon: appIcons.downChevron.icon12,
        }),
        [isAppPaneExpanded, flexPaneExpanded]
    );

    return (
        <div id="BottomBar" className={isFluentV9Enabled ? bottomBar : bottomBarV8}>
            <div
                className={classnames(
                    isFluentV9Enabled ? bottomBarContainer : bottomBarContainerV8,
                    isFluentV9Enabled && getIsDarkTheme() && bottomBarContainerGroupDivider
                )}
            >
                <FluentIconStyleProvider {...(isFluentV9Enabled ? iconStylesFluentV9 : iconStyles)}>
                    <RibbonBottomBar
                        appStrings={getAppStrings()}
                        ariaControlsId={getRibbonBottomBarAriaControlsId(id)}
                        ariaLabeledBy={id}
                        controlGroups={controlGroups}
                        controlRendererFunction={controlRendererFunction(
                            controlInMenuRendererFunction
                        )}
                        controlInMenuRendererFunction={controlInMenuRendererFunction}
                        customStyles={
                            isFluentV9Enabled ? getCustomStylesV9(outerContainer) : customStylesV8
                        }
                        customStylingConstants={
                            !isFluentV9Enabled && !isSingleLineRibbon()
                                ? customStylingConstants
                                : undefined
                        }
                        disableAnimations={disableAnimation}
                        id={getRibbonBottomBarId(id)}
                        initialScalingSteps={initialScalingSteps}
                        overflowMenuLauncherIcon={appIcons.overflowMenuLauncher}
                        paddleProps={paddleProps}
                        scalingSteps={scalingSteps}
                        preventShadowRendering={true}
                        disableQuickScaling={true}
                        ribbonModeToggleProps={
                            isFluentV9Enabled ? ribbonModeToggleProps : undefined
                        }
                    />
                </FluentIconStyleProvider>
            </div>

            {!isFluentV9Enabled && <RibbonModeMenuContainer />}

            {copilotCommandCenter}
        </div>
    );
},
'CommandRibbonBottomBar');

import type { CustomDataMap, CustomDataType } from 'owa-analytics-types';
import type { TraceErrorObject } from 'owa-trace/lib/TraceErrorObject';

import { scrubForPii, scrubFileNameForPii } from 'owa-config/lib/scrubForPii';
import { isOneOf } from 'owa-errors/lib/isOneOf';
import { categorizeError } from 'owa-errors/lib/categorizeError';

import { isDocumentVisible } from './visibilityState';

const errorsWithCompleteCallStack = [
    'AnalyticsWorkers_MainThread_EventFailedInWebWorker: logDatapoint. Original Error: Maximum call stack size exceeded',
    'Warning: Cannot update a component (`%s`) while rendering a different component (`%s`). To locate the bad setState() call inside `%s`, follow the stack trace as described in https://reactjs.org/link/setstate-in-render%s',
];

export function addErrorToCustomData(message: string, errorDetails: TraceErrorObject | undefined) {
    const category = categorizeError(message, errorDetails);
    const datapointName = category && `${category}_ERROR`;
    const customData: CustomDataMap = {};
    const truncate = !isOneOf(errorsWithCompleteCallStack, message);
    addToCustomData(customData, 'message', message);

    addToCustomData(customData, 'background', isDocumentVisible());
    if (errorDetails) {
        addToCustomData(customData, 'name', errorDetails.name);
        addToCustomData(customData, 'file', scrubFileNameForPii(errorDetails.filename));
        addToCustomData(customData, 'line', errorDetails.lineno);
        addToCustomData(customData, 'col', errorDetails.colno);
        addToCustomData(customData, 'httpstatus', errorDetails.httpStatus);
        addToCustomData(customData, 'stack', scrubFileNameForPii(errorDetails.stack), truncate);
        addToCustomData(
            customData,
            'diagnostics',
            JSON.stringify({
                ...errorDetails.additionalInfo,
                diagnosticInfo: errorDetails.diagnosticInfo || '',
            }),
            truncate
        );
        addToCustomData(customData, 'responseCode', errorDetails.responseCode);
        addToCustomData(customData, 'innerMessage', errorDetails.innerMessage);
        addToCustomData(customData, 'gqlPath', errorDetails.gqlPath);
        addToCustomData(
            customData,
            'queryStack',
            scrubFileNameForPii(errorDetails.queryStack),
            truncate
        );
    } else {
        addToCustomData(
            customData,
            'stack',
            /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
             * Error constructor names can only be a string literals.
             *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
            scrubFileNameForPii(new Error(message).stack),
            truncate
        );
    }

    return {
        datapointName,
        customData,
    };
}

function addToCustomData(
    customData: CustomDataMap,
    key: string,
    value: CustomDataType,
    truncate?: boolean
) {
    const valueType = typeof value;
    if (valueType != 'undefined') {
        customData[key] = valueType == 'string' ? scrubForPii(<string>value, !!truncate) : value;
    }
}

import type { AnalyticsFlightsAndAppSettings } from 'owa-analytics-types';
import { getApplicationSettings } from 'owa-application-settings';
import { isGulpOrBranching } from 'owa-config';
import { isDataWorkerPlatformEnabled } from 'owa-data-worker-check';
import { isFeatureEnabled } from 'owa-feature-flags';

export function initializeAnalyticsFlightsAndAppSettings() {
    const isDevEnvironment = isGulpOrBranching();
    const analyticsApplicationSettings = getApplicationSettings('Analytics');

    const settings: AnalyticsFlightsAndAppSettings = {
        // ECS Settings
        disabledDatapoints: analyticsApplicationSettings.disabledDatapoints || [],
        throttledDatapoints: analyticsApplicationSettings.throttledDatapoints || [],
        disableIsCoreDatapoints: analyticsApplicationSettings.disableIsCoreDatapoints || [],
        enableHighCountEventThrottle: analyticsApplicationSettings.enableHighCountEventThrottle,
        highCountEventThrottleWindow: analyticsApplicationSettings.highCountEventThrottleWindow,
        highCountEventThrottleThreshold:
            analyticsApplicationSettings.highCountEventThrottleThreshold,
        capturePerfProfiles: analyticsApplicationSettings.capturePerfProfiles || [],

        // Feature Flights
        shouldCaptureAssets: isFeatureEnabled('an-log-assets'),
        skipFlightControls:
            isFeatureEnabled('ring-dogfood') || isFeatureEnabled('an-no-sample-ppe'),
        isFullVerboseLoggingEnabled: isFeatureEnabled('an-full-logging'),
        enablePrivacyGuard: isFeatureEnabled('fwk-oneDs-privacyGuard'),
        turnOffCustomData: isFeatureEnabled('fwk-turnOffCustomData'),
        devToolsEnabled: isDevEnvironment || isFeatureEnabled('fwk-devTools'),
        trackEventFrequency: isFeatureEnabled('fwk-an-trackEventFrquency'),
        isDataWorkerEnabled: isDataWorkerPlatformEnabled(),
        enableOfflineCaching: isFeatureEnabled('fwk-offline-boot'),
        isDiagDatapointTracingPanelEnabled: isFeatureEnabled('fwk-diag-datapoint-tracing-panel'),
    };

    return settings;
}

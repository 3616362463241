import type { MailboxInfo } from 'owa-client-types';
import getStore from '../store';

export function hasUserSelectedThirdPartyMeetingProvider(mailboxInfo: MailboxInfo): boolean {
    const matchedInfo = getStore().thirdPartyImProviderInfoArray.find(
        info => info.mailboxInfo?.mailboxSmtpAddress === mailboxInfo?.mailboxSmtpAddress
    );

    return !!matchedInfo?.isThirdPartyMeetingProvider;
}

import type CopilotPrioritizeSettings from '../types/CopilotPrioritizeSettings';
import { isCopilotFeatureEnabled } from 'owa-copilot-settings-utils';
import getStore from '../store/store';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import isCopilotPrioritizeSettingsInitialized from './isCopilotPrioritizeSettingsInitialized';
import fetchMailCopilotSettings from '../utils/fetchMailCopilotSettings';

const pendingRequests: string[] = [];

export default function getCopilotPrioritizeSettings(
    mailboxInfo: MailboxInfo
): CopilotPrioritizeSettings {
    const key = getIndexerValueForMailboxInfo(mailboxInfo);
    const store = getStore().copilotPrioritizeSettingsStoreMap.get(key);

    if (
        !isCopilotFeatureEnabled('Inbox', mailboxInfo, true /* skipLanguageCheck */) ||
        !isCopilotPrioritizeSettingsInitialized(mailboxInfo) ||
        !store
    ) {
        if (
            isCopilotFeatureEnabled('Inbox', mailboxInfo, true /* skipLanguageCheck */) &&
            !pendingRequests.includes(mailboxInfo.userIdentity)
        ) {
            pendingRequests.push(mailboxInfo.userIdentity);

            fetchMailCopilotSettings(mailboxInfo).finally(() => {
                const index = pendingRequests.indexOf(mailboxInfo.userIdentity);
                if (index > -1) {
                    pendingRequests.splice(index, 1);
                }
            });
        }

        const defaultSettings: CopilotPrioritizeSettings = {
            version: 1,
            // Feature is off if not supported
            prioritizationEnabled: false,
            moreImportantRules: [],
            lessImportantRules: [],
            miscellaneousRules: [],
            applyLowPriorityLabel: false,
            experimentalSetting: null,
        };

        return defaultSettings;
    }

    return store.settings;
}

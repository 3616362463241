import createStoreCheckForGenericEnablement from './createStoreCheckForGenericEnablement';
import type { MailboxInfo } from 'owa-client-types';
import type {
    ImplementedCopilotFeatures,
    CopilotSettingsStore,
    CopilotCapableAddinInfo,
} from 'owa-copilot-settings-store';
import {
    copilotSettingsStore,
    getTenantDefaultAddin,
    lazySetAddinSettingsToOcs,
    isGeneric1PAddinSupported,
    isScenarioExtensible,
    isOldModelAddin,
    isSalesLicensePresent,
} from 'owa-copilot-settings-store';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { type LocalStorageKeys, removeItem } from 'owa-local-storage';

const ADDIN_BY_SCENARIO_LOCAL_STORAGE_KEY = 'olk-copilot-last-used-addin';

const getAddinsAvailableForFeature = createStoreCheckForGenericEnablement<
    ImplementedCopilotFeatures,
    CopilotCapableAddinInfo[]
>('GetAvailableAddins', getAddinsAvailableForFeatureInternal);

export default getAddinsAvailableForFeature;

function getAddinsAvailableForFeatureInternal(
    store: CopilotSettingsStore,
    _language: string,
    scenario: ImplementedCopilotFeatures,
    mailboxInfo: MailboxInfo
): CopilotCapableAddinInfo[] {
    if (store.isEnabled) {
        let addinsInfo = store.extensibility.addinsInfo.get(scenario);
        const hasSalesLicense = isSalesLicensePresent(mailboxInfo);
        const isExtensibleScenario = isScenarioExtensible(mailboxInfo, scenario);

        addinsInfo = addinsInfo?.filter(
            addin =>
                (addin.isOldModelAddin && hasSalesLicense) ||
                (!addin.isOldModelAddin && isExtensibleScenario)
        );

        if (!!addinsInfo && addinsInfo.length > 0) {
            return addinsInfo;
        }
    }
    return [];
}

export function getStringsForScenarioAndTitleId(
    scenario: ImplementedCopilotFeatures,
    titleId: string,
    mailboxInfo: MailboxInfo
) {
    const store = copilotSettingsStore(mailboxInfo);
    if (
        !!store &&
        store.isEnabled &&
        (isSalesLicensePresent(mailboxInfo) || !isOldModelAddin(titleId, mailboxInfo))
    ) {
        const foundAddin = store.extensibility.addinsInfo
            .get(scenario)
            ?.find(addin => addin.titleId == titleId);
        return foundAddin
            ? {
                  scenarioStrings: foundAddin.scenarioStrings.get(scenario),
                  fallbackAddinName: foundAddin.fallbackAddinName,
                  iconUri: foundAddin.menuIcon,
              }
            : undefined;
    }
    return undefined;
}

export async function updateLastUsedAddin(
    scenario: ImplementedCopilotFeatures,
    titleId: string,
    mailboxInfo: MailboxInfo
) {
    const store = copilotSettingsStore(mailboxInfo);
    if (
        !!store &&
        store.isEnabled &&
        store.extensibility.scenarioToAddinSettingsMap &&
        isGeneric1PAddinSupported(mailboxInfo)
    ) {
        const tenantDefaultAddin = getTenantDefaultAddin(mailboxInfo);
        if (tenantDefaultAddin) {
            store.extensibility.scenarioToAddinSettingsMap[scenario] = {
                tenantDefault: tenantDefaultAddin,
                lastUsedAddin: titleId,
            };
        } else {
            store.extensibility.scenarioToAddinSettingsMap[scenario] = {
                lastUsedAddin: titleId,
            };
        }
        const setAddinSettingsToOcs = await lazySetAddinSettingsToOcs.import();
        setAddinSettingsToOcs(mailboxInfo, store.extensibility.scenarioToAddinSettingsMap);
        removeItem(window, getLocalStorageKeyForMbxInfo(mailboxInfo)); //cleanup previously stored value in local storage
    }
}

function getLocalStorageKeyForMbxInfo(mailboxInfo: MailboxInfo): LocalStorageKeys {
    return `${getIndexerValueForMailboxInfo(mailboxInfo)}_${ADDIN_BY_SCENARIO_LOCAL_STORAGE_KEY}`;
}

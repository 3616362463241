import { removeItem, setItem } from 'owa-local-storage';
import { ThemeConstants } from 'owa-theme-shared';

// The image key is stored in local storage for modern image themes to initiate the request earlier during boot
// enhancing the speed of future app launches
export function addImageUrlToLocalStorage(imageUrl: string) {
    setItem(self, ThemeConstants.LOCAL_IMAGE_STORAGE_KEY, imageUrl);
}

// This is to ensure that the image key is not stored in local storage for non-full bleed themes
export function removeImageUrlFromLocalStorage() {
    removeItem(self, ThemeConstants.LOCAL_IMAGE_STORAGE_KEY);
}

import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import type { MailboxInfo } from 'owa-client-types';

const activeExperiencesMap = new Map<string, string | null>();

export function setActiveExperiences(
    activeExperiences: string | null,
    mailboxInfo?: MailboxInfo
): string | null {
    const key = getMapKey(mailboxInfo);

    if (!activeExperiencesMap.has(key)) {
        activeExperiencesMap.set(key, activeExperiences);
    }

    return activeExperiencesMap.get(key) ?? null;
}

export function getActiveExperiences(mailboxInfo?: MailboxInfo): string | null {
    const key = getMapKey(mailboxInfo);
    return activeExperiencesMap.get(key) ?? null;
}

function getMapKey(mailboxInfo?: MailboxInfo): string {
    return mailboxInfo ? getIndexerValueForMailboxInfo(mailboxInfo) : 'default_global_account';
}
